import React, { Component } from 'react';
import CISService from '../service/CISService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { classNames } from 'primereact/utils';

export class FormPlateaux extends Component {
 
    constructor() {
        super();
        this.state = {
            liste_Codification: [],
            selectedCodif: null,
            showindicator: false,
            idCodif: '',
            libelleCodif: '',
            codeCodif: '',
            paramCodif: 'PLATEAUX',
            descriptionCodif: '',
            actifCodif: 'Oui',

            liste_actif: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' }
            ],

            ////////////////////////////
            modif: false, // pour vérifier s'il s'agit d'une modification ou un ajout
            visible: false, // pour afficher la boite de confirmation de suppression
            visible_dlg_form: false, // pour afficher la boite de dialogue du formation de saisie
            showindicator: false, // pour afficher l'état de chargement
            submit: false,// pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            msg: '',
            ////////////////////////////
        };
        this.edit_codification = this.edit_codification.bind(this);
        this.conf_delete_codification = this.conf_delete_codification.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.CISService = new CISService();
    }

    componentDidMount() {
        this.get_liste_codification();
    }

    get_liste_codification() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_codification(this.state.paramCodif).then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                this.setState({ liste_Codification: data });
            }
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_codification();
            this.get_liste_codification();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
        this.setState({submit: false});
    }

    edit_codification(data) {
        if (data != null && data != undefined) {
            this.setState({ libelleCodif: data.libelleCodif });
            this.setState({ codeCodif: data.codeCodif });
            this.setState({ descriptionCodif: data.descriptionCodif });
            this.setState({ actifCodif: data.actifCodif });
            this.setState({ modif: true });
            this.setState({ selectedCodif: data });
            this.setState({ visible_dlg_form: true });
        }
    }

    annule_codification() {
        this.setState({ libelleCodif: '' });
        this.setState({ codeCodif: '' });
        this.setState({ descriptionCodif: '' });
        this.setState({ modif: false });
        this.setState({ visible_dlg_form: false });
    }


    enreg_codification() {
        this.setState({ submit: true });
        if (this.state.libelleCodif == '' || this.state.codeCodif == '') {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else if (this.state.modif == false) {
            this.setState({ showindicator: true });
            let data = {
                idCodif: 0,
                libelleCodif: this.state.libelleCodif,
                codeCodif: this.state.codeCodif,
                paramCodif: this.state.paramCodif,
                descriptionCodif: this.state.descriptionCodif,
                actifCodif: 'Oui'
            }
            this.CISService.enreg_codification(data).then(data => this.resultat(data.code, data.contenu));
        } else {
            let data = this.state.selectedCodif;
            data.libelleCodif = this.state.libelleCodif;
            data.codeCodif = this.state.codeCodif;
            data.descriptionCodif = this.state.descriptionCodif;
            this.CISService.enreg_codification(data).then(data => this.resultat(data.code, data.contenu));
        }
    }

    actionTemplate(rowData) {
        return <div className="grid" >
            <div className="col"><Button onClick={() => this.edit_codification(rowData)} style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_codification(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -10 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_codification() {
        if (this.state.libelleCodif == null || this.state.libelleCodif == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.CISService.delete_codification(this.state.selectedCodif).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_codification(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedCodif: data });
            this.setState({ msg: data.libelleCodif });
            this.setState({ visible: true });
        }
    }


    /* Affichage de la boite de dialogue pour l'ajout d'une nouvelle region */
    toogleDlg() {
        this.annule_codification();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedCodif == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_codification(this.state.selectedCodif)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

     /* Exportation du tableau contenant la liste des plateaux en fichier CSV */
     exportCSV = () => {
        this.dt.exportCSV("Liste_plateaux");
    }

    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_codification();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="card p-fluid col-12">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_Codification} paginatorPosition="top" selectionMode="single" paginator={true} rows={100}
                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedCodif: e.data })} onRowSelect={e => this.setState({ selectedCodif: e.data })}
                    responsive={true} selection={this.state.selectedCodif} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                    <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                    <Column field="libelleCodif" filter={true} filterMatchMode='contains' header="Langue" sortable={true} style={{ width: '30%' }} />
                    <Column field="codeCodif" filter={true} filterMatchMode='contains' header="Code" sortable={true} style={{ width: '20%' }} />
                    <Column field="descriptionCodif" filter={true} filterMatchMode='contains' header="Description" sortable={true} style={{ width: '40%' }} />
                </DataTable>

                {/* Boite de dialogue d'ajout et de modification d'une region  */}
                <Dialog visible={this.state.visible_dlg_form} style={{ width: '450px' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annule_codification()} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_codification()} style={{ width: 120 }} />
                    </>}>
                    <div className="p-fluid">
                        <div className="field">
                            <label htmlFor="libelleCodif">Plateaux *</label>
                            <InputText id="libelleCodif" value={this.state.libelleCodif} onChange={(e) => this.setState({ libelleCodif: e.target.value, descriptionCodif: e.target.value })} className={classNames({ 'p-invalid': this.state.submit && !this.state.libelleCodif })} />
                            {this.state.submit && !this.state.libelleCodif && <small className="p-invalid" style={{ color: 'red' }}>Le Plateaux est obligatoire.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="codeCodif">Code *</label>
                            <InputText id="codeCodif" value={this.state.codeCodif} onChange={(e) => this.setState({ codeCodif: e.target.value })} className={classNames({ 'p-invalid': this.state.submit && !this.state.codeCodif })} />
                            {this.state.submit && !this.state.codeCodif && <small className="p-invalid" style={{ color: 'red' }}>Le code est obligatoire.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="descriptionCodif">Description </label>
                            <InputText id="descriptionCodif" value={this.state.descriptionCodif} onChange={(e) => this.setState({ descriptionCodif: e.target.value })} />
                        </div>
                    </div>
                </Dialog>

                
            </div>
        );
    }
}