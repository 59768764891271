import React, { Component } from 'react';
import CISService from '../service/CISService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { SplitButton } from 'primereact/splitbutton';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Chart } from 'primereact/chart';
import { InputNumber } from 'primereact/inputnumber';

const chartOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    aspectRatio: .8,
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        },
        y: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        }
    }
};

export class StatTopMotif extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            liste_structure: [],
            liste_profil: [],
            liste_categorie: [],
            liste_item_export: [
                {
                    label: 'Exporter en CVS',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.exportCSV();
                    }
                },
                {
                    label: 'Exporter en XLS',
                    icon: 'pi pi-file-excel',
                    command: (e) => {
                        this.exportExcel();
                    }
                },
            ],
            top: 10,
            pd1: new Date(),
            pd2: new Date(),
            ridStruct: 'Tous',
            rCategorie: 'Tous',
            rtypeCli: 'Tous',
            retatReq: 'Tous',
            rstatutReq: 'Tous',
            rProfil: 'Tous',
        };
        this.CISService = new CISService();
    }

    componentDidMount() {
        this.get_liste_codification();
        this.get_liste_data();
        this.get_liste_structures();
    }

    get_liste_data() {
        this.setState({ showindicator: true });
        try {
            let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
            let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
            this.CISService.get_liste_stat_top_motif(spd1, spd2, this.state.pidStruct,this.state.retatReq, this.state.rstatutReq, this.state.rCategorie, this.state.rProfil,this.state.top).then(data => this.setState({ liste_data: data, showindicator: false }));
        } catch (err) {
            console.log(err);
        }
    }

    //Liste des motifs
    get_liste_motif() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_motif().then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idMotif, label: x.descriptionMotif }
                });
                this.setState({ liste_motif: liste });
            }
        });
    }

    get_liste_nature(idMotif) {
        this.CISService.get_liste_nature(idMotif).then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idNature, label: x.descriptionNature }
                });
                this.setState({ liste_nature: liste });
            }
        });
    }

    //Liste des Codifications 
    get_liste_codification() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_codification('CATEGORIE_CLIENT').then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.descriptionCodif }
                });
                this.setState({ liste_categorie: liste });
            }
        });

        this.CISService.get_liste_codification('PLATEAUX').then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.descriptionCodif }
                });
                this.setState({ liste_profil: liste });
            }
        });

    }

    //Liste des agences 
    get_liste_structures() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_structure().then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idStruct, label: x.libelleStruct }
                });
                this.setState({ liste_structure: liste });
            }
        });
    }

    /* Exportation du tableau contenant la liste des requetes en fichier CSV */
    exportCSV = () => {
        //ref={(el) => { this.dt = el; }}
        this.dt.exportCSV("TOP_MOTIF_");
    }

    /* Exportation du tableau  en fichier XLSX */
    exportExcel = () => {
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return { 'MOTIF': x.label, 'NOMBRE': x.ivalue, 'POURCENTAGE': x.prc }
        }) : [];
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'TOP_MOTIF_');
        });
    }

    /* Enregistrer le fichier sur le client */
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    render() {
        let liste_label = [];
        let liste_ds = [];
        if (this.state.liste_data !== null && this.state.liste_data !== undefined) {
            if (this.state.liste_data.length > 0) {
                liste_label = this.state.liste_data.map(x => {
                    return x.label
                });
                liste_ds = this.state.liste_data.map(x => {
                    return x.ivalue
                });
            }
        }

        const chart_data = {
            labels: liste_label,
            datasets: [
                {
                    label: 'Requête',
                    backgroundColor: '#64B5F6',
                    data: liste_ds
                },
            ]
        };

        const template = (options) => {
            const toggleIcon = options.collapsed ? 'pi pi-filter-fill' : 'pi pi-chevron-up';
            const className = `${options.className} justify-content-start`;
            const titleClassName = `${options.titleClassName} pl-1`;
            return (
                <a onClick={options.onTogglerClick} style={{ cursor: 'pointer' }}>
                    <div className={className}>
                        <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                            <span className={toggleIcon}></span>
                            <Ripple />
                        </button>
                        <span className={titleClassName}>
                            Filtre avancé
                        </span>

                    </div>
                </a>
            )
        }
        return (
            <div className="card p-fluid" style={{ height: 900 }}>
                <Panel headerTemplate={template} collapsed={true} toggleable style={{ marginBottom: 10 }}>
                    <div className="grid">
                        <div className="col-12">
                            <div className="grid" >

                                <div className="col-6 md:col-2" style={{ marginTop: 5 }}>
                                    <span className="p-float-label">
                                        <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => { this.get_liste_data() })} dateFormat="dd/mm/yy" yearNavigator yearRange="2021:2070" />
                                        <label htmlFor="inputtext">Date début</label>
                                    </span>
                                </div>
                                <div className="col-6 md:col-2" style={{ marginTop: 5 }}>
                                    <span className="p-float-label">
                                        <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => { this.get_liste_data() })} dateFormat="dd/mm/yy" yearNavigator yearRange="2021:2070" />
                                        <label htmlFor="inputtext">Date fin</label>
                                    </span>
                                </div>
                                <div className="col-6 md:col-2" style={{ marginTop: 5 }}>
                                    <span className="p-float-label">
                                        <InputNumber showButtons buttonLayout="horizontal" decrementButtonClassName="p-button-info" incrementButtonClassName="p-button-info" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" min={0} step={5} value={this.state.top}
                                            useGrouping={false} onChange={(e) => this.setState({ top: e.value }, () => { this.get_liste_data() })} />
                                        <label htmlFor="inputtext">Top</label>
                                    </span>
                                </div>
                                <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                    <span className="p-float-label">
                                        <Dropdown options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_categorie)}
                                            value={this.state.rCategorie} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rCategorie: e.value }, () => {
                                                this.get_liste_data();
                                            })} autoWidth={false} style={{ width: '100%' }} />
                                        <label htmlFor="inputtext">Catégorie client</label>
                                    </span>
                                </div>

                                <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                    <span className="p-float-label">
                                        <Dropdown options={[{ value: 'Tous', label: 'Tous' }, { value: 'FERME', label: 'FERME' }, { value: 'OUVERT', label: 'OUVERT' }]}
                                            value={this.state.rstatutReq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rstatutReq: e.value }, () => {
                                                this.get_liste_data();
                                            })} autoWidth={false} style={{ width: '100%' }} />
                                        <label htmlFor="inputtext">Statut </label>
                                    </span>
                                </div>

                                <div className="col-6 md:col-2" style={{ marginTop: 5 }}>
                                    <span className="p-float-label">
                                        <Dropdown options={[{ value: 'Tous', label: 'Tous' }, { value: 'FONDE', label: 'FONDE' }, { value: 'NON FONDE', label: 'NON FONDE' }]}
                                            value={this.state.retatReq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ retatReq: e.value }, () => {
                                                this.get_liste_data();
                                            })} autoWidth={false} style={{ width: '100%' }} />
                                        <label htmlFor="inputtext">Etat </label>
                                    </span>
                                </div>

                                <div className="col-6 md:col-2" style={{ marginTop: 5 }}>
                                    <span className="p-float-label">
                                        <Dropdown options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_structure)}
                                            value={this.state.ridStruct} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridStruct: e.value }, () => {
                                                this.get_liste_data();
                                            })} autoWidth={false} style={{ width: '100%' }} />
                                        <label htmlFor="inputtext">Structure</label>
                                    </span>
                                </div>

                                <div className="col-6 md:col-2" style={{ marginTop: 5 }}>
                                    <span className="p-float-label">
                                        <Dropdown options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_profil)}
                                            value={this.state.rProfil} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rProfil: e.value }, () => {
                                                this.get_liste_data();
                                            })} autoWidth={false} style={{ width: '100%' }} />
                                        <label htmlFor="inputtext">Profil</label>
                                    </span>
                                </div>
                                <div className="col-6 md:col-2" style={{ marginTop: 5 }}>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                                        <SplitButton style={{ width: 200 }} label="Rechercher" icon="pi pi-search" onClick={(e) => this.get_liste_data()} model={this.state.liste_item_export} />
                                    }
                                </div>
                                <div className="col-6 md:col-2" style={{ marginTop: 5 }}>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                                        null
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </Panel>
                <div className="grid">
                    <div className="col-12 md:col-6">
                        {/* <span> <i className='pi pi-table'></i> Liste</span><hr /> */}
                        <DataTable value={this.state.liste_data} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={20} ref={(el) => { this.dt = el; }}
                            alwaysShowPaginator={false} responsive={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                            <Column header="No" body={(rowData, props) => {
                                return <span>{props.rowIndex + 1}</span>
                            }} style={{ width: '5%' }} />
                            <Column field="label" header="Motif" sortable={true} />
                            <Column field="ivalue" header="Nb" style={{ width: '10%' }} sortable />
                            <Column field="prc" header="Taux" style={{ width: '10%' }} sortable />
                        </DataTable>
                    </div>
                    <div className="col-12 md:col-6">
                        {/* <span> <i className='pi pi-chart-line'></i> Graphe</span><hr /> */}
                        <Chart options={chartOptions} responsive={true} type="bar" data={chart_data} height="600w" />
                    </div>
                </div>

            </div>
        );
    }
}