import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import CISService  from '../service/CISService';
import { ProgressSpinner } from 'primereact/progressspinner';
 
export class Login extends Component {
    constructor() {
        super();
        this.state = {
            showindicator: false,
            login: '',
            pwd: '',
            msg: '',
        }; 
        this.appservice = new CISService();
    }

    handleKeyPress = e => {
        if (e.keyCode === 13) {
            this.identification();
        }
    }

    identification() {
        if (this.state.login == '' || this.state.pwd == '') {
            this.setState({ msg: 'Veuilllez saisir le login et le mot de passe' });
        } else {
            this.setState({ showindicator: true });
            this.appservice.get_identification(this.state.login, this.state.pwd).then(data => {
                if (data !== null && data !== undefined) {
                    if (data.actifUser === 'Oui') {
                        localStorage.setItem('userconnected', JSON.stringify(data));
                        this.setState({ showindicator: false });
                        window.location = "#/dashboard";
                    } else {
                        this.setState({ showindicator: false });
                        this.setState({ msg: "Vous n'etes pas autorisés à vous connecter, merci de contacter l'administrateur " });
                    }
                } else {
                    this.setState({ showindicator: false });
                    this.setState({ msg: 'Login ou mot de passe incorrects' });
                }
            });
        }
    } 

    render() {

        return ( 
            <div className="login-body">
                <div className="login-wrapper">
                    <div className="login-panel">
                        {/* <img src="assets/layout/images/logo.png" className="logo" alt="diamond-layout" width={100} height={150} /> */}
                        <img src="assets/layout/images/logoLoginSablux.png" className="logo" alt="diamond-layout" style={{width: 272, height: 100}}  />

                        <div className="login-form">
                            <h2>Connexion</h2>
                            <p>Saisissez vos identfiants pour accéder à la plateforme </p>

                            <InputText placeholder="Votre login" onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ login: e.target.value })} value={this.state.login} style={{ width: '90%' }} />
                            <Password feedback={false} placeholder="Votre mot de passe" onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ pwd: e.target.value })} value={this.state.pwd} style={{ width: '90%' }} />
                            <Button label="Connexion" icon="pi pi-sign-in" type="button" style={{ width: 150 }} onClick={(e) => this.identification()} className="p-button-help"/>
                            <br />
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px', marginTop: '10px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            <br />
                            <label style={{ color: 'red', marginTop: 10, fontWeight: 'bold' }} >{this.state.msg}</label>

                        </div>

                        <p>Mot de passe oublié ? <a href="/">Cliquer ici</a> pour réinitiliser.</p>
                    </div>
                    <div className="login-image">
                        <div className="login-image-content">

                            {/*   <h3>
                            Traiter
                            chaque client
                            comme une famille
                            </h3> */}
                        </div>
 
                        <div>
                            <span style={{ fontWeight: 'bold', color: '#8e4396', fontSize: 50, marginLeft: -250 }}>Traiter<br />  </span>
                            <span style={{ fontWeight: 'bold', color: '#8e4396', fontSize: 50, marginLeft: -250 }}>chaque client <br /> </span>
                            <span style={{ fontWeight: 'bold', color: '#8e4396', fontSize: 50, marginLeft: -250 }}>comme une famille </span>
                            <hr style={{ marginLeft: -150 }} />
                        </div> 

                        {/* <div> 
                            <span style={{ fontWeight: 'bold', color: '#e9590b', fontSize: 50, marginLeft: -250 }}>Traiter<br />  </span>
                            <span style={{ fontWeight: 'bold', color: '#e9590b', fontSize: 50, marginLeft: -250 }}>chaque client <br /> </span>
                            <span style={{ fontWeight: 'bold', color: '#e9590b', fontSize: 50, marginLeft: -250 }}>comme une famille </span>
                            <hr style={{ marginLeft: -150 }} />
                        </div>  */}

                        <div className="image-footer">

                            <p>Suivez nous sur .</p>
                            <div className="icons">
                                <i className="pi pi-facebook" style={{color: '#8e4396'}}></i>
                                <i className="pi pi-twitter" style={{color: '#8e4396'}}></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}