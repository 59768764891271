import React, { Component } from 'react';
import CISService from '../service/CISService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { MultiSelect } from 'primereact/multiselect';

export class FormGroupe extends Component {

    constructor() {
        super();
        this.state = {
            liste_Codification: [],
            liste_users: [],
            selectedUser: null,
            selectedCodif: null,
            showindicator: false,
            idCodif: '',
            libelleCodif: '',
            nomSelectedGroup: '',
            codeCodif: '',
            paramCodif: 'GROUPE',
            descriptionCodif: 'BACK OFFICE',
            actifCodif: 'Oui',

            liste_actif: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' }
            ],

            liste_type: [
                { label: 'BACK OFFICE', value: 'BACK OFFICE' },
                { label: 'FRONT OFFICE', value: 'FRONT OFFICE' }
            ],

            liste_nature: [],
            liste_motif: [],
            liste_groupe: [],
            liste_droit: [],
            liste_selected_nature: [],
            liste_selected_droit: [],
            idMotif: null,
            idGrp: null,

            ////////////////////////////
            visible_dlg_form_droit: false,
            modif: false, // pour vérifier s'il s'agit d'une modification ou un ajout
            visible: false, // pour afficher la boite de confirmation de suppression
            visible_del_droit: false, // pour afficher la boite de confirmation de suppression
            visible_dlg_form: false, // pour afficher la boite de dialogue du formation de saisie
            showindicator: false, // pour afficher l'état de chargement
            submit: false,// pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            msg: '',
            ////////////////////////////
        };
        this.edit_codification = this.edit_codification.bind(this);
        this.conf_delete_codification = this.conf_delete_codification.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.toogleDlgDroit = this.toogleDlgDroit.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.CISService = new CISService();
    }

    componentDidMount() {
        this.get_liste_codification();
        this.get_liste_motif();
    }

    conf_delete_data() {
        this.setState({ msg: 'Voulez-vous vraiment supprimer les lignes sélectionnées ?' });
        this.setState({ visible_del_droit: true });
    }

    /* Affichage de la boite de dialogue pour l'ajout d'une nouvelle nature */
    toogleDlgDroit(data) {
        this.setState({ selectedCodif: data, nomSelectedGroup: data.libelleCodif }, () => {
            this.get_liste_droit();
        });
        this.setState({ visible_dlg_form_droit: !this.state.visible_dlg_form_droit });
    }

    get_liste_nature() {
        this.setState({ showindicator: true, liste_nature: [] });
        this.CISService.get_liste_by_motif_grp(this.state.selectedCodif.idCodif, this.state.idMotif).then(data => {
            this.setState({ showindicator: false });
            if (data !== undefined) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.idNature, label: x.libelleNature }
                    });
                    this.setState({ liste_nature: liste });
                }

            }
        });
    }

    //Liste des motifs
    get_liste_motif() {
        this.CISService.get_liste_motif().then(data => {
            if (data !== undefined) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.idMotif, label: x.descriptionMotif }
                    });
                    this.setState({ liste_motif: liste });
                }
            }
        });
    }


    get_liste_droit() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_droit_traitement(this.state.selectedCodif.idCodif).then(data => this.setState({ liste_droit: data, showindicator: false }));
    }


    get_liste_codification() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_codification(this.state.paramCodif).then(data => {
            this.setState({ showindicator: false });
            this.setState({ liste_Codification: data });
        });
    }

    resultat_droit(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.get_liste_droit();
            this.setState({ liste_selected_droit: [] });
            this.setState({ liste_selected_nature: [] });
            this.get_liste_nature();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_codification();
            this.get_liste_codification();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
        this.setState({ submit: false });
    }

    edit_codification(data) {
        if (data != null && data != undefined) {
            this.setState({ libelleCodif: data.libelleCodif });
            this.setState({ codeCodif: data.codeCodif });
            this.setState({ descriptionCodif: data.descriptionCodif });
            this.setState({ actifCodif: data.actifCodif });
            this.setState({ modif: true });
            this.setState({ selectedCodif: data });
            this.setState({ visible_dlg_form: true });
        }
    }

    annule_codification() {
        this.setState({ libelleCodif: '' });
        this.setState({ codeCodif: '' });
        this.setState({ descriptionCodif: 'BACK OFFICE' });
        this.setState({ modif: false });
        this.setState({ visible_dlg_form: false });
    }


    enreg_codification() {
        this.setState({ submit: true });
        if (this.state.libelleCodif == '') {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else if (this.state.modif == false) {
            this.setState({ showindicator: true });
            let data = {
                idCodif: 0,
                libelleCodif: this.state.libelleCodif,
                codeCodif: this.state.codeCodif,
                paramCodif: this.state.paramCodif,
                descriptionCodif: this.state.descriptionCodif,
                actifCodif: 'Oui'
            }
            this.CISService.enreg_codification(data).then(data => this.resultat(data.code, data.contenu));
        } else {
            let data = this.state.selectedCodif;
            data.libelleCodif = this.state.libelleCodif;
            data.codeCodif = this.state.codeCodif;
            data.descriptionCodif = this.state.descriptionCodif;
            this.CISService.enreg_codification(data).then(data => this.resultat(data.code, data.contenu));
        }
    }

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 140 }}>
            <div className="col"><Button onClick={() => this.edit_codification(rowData)} style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button disabled={rowData.libelleCodif == 'Administrateur'} onClick={() => this.toogleDlgDroit(rowData)} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -15 }} icon="pi pi-sitemap" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_codification(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -15 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_codification() {
        if (this.state.libelleCodif == null || this.state.libelleCodif == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.CISService.delete_codification(this.state.selectedCodif).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_codification(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedCodif: data });
            this.setState({ msg: data.libelleCodif });
            this.setState({ visible: true });
        }
    }

    delete_data() {
        if (this.state.liste_selected_droit.length <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à supprimer' });
        } else {
            this.CISService.delete_Droit_Traitement(this.state.liste_selected_droit).then(data => this.resultat_droit(data.code, data.contenu));
        }
    }

    enreg_droit() {
        if (this.state.liste_selected_nature.length <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les natures' });
        } else {
            let i = 0;
            let formData = this.state.liste_selected_nature.map(x => {
                i = i + 1;
                return { idDtrt: i, niveauDtrt: 0, actifDtrt: 'Oui', idCodifGrp: { idCodif: this.state.selectedCodif.idCodif }, idNature: { idNature: x } }
            });
            this.CISService.enreg_Droit_Traitement(formData).then(data => this.resultat_droit(data.code, data.contenu));
        }
    }

    /* Affichage de la boite de dialogue pour l'ajout d'une nouvelle region */
    toogleDlg() {
        this.annule_codification();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedCodif == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_codification(this.state.selectedCodif)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    /* Exportation du tableau contenant la liste des groupes en fichier CSV */
    exportCSV = () => {
        //ref={(el) => { this.dt = el; }}
        this.dt.exportCSV("Liste_groupe");
    }

    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_codification();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="fluid card">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visible_del_droit} style={{ width: '250px' }} modal={true} footer={
                    <div>
                        <Button icon="pi pi-check" onClick={() => {
                            this.setState({ visible_del_droit: false });
                            this.delete_data();
                        }} label="Oui" />
                        <Button icon="pi pi-times" onClick={() => this.setState({ visible_del_droit: false })} label="Annuler" className="p-button-secondary" />
                    </div>
                } onHide={() => this.setState({ visible_del_droit: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment les lignes sélectionnées ?
                    </div>
                </Dialog>

                {/* Boite de dialogue de gestion des droits  */}
                <Dialog visible={this.state.visible_dlg_form_droit} style={{ width: '75%' }} header={"Droits de traitement du groupe " + this.state.nomSelectedGroup} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form_droit: false })}
                    footer={
                        <>
                            <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_form_droit: false })} />
                            <Button label="Supprimer" disabled={this.state.liste_selected_droit.length <= 0} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data()} style={{ width: 150 }} />
                        </>
                    }>
                    <div className="fluid card" >
                        <div className="formgrid grid">
                            <div className="field col-12 md:col-5">
                                <label htmlFor="libelleCommune">Motif *</label>
                                <Dropdown id="profil" options={this.state.liste_motif} value={this.state.idMotif} onChange={(e) => this.setState({ idMotif: e.value, liste_selected_nature: [], liste_nature: [] }, () => {
                                    this.get_liste_nature();
                                })} autoWidth={false} filter={true} filterBy='label' style={{ width: '100%' }} />
                            </div>
                            <div className="field col-12 md:col-5">
                                <label htmlFor="libelleCommune">Nature *</label>
                                <MultiSelect value={this.state.liste_selected_nature} options={this.state.liste_nature} onChange={(e) => this.setState({ liste_selected_nature: e.value })} optionLabel="label" filter={true} filterBy='label' panelStyle={{ width: '500px' }} />
                            </div>
                            <div className="field col-12 md:col-2">
                                <Button label="Ajouter" disabled={this.state.liste_selected_nature.length <= 0} icon="pi pi-check" onClick={(e) => this.enreg_droit()} style={{ width: 125, marginTop: 25 }} />
                            </div>

                        </div>
                        <DataTable value={this.state.liste_droit} paginatorPosition="bottom" paginator={true} rows={10} alwaysShowPaginator={false}
                            selection={this.state.liste_selected_droit} onSelectionChange={e => this.setState({ liste_selected_droit: e.value })}
                            responsive={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                        >
                            <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                            <Column header="No" body={(rowData, props) => {
                                return <div>
                                    <span >{props.rowIndex + 1}</span>
                                </div>
                            }} style={{ width: '5%' }} />
                            <Column field="idNature.libelleNature" filter={true} filterMatchMode='contains' header="Nature" />
                            <Column field="idNature.typeNature" filter={true} filterMatchMode='contains' header="Type" style={{ width: '15%' }} />
                            <Column field="idNature.idMotif.descriptionMotif" filter={true} filterMatchMode='contains' header="Motif" style={{ width: '20%' }} />
                        </DataTable>
                    </div>
                </Dialog>

                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                <div className="grid ">
                    <div className="col-12 md:col-6">
                        <span> <i className='pi pi-sitemap'></i> Gestion des groupes</span><hr />

                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_Codification} paginatorPosition="top" selectionMode="single" paginator={true} rows={5}
                            alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedCodif: e.data }, () => {
                                this.setState({ showindicator: true });
                                this.CISService.get_liste_utilisateur_groupe(this.state.selectedCodif.idCodif).then(data => this.setState({ liste_Utilisateur: data, showindicator: false }));
                            })} onRowSelect={e => this.setState({ selectedCodif: e.data })}
                            responsive={true} selection={this.state.selectedCodif} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                            <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '22%' }} />
                            <Column field="libelleCodif" filterMatchMode='contains' filter={true} header="Groupe" />
                            <Column field="descriptionCodif" filterMatchMode='contains' filter={true} header="Type" style={{ width: '25%' }} />
                            <Column field="actifCodif" filterMatchMode='contains' filter={true} header="Actif" style={{ width: '15%' }} />
                        </DataTable>


                        {/* Boite de dialogue d'ajout et de modification d'un groupe  */}
                        <Dialog visible={this.state.visible_dlg_form} style={{ width: '450px' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                            footer={<>
                                <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annule_codification()} />
                                <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_codification()} style={{ width: 120 }} />
                            </>}>
                            <div className="p-fluid">
                                <div className="field">
                                    <label htmlFor="libelleCodif">Groupe *</label>
                                    <InputText id="libelleCodif" value={this.state.libelleCodif} onChange={(e) => this.setState({ libelleCodif: e.target.value, descriptionCodif: e.target.value })} className={classNames({ 'p-invalid': this.state.submit && !this.state.libelleCodif })} />
                                    {this.state.submit && !this.state.libelleCodif && <small className="p-invalid" style={{ color: 'red' }}>Le Plateaux est obligatoire.</small>}
                                </div>

                                {this.state.libelleCodif !== 'Administrateur' ?
                                    <div className="field">
                                        <label htmlFor="descriptionCodif">Type * </label>
                                        <Dropdown id="descriptionCodif" options={this.state.liste_type} value={this.state.descriptionCodif} onChange={(e) => this.setState({ descriptionCodif: e.target.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' />
                                    </div> : null}


                                <div className="field">
                                    <label htmlFor="actifCodif">Actif *</label>
                                    <Dropdown id="actifCodif" options={this.state.liste_actif} value={this.state.actifCodif} onChange={(e) => this.setState({ actifCodif: e.target.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' />
                                </div>
                            </div>
                        </Dialog>

                    </div>

                    <div className="col-12 md:col-6">
                        <span> <i className='pi pi-users'></i> Liste des utilisateurs</span><hr />
                        <DataTable value={this.state.liste_Utilisateur} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={100}
                            alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedUser: e.data })} onRowSelect={e => this.setState({ selectedUser: e.data })}
                            responsive={true} selection={this.state.selectedUser} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                            <Column body={(rowData, column) => {
                                if (rowData.actifUser == 'Oui') return <Button icon="pi pi-thumbs-up" rounded severity="success" aria-label="Search" />
                                else return <Button icon="pi pi-thumbs-down" rounded severity="warning" aria-label="Search" />
                            }} header="#" style={{ width: '10%' }} />
                            <Column field="loginUser" filter={true} filterMatchMode='contains' header="Login" sortable={true} style={{ width: '40%' }} />
                            <Column field="matrPers.nomPers" body={(rowData, column) => {
                                return <div>
                                    <span>{rowData.matrPers.nomPers} {rowData.matrPers.prenomPers}</span>
                                </div>
                            }} filter={true} filterMatchMode='contains' header="Titulaire" sortable={true} style={{ width: '50%' }} />
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }
}