import React, { Component } from 'react';
import CISService from '../service/CISService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';

export class FormMotif extends Component {
    constructor() {
        super();
        this.state = {
            liste_Motif: [],
            liste_profil: [],
            idMotif: '',
            descriptionMotif: '',
            codeMotif: '',
            actifMotif: '',
            showindicator: false,
            selectedMotif: null,

            liste_actif: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' }
            ],

            liste_type: [
                { label: 'DEMANDE', value: 'DEMANDE' },
                { label: 'RECLAMATION', value: 'RECLAMATION' },
                { label: 'DIVERS', value: 'DIVERS' },
            ],

            liste_Nature: [],
            idNature: '',
            libelleNature: '',
            descriptionNature: '',
            codeNature: '',
            typeNature: 'RECLAMATION',
            delaiNature: '',
            selectedNature: null,
            idCodifProfil: null,

            ////////////////////////////
            modif: false, // pour vérifier s'il s'agit d'une modification ou un ajout un motif
            visible: false, // pour afficher la boite de confirmation de suppression un motif
            visible_dlg_form: false, // pour afficher la boite de dialogue du formation de saisie des motifs


            modifNature: false, // pour vérifier s'il s'agit d'une modification ou un ajout une nature
            visibleNature: false, // pour afficher la boite de confirmation de suppression des natures
            visible_dlg_form_nature: false, // pour afficher la boite de dialogue du formation de saisie des natures


            showindicator: false, // pour afficher l'état de chargement
            submit: false,// pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge du formulaire motif
            submitNature: false,// pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge du formulaire nature
            msg: '',

            ////////////////////////////
        };
        this.edit_motif = this.edit_motif.bind(this);
        this.conf_delete_motif = this.conf_delete_motif.bind(this);

        this.edit_nature = this.edit_nature.bind(this);
        this.conf_delete_nature = this.conf_delete_nature.bind(this);
        this.toogleDlgNature = this.toogleDlgNature.bind(this);

        this.toogleDlg = this.toogleDlg.bind(this);
        this.CISService = new CISService();

    }

    //Liste des profils ou plateaux
    get_liste_profil() {
        this.CISService.get_liste_codification('PLATEAUX').then(data => {
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libelleCodif }
                });
                this.setState({ liste_profil: liste });
            }
        });
    }

    componentDidMount() {
        this.get_liste_motif();
        this.get_liste_profil();
    }

    get_liste_motif() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_motif().then(data => this.setState({ liste_Motif: data, showindicator: false }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_motif();
            this.get_liste_motif();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
        this.setState({ submit: false });
    }


    edit_motif(data) {
        if (data != null && data != undefined) {
            this.setState({ descriptionMotif: data.descriptionMotif });
            this.setState({ codeMotif: data.codeMotif });
            this.setState({ actifMotif: data.actifMotif });
            this.setState({ modif: true });
            this.setState({ selectedMotif: data });
            this.setState({ visible_dlg_form: true });
        }
    }

    annule_motif() {
        this.setState({ descriptionMotif: '' });
        this.setState({ codeMotif: '' });
        this.setState({ actifMotif: '' });
        this.setState({ visible_dlg_form: false });
    }


    enreg_motif() {
        this.setState({ submit: true });
        if (this.state.descriptionMotif == '') {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else if (this.state.modif == false) {
            this.setState({ showindicator: true });
            let data = {
                idMotif: 0,
                descriptionMotif: this.state.descriptionMotif,
                codeMotif: this.state.codeMotif,
                actifMotif: this.state.actifMotif
            }
            this.CISService.enreg_motif(data).then(data => this.resultat(data.code, data.contenu));
        } else {
            this.setState({ showindicator: true });
            let data = this.state.selectedMotif;
            data.descriptionMotif = this.state.descriptionMotif;
            data.codeMotif = this.state.codeMotif;
            data.actifMotif = this.state.actifMotif;
            this.CISService.enreg_motif(data).then(data => this.resultat(data.code, data.contenu));
        }
    }

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 130 }}>
            <div className="col"><Button onClick={() => this.edit_motif(rowData)}  style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.toogleDlgNature(rowData)} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -17 }} icon="pi pi-sitemap" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_motif(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -17 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_motif() {
        if (this.state.descriptionMotif == null || this.state.descriptionMotif == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.CISService.delete_motif(this.state.selectedMotif).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_motif(data) {
        if (data != null && data != undefined) {
            this.setState({ descriptionMotif: data });
            this.setState({ msg: data.descriptionMotif });
            this.setState({ selectedMotif: data });
            this.setState({ visible: true });
        }
    }


    get_liste_nature() {
        this.CISService.get_liste_nature(this.state.selectedMotif.idMotif).then(data => this.setState({ liste_Nature: data }));
    }

    resultat_nature(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_nature();
            this.get_liste_nature();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_nature(data) {
        if (data != null && data != undefined) {
            this.setState({ libelleNature: data.libelleNature });
            this.setState({ descriptionNature: data.descriptionNature });
            this.setState({ codeNature: data.codeNature });
            this.setState({ typeNature: data.typeNature });
            this.setState({ delaiNature: data.delaiNature });
            this.setState({ idCodifProfil: data.idCodifProfil.idCodif });
            this.setState({ modifNature: true });
            this.setState({ selectedNature: data });
        }
    }

    annule_nature() {
        this.setState({ libelleNature: '' });
        this.setState({ descriptionNature: '' });
        this.setState({ codeNature: '' });
        this.setState({ typeNature: 'RECLAMATION' });
        this.setState({ delaiNature: '48' });
        this.setState({ idCodifProfil: null });
        this.setState({ modifNature: false });
    }


    enreg_nature() {
        this.setState({ submitNature: false });
        if (this.state.libelleNature == '' || this.state.typeNature == ''
            || this.state.delaiNature == '' || this.state.idCodifProfil == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else if (this.state.modifNature == false) {
            let data = {
                idNature: 0,
                libelleNature: this.state.libelleNature,
                descriptionNature: this.state.descriptionNature,
                codeNature: this.state.codeNature,
                typeNature: this.state.typeNature,
                delaiNature: this.state.delaiNature,
                idCodifProfil: { idCodif: this.state.idCodifProfil },
                idMotif: this.state.selectedMotif,
            }
            this.CISService.enreg_nature(data).then(data => this.resultat_nature(data.code, data.contenu));
        } else {
            let data = this.state.selectedNature;
            data.libelleNature = this.state.libelleNature;
            data.descriptionNature = this.state.descriptionNature;
            data.codeNature = this.state.codeNature;
            data.typeNature = this.state.typeNature;
            data.delaiNature = this.state.delaiNature;
            data.idCodifProfil = { idCodif: this.state.idCodifProfil };
            this.CISService.enreg_nature(data).then(data => this.resultat_nature(data.code, data.contenu));
        }
    }

    actionTemplateNature(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_nature(rowData)}  style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_nature(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -17 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_nature() {
        if (this.state.selectedNature == null || this.state.selectedNature == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.CISService.delete_nature(this.state.selectedNature).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_nature(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedNature: data });
            this.setState({ msg: data.libelleNature });
            this.setState({ visibleNature: true });
        }
    }


    /* Affichage de la boite de dialogue pour l'ajout d'un nmotif  */
    toogleDlg() {
        this.annule_motif();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }


    /* Affichage de la boite de dialogue pour l'ajout d'une nouvelle nature */
    toogleDlgNature(data) {
        this.annule_nature();
        this.setState({ selectedMotif: data }, () => {
            this.get_liste_nature();
        });
        this.setState({ visible_dlg_form_nature: !this.state.visible_dlg_form_nature });
    }


    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedMotif == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_motif(this.state.selectedMotif)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )
    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    /* Exportation du tableau contenant la liste des langues en fichier CSV */
    exportCSV = () => {
        //ref={(el) => { this.dt = el; }}
        this.dt.exportCSV("Liste_natures");
    }

    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_motif();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        const dialogFooterNature = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visibleNature: false });
                    this.delete_nature();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visibleNature: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid col-12 card">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visibleNature} style={{ width: '250px' }} modal={true} footer={dialogFooterNature} onHide={() => this.setState({ visibleNature: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_Motif} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={15}
                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedMotif: e.data })} onRowSelect={e => this.setState({ selectedMotif: e.data })}
                    responsive={true} selection={this.state.selectedMotif} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                    <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                    <Column field="descriptionMotif" filter={true} filterMatchMode='contains' header="Motif" sortable={true} style={{ width: '55%' }} />
                    <Column field="codeMotif" filter={true} filterMatchMode='contains' header="Code" sortable={true} style={{ width: '20%' }} />
                    <Column field="actifMotif" filter={true} filterMatchMode='contains' header="Actif" sortable={true} style={{ width: '15%' }} />
                </DataTable>

                {/* Boite de dialogue d'ajout et de modification d'un motif  */}
                <Dialog visible={this.state.visible_dlg_form} style={{ width: '450px' }} header={this.state.modif == true ? "Modification motif" : "Ajout motif"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annule_motif()} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_motif()} style={{ width: 120 }} />
                    </>}>
                    <div className="p-fluid">
                        <div className="field">
                            <label htmlFor="description">Motif *</label>
                            <InputTextarea id="description" autoFocus rows={2} value={this.state.descriptionMotif} onChange={(e) => this.setState({ descriptionMotif: e.target.value })} className={classNames({ 'p-invalid': this.state.submit && !this.state.descriptionMotif })} />
                            {this.state.submit && !this.state.descriptionMotif && <small className="p-invalid" style={{ color: 'red' }}>Le motif est obligatoire.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="code">Code </label>
                            <InputText id="code" value={this.state.codeMotif} onChange={(e) => this.setState({ codeMotif: e.target.value })} className={classNames({ 'p-invalid': this.state.submit && !this.state.codeMotif })} />
                            {this.state.submit && !this.state.codeMotif && <small className="p-invalid" style={{ color: 'red' }}>Le code est obligatoire.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="actif">Actif </label>
                            <Dropdown id="actif" options={this.state.liste_actif} value={this.state.actifMotif} onChange={(e) => this.setState({ actifMotif: e.target.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' />
                        </div>
                    </div>
                </Dialog>



                {/* Boite de dialogue d'ajout et de modification d'une nature  */}
                <Dialog visible={this.state.visible_dlg_form_nature} style={{ width: '80%' }} header={this.state.modifNature == true ? "Modification d'une nature" : "Ajout d'une nature"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form_nature: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => {
                            this.annule_nature();
                            this.setState({ visible_dlg_form_nature: false });
                        }} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_nature()} style={{ width: 120 }} />
                    </>}>
                    <div className="p-fluid">
                        <div className="formgrid grid" style={{ marginTop: 13 }}>
                            <div className="field col-6" style={{ padding: 4 }}>
                                <span className="p-float-label">
                                    <InputTextarea id="Nature" rows={2} value={this.state.libelleNature} onChange={(e) => this.setState({ libelleNature: e.target.value, descriptionNature: 'Le client souhaite faire une réclamation concernant son '+e.target.value.toLowerCase() })} className={classNames({ 'p-invalid': this.state.submitNature && !this.state.libelleNature })} />
                                    <label htmlFor="Nature">Nature *</label>
                                </span>
                                {this.state.submitNature && !this.state.libelleNature && <small className="p-invalid" style={{ color: 'red' }}>La nature est obligatoire.</small>}
                            </div>
                            <div className="field col-6"style={{ padding: 4 }}>
                                <span className="p-float-label">
                                    <InputTextarea id="description" rows={2} value={this.state.descriptionNature} onChange={(e) => this.setState({ descriptionNature: e.target.value })} className={classNames({ 'p-invalid': this.state.submitNature && !this.state.descriptionNature })} />
                                    <label htmlFor="Description">Description </label>
                                </span>
                                {this.state.submitNature && !this.state.descriptionNature && <small className="p-invalid" style={{ color: 'red' }}>La description est obligatoire.</small>}
                            </div>
                          
                            <div className="field col-4" style={{ padding: 4 }}>
                                <span className="p-float-label">
                                    <InputText id="delai" keyfilter={'pnum'} value={this.state.delaiNature} onChange={(e) => this.setState({ delaiNature: e.target.value })} className={classNames({ 'p-invalid': this.state.submitNature && !this.state.delaiNature })} />
                                    <label htmlFor="Délai">Délai (H) *</label>
                                </span>
                                {this.state.submitNature && !this.state.delaiNature && <small className="p-invalid" style={{ color: 'red' }}>Le délai est obligatoire.</small>}
                            </div>

                            <div className="field col-4" style={{ padding: 4 }}>

                                <span className="p-float-label">
                                    <Dropdown id="Type" options={this.state.liste_type} value={this.state.typeNature} onChange={(e) => this.setState({ typeNature: e.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submitNature && !this.state.typeNature })} />
                                    <label htmlFor="Type">Type *</label>
                                </span>
                                {this.state.submitNature && !this.state.typeNature && <small className="p-invalid" style={{ color: 'red' }}>Le type est obligatoire.</small>}
                            </div>

                            <div className="field col-4" style={{ padding: 4 }}>
                                <span className="p-float-label">
                                    <Dropdown id="profil" options={this.state.liste_profil} value={this.state.idCodifProfil} onChange={(e) => this.setState({ idCodifProfil: e.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submitNature && !this.state.idCodifProfil })} />
                                    <label htmlFor="profil">Profil *</label>
                                </span>
                                {this.state.submitNature && !this.state.idCodifProfil && <small className="p-invalid" style={{ color: 'red' }}>Le profil est obligatoire.</small>}
                            </div>
                        </div>
                    </div>

                    <DataTable value={this.state.liste_Nature} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={5}
                        alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedNature: e.data })} onRowSelect={e => this.setState({ selectedNature: e.data })}
                        responsive={true} selection={this.state.selectedNature} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                        <Column header="Action" body={this.actionTemplateNature.bind(this)} style={{ width: '10%' }} />
                        <Column field="libelleNature" filter={true} filterMatchMode='contains' header="Nature" sortable={true} />
                        <Column field="typeNature" filter={true} filterMatchMode='contains' header="Type" sortable={true} style={{ width: '15%' }} />
                        <Column field="delaiNature"  header="Délai (H)" sortable={true} style={{ width: '12%' }} />
                        <Column field="idCodifProfil.libelleCodif" filter={true} filterMatchMode='contains' header="Profil" sortable={true} style={{ width: '10%' }} />
                    </DataTable>
                </Dialog>


            </div>



        );
    }
}