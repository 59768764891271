import React, { Component } from 'react';
import CISService from '../service/CISService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import 'moment';
import 'moment/locale/fr';
import { classNames } from 'primereact/utils';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';

export class FormUtilisateur extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            liste_groupe: [],
            liste_personnel: [],
            selectedData: null,
            idUser: '',
            loginUser: '',
            actifUser: 'Oui',
            pwdUser: '',
            chpwdUser: '',
            refUser: '',
            matrPers: null,
            showindicator: false,
            idCodifGrp: '', //Clé étrangère de la table Codification
            liste_actif: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' }
            ],

            ////////////////////////////
            modif: false, // pour vérifier s'il s'agit d'une modification ou un ajout
            visible: false, // pour afficher la boite de confirmation de suppression
            visible_dlg_form: false, // pour afficher la boite de dialogue du formation de saisie
            showindicator: false, // pour afficher l'état de chargement
            submit: false,// pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            msg: '',
            ////////////////////////////
        };
        this.edit_utiisateur = this.edit_utiisateur.bind(this);
        this.conf_delete_utiisateur = this.conf_delete_utiisateur.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.CISService = new CISService();

    }



    componentDidMount() {
        this.get_liste_data();
        this.get_liste_groupe();
        this.get_liste_personnel();
    }

    get_liste_data() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_utilisateur().then(data => this.setState({ liste_data: data, showindicator: false }));
    }

    //Liste des groupes
    get_liste_groupe() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_codification('GROUPE').then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libelleCodif }
                });
                this.setState({ liste_groupe: liste });
            }
        });
    }

    //Liste des personnels
    get_liste_personnel() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_personnel().then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.matrPers, label: x.nomPers + ' ' + x.prenomPers }
                });
                this.setState({ liste_personnel: liste });
            }
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_data();
            this.get_liste_data();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
        this.setState({ submit: false });
    }

    edit_utiisateur(data) {
        if (data != null && data != undefined) {
            this.setState({ loginUser: data.loginUser });
            this.setState({ actifUser: data.actifUser });
            this.setState({ pwdUser: data.pwdUser });
            this.setState({ chpwdUser: data.chpwdUser });
            this.setState({ refUser: data.refUser });
            this.setState({ matrPers: data.matrPers.matrPers });
            this.setState({ idCodifGrp: data.idCodifGrp.idCodif });
            this.setState({ modif: true ,visible_dlg_form: true});
            this.setState({ selectedData: data });
        }
    }

    annule_data() {
        this.setState({ loginUser: '' });
        this.setState({ actifUser: '' });
        this.setState({ pwdUser: '' });
        this.setState({ chpwdUser: '' });
        this.setState({ refUser: '' });
        this.setState({ id_etabl: '' });
        this.setState({ iduser: '' });
        this.setState({ matrPers: '' });
        this.setState({ idCodifGrp: '' });
        this.setState({ selectedData: null });
        this.setState({ modif: false });
        this.setState({ visible_dlg_form: false });
        this.forceUpdate();
    }

    enreg_data() {
        this.setState({ submit: true });
        if (this.state.loginUser == "" || this.state.matrPers == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else if (this.state.modif == false) {
            this.setState({ showindicator: true });
            let data = {
                idUser: 0,
                loginUser: this.state.loginUser,
                actifUser: this.state.actifUser,
                pwdUser: '0000',
                chpwdUser: 0,
                refUser: this.state.refUser,
                matrPers: { matrPers: this.state.matrPers },
                idCodifGrp: { idCodif: this.state.idCodifGrp }
            }
            this.CISService.enreg_utilisateur(data).then(data => this.resultat(data.code, data.contenu));
        } else {
            this.setState({ showindicator: true });
            let data = this.state.selectedData;
            data.loginUser = this.state.loginUser;
            data.actifUser = this.state.actifUser;
            data.idCodifGrp = { idCodif: this.state.idCodifGrp }
            this.CISService.enreg_utilisateur(data).then(data => this.resultat(data.code, data.contenu));
        }

    }

    activer_compte(data) {
        if (data !== null && data !== undefined) {
            if (data.actifUser == "Oui") {
                data.actifUser = "Non";
                this.setState({ showindicator: true });
                this.CISService.enreg_utilisateur(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                data.actifUser = "Oui";
                this.setState({ showindicator: true });
                this.CISService.enreg_utilisateur(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }


    reset_password(rowData) {
        if (rowData === null || rowData === undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à modifier' });
        } else {
            this.setState({ showindicator: true });
            rowData.pwdUser = '0000';
            this.CISService.enreg_utilisateur(rowData).then(data => {
                if (data !== null && data !== undefined) {
                    this.setState({ showindicator: false });
                    if (data.code === 200) {
                        this.toast.show({ severity: 'success', summary: 'Success', detail: 'Reinitialisation effectuée avec succès' });
                    } else {
                        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec reinitialisation' });
                    }
                }
            });
        }
    }


    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 120 }}>
            <div className="col"><Button onClick={() => this.edit_utiisateur(rowData)} style={{ width: 25, height: 25, marginLeft: -10 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.reset_password(rowData)} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -15 }} icon="pi pi-refresh" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_utiisateur(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -15 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_utiisateur() {
        if (this.state.actifUser == null || this.state.actifUser == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.CISService.delete_utilisateur(this.state.selectedData).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_utiisateur(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedData: data });
            this.setState({ msg: data.loginUser });
            this.setState({ visible: true });
        }
    }



    /* Affichage de la boite de dialogue pour l'ajout d'une nouvelle region */
    toogleDlg() {
        this.annule_data();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedData == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data(this.state.selectedData)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_utiisateur();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="card p-fluid col-12" style={{height: 800}}>
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>


                <DataTable value={this.state.liste_data} paginatorPosition="top" selectionMode="single" paginator={true} rows={10} responsiveLayout='scroll'
                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedData: e.data })} onRowSelect={e => this.setState({ selectedData: e.data })}
                    responsive={true} selection={this.state.selectedData} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                    <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                    <Column field="loginUser" filter={true} filterMatchMode='contains' header="Login" sortable={true} style={{ width: '13%' }} />
                    <Column field="idCodifGrp.libelleCodif" filter={true} filterMatchMode='contains' header="Profil" sortable={true} style={{ width: '12%' }} />
                    <Column field="matrPers.nomPers" filter={true} filterMatchMode='contains' header="Nom" sortable={true} style={{ width: '12%' }} />
                    <Column field="matrPers.prenomPers" filter={true} filterMatchMode='contains' header="Prénom" sortable={true} style={{ width: '20%' }} />
                    <Column field="matrPers.idStruct.libelleStruct" filter={true} filterMatchMode='contains' header="Site" sortable={true} style={{ width: '25%' }} />
                    <Column field="actifUser" filter={true} filterMatchMode='contains' header="Actif" sortable={true} style={{ width: '10%' }} />
                </DataTable>


                {/* Boite de dialogue d'ajout et de modification d'une region  */}
                <Dialog visible={this.state.visible_dlg_form} style={{ width: '450px' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annule_data()} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 120 }} />
                    </>}>
                    <div className="p-fluid">
                        <div className="field">
                            <label htmlFor="libelleCodif">Login *</label>
                            <InputText id="loginUser" value={this.state.loginUser} onChange={(e) => this.setState({ loginUser: e.target.value })} className={classNames({ 'p-invalid': this.state.submit && !this.state.loginUser })} />
                            {this.state.submit && !this.state.loginUser && <small className="p-invalid" style={{ color: 'red' }}>Le login est obligatoire.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="profil">Profil *</label>
                            <Dropdown id="profil" options={this.state.liste_groupe} value={this.state.idCodifGrp} onChange={(e) => this.setState({ idCodifGrp: e.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.idCodifGrp })} />
                            {this.state.submit && !this.state.idCodifGrp && <small className="p-invalid" style={{ color: 'red' }}>Le profil est obligatoire.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="descriptionCodif">Titulaire * </label>
                            <Dropdown id="actifUser" options={this.state.liste_personnel} value={this.state.matrPers} onChange={(e) => this.setState({ matrPers: e.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.matrPers })} />
                            {this.state.submit && !this.state.matrPers && <small className="p-invalid" style={{ color: 'red' }}>Le titulaire est obligatoire.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="actif">Actif *</label>
                            <Dropdown id="actif" options={this.state.liste_actif} value={this.state.actifUser} onChange={(e) => this.setState({ actifUser: e.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.actifUser })} />
                            {this.state.submit && !this.state.actifUser && <small className="p-invalid" style={{ color: 'red' }}>Le statut du compte est obligatoire.</small>}
                        </div>
                    </div>
                </Dialog>


            </div>
        );
    }
}