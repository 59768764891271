import React, { Component } from 'react';
import CISService from '../service/CISService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { SplitButton } from 'primereact/splitbutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import { InputMask } from 'primereact/inputmask';
import { TabView, TabPanel } from 'primereact/tabview';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import { Badge } from 'primereact/badge';

export class FormRequete extends Component {
    constructor() {
        super();
        this.state = {
            liste_requete: [],
            liste_client: [],
            liste_motif: [],
            liste_nature: [],
            liste_traitement: [],
            liste_structure: [],
            liste_categorie: [],
            liste_offre: [],
            liste_selected_offre: [],
            liste_abonnement: [],
            liste_type_piece: [],
            liste_langue: [],
            liste_quartier: [],
            selectedClient: null,
            selectedAbonnement: null,
            selectedTraitement: null,
            liste_item_export: [
                {
                    label: 'Exporter en CVS',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.exportCSV();
                    }
                },
                {
                    label: 'Exporter en XLS',
                    icon: 'pi pi-file-excel',
                    command: (e) => {
                        this.exportExcel();
                    }
                },
            ],
            idReq: 0,
            pd1: new Date(),
            pd2: new Date(),
            rtelCli: '',
            ridStruct: 'Tous',
            rCategorie: 'Tous',
            rtypeCli: 'Tous',
            retatReq: 'Tous',
            rstatutReq: 'Tous',
            ridCli: 'Tous',
            ridMotif: 'Tous',
            ridNature: 'Tous',
            ////////  CLENTS  /////////////
            nomCli: '',
            prenomCli: '',
            telCli: '',
            emailCli: '',
            adrCli: '',
            dateNaissCli: null,
            lieuNaissCli: '',
            sexeCli: null,
            file: null,
            filePI: null,
            fileDossier: null,
            urlfile: null,
            urlfilePI: null,
            urlfileDossier: null,
            photoCli: 'nophoto.png',
            numPiCli: '',
            dossierCli: '',
            photoPiCli: '',
            typeCli: '',
            idQuartier: null,
            idCodifCategorie: null,
            idStruct: null, //Clé étrangère de la table Structure
            idCodifTypePiece: null, //Clé étrangère de la table Codification
            idCodifLang: null, //Clé étrangère de la table Codification

            ////////////////////////////////////////
            selectedReq: null,
            contenuReq: '',
            contactReq: '',
            etatReq: '',
            lieuReq: '',
            statutReq: '',
            ticketReq: '',
            noteReq: '',
            canalReq: '',
            idMotif: null,
            idNature: null,
            idCli: null,  //Clé étrangère de la table Client
            idStruct: '', //Clé étrangère de la table Structure
            reponseTrt: '',
            actionTrt: '',
            checkCloture: false,
            ////////////////////////////
            checkPhone: true, //Véfifier si le numéro existe déja
            modif: false, // pour vérifier s'il s'agit d'une modification ou un ajout
            modif_cli: false, // pour vérifier s'il s'agit d'une modification ou un ajout
            modif_abn: false, // pour vérifier s'il s'agit d'une modification ou un ajout
            visible: false, // pour afficher la boite de confirmation de suppression
            visible_del_abonnement: false, // pour afficher la boite de confirmation de suppression
            visible_dlg_form: false, // pour afficher la boite de dialogue du formation de saisie
            visible_dlg_form_req: false, // pour afficher la boite de dialogue du formation de saisie
            visible_dlg_form_trt: false, // pour afficher la boite de dialogue du formation de saisie
            visible_dlg_historique_trt: false, // pour afficher la boite de dialogue du formation de saisie
            visible_dlg_abn: false, // pour afficher la boite de dialogue des abonnements
            visible_dlg_detail: false,
            visible_dlg_toogle_statut: false,
            showindicator: false, // pour afficher l'état de chargement
            submitTrt: false,
            modif_trt: false,
            submit: false, // pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            submitInfoCli: false, // pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            submitAbn: false, // pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge

            ////////////////////////////
        };
        this.editRequete = this.editRequete.bind(this);
        this.conf_deleteRequete = this.conf_deleteRequete.bind(this);
        this.conf_delete_abonnement = this.conf_delete_abonnement.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.CISService = new CISService();

    }

    componentDidMount() {
        if (this.get_type_operation() == 'liste') {
            this.get_listeRequete();
        } else {
            let telCli = this.props.match.params.telCli !== '0' && this.props.match.params.telCli !== undefined ? this.props.match.params.telCli : '';
            this.annuleRequete();
            this.annule_client();
            this.setState({
                telCli: telCli,
                contactReq: telCli,
                liste_requete: [],
                modif: false,
                modif_cli: false,
                selectedClient: null,
                selectedAbonnement: false,
                selectedReq: null,
                ridCli: 'Tous'
            }, () => {
                if (this.state.telCli.length > 0) {
                    this.search();
                }
            });
            this.setState({
                pd1: 'Tous',
                pd2: 'Tous',
            })
        }
        this.get_liste_offre();
        this.get_liste_codification();
        this.get_liste_quartier();
        this.get_liste_motif();
    }


    componentDidUpdate(prevProps) {
        if (prevProps.match.params.typeOp !== this.props.match.params.typeOp) {
            if (this.props.match.params.typeOp == 'new') {
                let telCli = this.props.match.params.telCli !== '0' && this.props.match.params.telCli !== undefined ? this.props.match.params.telCli : '';
                this.annuleRequete();
                this.annule_client();
                this.setState({
                    telCli: telCli,
                    liste_requete: [],
                    modif: false,
                    modif_cli: false,
                    selectedClient: null,
                    selectedAbonnement: false,
                    selectedReq: null,
                    contactReq: '',
                    ridCli: 'Tous'
                }, () => {
                    if (this.state.telCli.length > 0) {
                        this.search();
                    }
                });
                this.setState({
                    pd1: 'Tous',
                    pd2: 'Tous',
                })
            } else if (this.props.match.params.typeOp == 'liste') {
                this.setState({ pd1: new Date(), pd2: new Date(), liste_requete: [] }, () => {
                    this.get_listeRequete();
                })
            }

        }
    }

    get_listeRequete() {
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        this.CISService.get_liste_requete(spd1, spd2, this.state.ridCli, this.state.ridMotif, this.state.ridNature, this.state.retatReq, this.state.rstatutReq, this.state.rCategorie).then(data => this.setState({ liste_requete: data, showindicator: false }));
    }

    get_liste_client() {
        this.CISService.get_liste_client().then(data => this.setState({ liste_client: data }));
    }

    getAbonnemts() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_abonnement_client(this.state.selectedClient.idCli).then(data => this.setState({ liste_abonnement: data, showindicator: false }));
    }

    //Liste des offres
    get_liste_offre() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_offre().then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idOffre, label: x.libelleOffre }
                });
                this.setState({ liste_offre: liste });
            }
        });
    }

    //Liste des motifs
    get_liste_motif() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_motif().then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idMotif, label: x.descriptionMotif }
                });
                this.setState({ liste_motif: liste });
            }
        });
    }

    get_liste_nature(idMotif) {
        this.CISService.get_liste_nature(idMotif).then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idNature, label: x.descriptionNature }
                });
                this.setState({ liste_nature: liste });
            }
        });
    }


    search() {
        this.setState({ showindicator: true });
        this.CISService.getClientbyPhone(this.state.telCli).then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data.idCli !== undefined) {
                this.setState({ nomCli: data.nomCli, idCli: data.idCli });
                this.setState({ prenomCli: data.prenomCli });
                this.setState({ telCli: data.telCli });
                this.setState({ emailCli: data.emailCli });
                this.setState({ adrCli: data.adrCli });
                this.setState({ dateNaissCli: data.dateNaissCli });
                this.setState({ lieuNaissCli: data.lieuNaissCli });
                this.setState({ sexeCli: data.sexeCli });
                this.setState({ photoCli: data.photoCli });
                this.setState({ numPiCli: data.numPiCli });
                this.setState({ photoPiCli: data.photoPiCli });
                this.setState({ dossierCli: data.dossierCli });
                this.setState({ idCodifCategorie: data.idCodifCategorie.idCodif });
                this.setState({ typeCli: data.typeCli });
                this.setState({ idCodifTypePiece: data.idCodifTypePiece.idCodif });
                this.setState({ idCodifLang: data.idCodifLang.idCodif });
                this.setState({ idQuartier: data.idQuartier.idQuartier });
                this.setState({ modif_cli: true });
                this.setState({ selectedClient: data, ridCli: data.idCli }, () => {
                    this.get_listeRequete();
                });
                if (data.abonnementsCli !== null) {
                    if (data.abonnementsCli.length > 0) {
                        this.setState({ liste_selected_offre: data.abonnementsCli.map(x => { return x.idOffre.idOffre }) })
                    }
                }
                if (data.photoCli !== null && data.photoCli !== '') {
                    this.setState({ urlfile: this.CISService.download_file(data.photoCli) })
                } else {
                    this.setState({ urlfile: null })
                }
                if (data.photoPiCli !== null && data.photoPiCli !== '') {
                    this.setState({ urlfilePI: 'OK' })
                } else {
                    this.setState({ urlfilePI: null })
                }
                if (data.dossierCli !== null && data.dossierCli !== '') {
                    this.setState({ urlfileDossier: 'OK' })
                } else {
                    this.setState({ urlfileDossier: null })
                }
            } else {
                this.setState({ nomCli: '', idCli: 0 });
                this.setState({ prenomCli: '' });
                this.setState({ selectedClient: null });
                this.setState({ emailCli: '' });
                this.setState({ adrCli: '' });
                this.setState({ dateNaissCli: '' });
                this.setState({ lieuNaissCli: '' });
                this.setState({ sexeCli: '' });
                this.setState({ photoCli: '' });
                this.setState({ numPiCli: '' });
                this.setState({ photoPiCli: '' });
                this.setState({ dateEnregCli: '' });
                this.setState({ typeCli: '' });
                this.setState({ idQuartier: null });
                this.setState({ idStruct: null });
                this.setState({ idCodifTypePiece: null });
                this.setState({ idCodifLang: null });
                this.setState({ idCodifCategorie: null });
                this.setState({ modif_cli: false });
                this.setState({ urlfile: null });
                this.setState({ urlfilePI: null });
                this.setState({ urlfileDossier: null });
                this.setState({ file: null });
                this.setState({ filePI: null, liste_selected_offre: [] });
                this.setState({ fileDossier: null, modif_cli: false });
                this.setState({ visible_dlg_form: false });
            }
        });
    }

    //Liste Des Quartiers
    get_liste_quartier() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_quartier().then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let quartier = data.map(x => {
                    return { value: x.idQuartier, label: x.libelleQuartier }
                });
                this.setState({ liste_quartier: quartier });
            }
        });
    }

    //Liste des Codifications 
    get_liste_codification() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_codification('LANGUE').then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libelleCodif }
                });
                this.setState({ liste_langue: liste });
            }
        });
        this.setState({ showindicator: true });
        this.CISService.get_liste_codification('TYPE_PIECE').then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libelleCodif }
                });
                this.setState({ liste_type_piece: liste });
            }
        });
        this.setState({ showindicator: true });
        this.CISService.get_liste_codification('CATEGORIE_CLIENT').then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.descriptionCodif }
                });
                this.setState({ liste_categorie: liste });
            }
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annuleRequete();
            this.get_listeRequete();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
        this.setState({ submit: false });
    }

    editRequete(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedReq: data });
            this.get_liste_nature(data.idNature.idMotif.idMotif);
            this.setState({ contenuReq: data.contenuReq });
            this.setState({ contactReq: data.contactReq });
            this.setState({ etatReq: data.etatReq });
            this.setState({ lieuReq: data.lieuReq });
            this.setState({ statutReq: data.statutReq });
            this.setState({ canalReq: data.canalReq });
            this.setState({ idMotif: data.idNature.idMotif.idMotif });
            this.setState({ idNature: data.idNature.idNature });
            this.setState({ visible_dlg_form_req: true, modif: true });
        }
    }

    annuleRequete() {
        this.setState({ contenuReq: '' });
        this.setState({ etatReq: '' });
        this.setState({ lieuReq: '' });
        this.setState({ statutReq: '' });
        this.setState({ ticketReq: '' });
        this.setState({ noteReq: '' });
        this.setState({ canalReq: '' });
        this.setState({ sonderReq: '' });
        this.setState({ idNature: '' });
        this.setState({ idCli: '' });
        this.setState({ idStruct: '', reponseTrt: '' });
        this.setState({
            visible_dlg_form_req: false,
            modif_trt: false,
            visible_dlg_form_trt: false
        });
        this.setState({ modif: false });
    }

    enreg_traitement() {
        this.setState({ submitTrt: true });
        if (this.state.reponseTrt == '' || this.state.actionTrt == '') {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif_trt == false) {
                this.setState({ showindicator: true });
                let data = {
                    idTrt: 0,
                    reponseTrt: this.state.reponseTrt,
                    actionTrt: this.state.actionTrt,
                    idReq: this.state.selectedReq,
                    idUserTrt: this.CISService.get_userconnected(),
                }
                this.CISService.enreg_traitement(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                this.setState({ showindicator: true });
                let data = this.state.selectedTraitement;
                data.reponseTrt = this.state.reponseTrt;
                //data.actionTrt = this.state.actionTrt;
                data.idUserTrt = this.CISService.get_userconnected();
                this.CISService.enreg_traitement(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    enregRequete() {
        this.setState({ submit: true });
        if (this.state.contenuReq == '' || this.state.contactReq == '' || this.state.idNature == '') {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            let upload = false;
            let nomfic = this.state.photoCli;
            if (this.state.file != null && this.state.file != undefined) {
                let name = this.state.file.name;
                nomfic = 'PH_' + this.CISService.get_userconnected().idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                upload = true;
            }

            let uploadPI = false;
            let nomficPI = this.state.photoPiCli;
            if (this.state.filePI != null && this.state.filePI != undefined) {
                let name = this.state.filePI.name;
                nomficPI = 'PI_' + this.CISService.get_userconnected().idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                uploadPI = true;
            }

            let uploadDossier = false;
            let nomficDossier = this.state.dossierCli;
            if (this.state.fileDossier != null && this.state.fileDossier != undefined) {
                let name = this.state.fileDossier.name;
                nomficDossier = 'DO_' + this.CISService.get_userconnected().idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                uploadDossier = true;
            }

            let dataCli = this.state.selectedClient == null ? {
                idCli: 0,
                nomCli: this.state.nomCli,
                prenomCli: this.state.prenomCli,
                telCli: this.state.telCli,
                emailCli: this.state.emailCli,
                adrCli: this.state.adrCli,
                dateNaissCli: this.state.dateNaissCli,
                lieuNaissCli: this.state.lieuNaissCli,
                sexeCli: this.state.sexeCli,
                photoCli: nomfic,
                numPiCli: this.state.numPiCli,
                typeCli: this.state.typeCli,
                photoPiCli: nomficPI,
                dossierCli: nomficDossier,
                idStruct: this.CISService.get_userconnected().matrPers.idStruct,
                idCodifTypePiece: { idCodif: this.state.idCodifTypePiece },
                idCodifLang: { idCodif: this.state.idCodifLang },
                idCodifCategorie: { idCodif: this.state.idCodifCategorie },
                idQuartier: { idQuartier: this.state.idQuartier },
                abonnementsCli: this.state.liste_selected_offre.length > 0 ? this.state.liste_selected_offre.map(x => { return { idOffre: x } }) : []
            } : this.state.selectedClient;

            if (this.state.modif == false) {
                let data = {
                    idReq: 0,
                    contenuReq: this.state.contenuReq,
                    contactReq: this.state.contactReq,
                    lieuReq: this.state.lieuReq,
                    statutReq: this.state.statutReq,
                    etatReq: this.state.etatReq,
                    canalReq: this.state.canalReq,
                    idNature: { idNature: this.state.idNature },
                    idCli: dataCli,
                    idStruct: this.CISService.get_userconnected().matrPers.idStruct,
                    idUserPost: this.CISService.get_userconnected(),
                }
                this.CISService.enreg_requete(data).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        if (reponse.code == 200) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                            if (uploadPI === true) {
                                this.fileUpload(this.state.filePI, nomficPI);
                            }
                            if (uploadDossier === true) {
                                this.fileUpload(this.state.fileDossier, nomficDossier);
                            }
                        }
                        this.resultat(reponse.code, reponse.contenu);
                    }
                });
            } else {
                let data = this.state.selectedReq;
                data.contenuReq = this.state.contenuReq;
                data.contactReq = this.state.contactReq;
                data.lieuReq = this.state.lieuReq;
                data.statutReq = this.state.statutReq;
                data.canalReq = this.state.canalReq;
                data.idNature = { idNature: this.state.idNature };
                this.CISService.enreg_requete(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    enreg_client() {
        this.setState({ submitInfoCli: true });
        if (this.state.nomCli == '' || this.state.prenomCli == '' || this.state.telCli == '' ||
            this.state.lieuNaissCli == '' || this.state.numPiCli == '' || this.state.idQuartier == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let upload = false;
            let nomfic = this.state.photoCli;
            if (this.state.file != null && this.state.file != undefined) {
                let name = this.state.file.name;
                nomfic = 'PH_' + this.CISService.get_userconnected().idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                upload = true;
            }

            let uploadPI = false;
            let nomficPI = this.state.photoPiCli;
            if (this.state.filePI != null && this.state.filePI != undefined) {
                let name = this.state.filePI.name;
                nomficPI = 'PI_' + this.CISService.get_userconnected().idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                uploadPI = true;
            }

            let uploadDossier = false;
            let nomficDossier = this.state.dossierCli;
            if (this.state.fileDossier != null && this.state.fileDossier != undefined) {
                let name = this.state.fileDossier.name;
                nomficDossier = 'DO_' + this.CISService.get_userconnected().idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                uploadDossier = true;
            }

            if (this.state.modif_cli == false) {
                this.setState({ showindicator: true });
                let data = {
                    idCli: 0,
                    nomCli: this.state.nomCli,
                    prenomCli: this.state.prenomCli,
                    telCli: this.state.telCli,
                    emailCli: this.state.emailCli,
                    adrCli: this.state.adrCli,
                    dateNaissCli: this.state.dateNaissCli,
                    lieuNaissCli: this.state.lieuNaissCli,
                    sexeCli: this.state.sexeCli,
                    photoCli: nomfic,
                    numPiCli: this.state.numPiCli,
                    typeCli: this.state.typeCli,
                    photoPiCli: nomficPI,
                    dossierCli: nomficDossier,
                    idStruct: this.CISService.get_userconnected().matrPers.idStruct,
                    idCodifTypePiece: { idCodif: this.state.idCodifTypePiece },
                    idCodifLang: { idCodif: this.state.idCodifLang },
                    idCodifCategorie: { idCodif: this.state.idCodifCategorie },
                    idQuartier: { idQuartier: this.state.idQuartier },
                    abonnementsCli: this.state.liste_selected_offre.length > 0 ? this.state.liste_selected_offre.map(x => { return { idOffre: x } }) : []
                }
                this.CISService.enreg_client(data).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        if (reponse.code == 200) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                            if (uploadPI === true) {
                                this.fileUpload(this.state.filePI, nomficPI);
                            }
                            if (uploadDossier === true) {
                                this.fileUpload(this.state.fileDossier, nomficDossier);
                            }
                        }
                        if (reponse.code == '200') {
                            this.toast.show({ severity: 'success', summary: 'Success', detail: reponse.contenu });
                        } else {
                            this.toast.show({ severity: 'error', summary: 'Error', detail: reponse.contenu });
                        }
                        this.setState({ showindicator: false, visible_dlg_form: false });
                        this.setState({ submitInfoCli: false });
                    }
                });
            } else {
                this.setState({ showindicator: true });
                let data = this.state.selectedClient;
                data.nomCli = this.state.nomCli;
                data.prenomCli = this.state.prenomCli;
                data.telCli = this.state.telCli;
                data.emailCli = this.state.emailCli;
                data.adrCli = this.state.adrCli;
                data.dateNaissCli = this.state.dateNaissCli;
                data.lieuNaissCli = this.state.lieuNaissCli;
                data.sexeCli = this.state.sexeCli;
                data.photoCli = nomfic;
                data.dossierCli = nomficDossier;
                data.numPiCli = this.state.numPiCli;
                data.photoPiCli = nomficPI;
                data.typeCli = this.state.typeCli;
                data.latitudeClient = this.state.latitudeClient;
                data.precisionClient = this.state.precisionClient;
                data.idCodifTypePiece = { idCodif: this.state.idCodifTypePiece };
                data.idCodifCategorie = { idCodif: this.state.idCodifCategorie };
                data.idCodifLang = { idCodif: this.state.idCodifLang };
                data.idQuartier = { idQuartier: this.state.idQuartier };
                this.CISService.enreg_client(data).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        if (reponse.code == 200) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                            if (uploadPI === true) {
                                this.fileUpload(this.state.filePI, nomficPI);
                            }
                            if (uploadDossier === true) {
                                this.fileUpload(this.state.fileDossier, nomficDossier);
                            }
                        }
                        if (reponse.code == '200') {
                            this.toast.show({ severity: 'success', summary: 'Success', detail: reponse.contenu });
                        } else {
                            this.toast.show({ severity: 'error', summary: 'Error', detail: reponse.contenu });
                        }
                        this.setState({ showindicator: false, visible_dlg_form: false });
                        this.setState({ submitInfoCli: false });
                    }
                });
            }
        }
    }

    getHistorique_traitement(rowData) {
        this.setState({ showindicator: true });
        this.CISService.get_liste_traitements_req(rowData.idReq).then(data => this.setState({ liste_traitement: data, showindicator: false }));
    }

    actionTemplate(rowData) {
        let items = [{
            label: 'Historique',
            icon: 'pi pi-eye',
            command: (e) => {
                this.setState({ selectedReq: rowData, visible_dlg_historique_trt: true });
                this.getHistorique_traitement(rowData);
            }
        }
        ];
        if (rowData.statutReq !== "FERME") {
            items.push(
                {
                    label: 'Modifier',
                    icon: 'pi pi-pencil',
                    command: (e) => {
                        this.setState({ selectedReq: rowData });
                        this.editRequete(rowData);
                    }
                },
                {
                    label: 'Traiter',
                    icon: 'pi pi-check',
                    command: (e) => {
                        this.setState({ selectedReq: rowData, visible_dlg_form_trt: true });
                    }
                },
                {
                    label: 'Cloturer',
                    icon: 'pi pi-thumbs-up',
                    command: (e) => {
                        this.setState({ selectedReq: rowData, reponseTrt: 'Cloture de la requête', actionTrt: 'Cloture', modif_trt: false }, () => {
                            this.enreg_traitement();
                        });

                    }
                },
                {
                    label: 'Relancer',
                    icon: 'pi pi-bell',
                    command: (e) => {
                        this.setState({ selectedReq: rowData, reponseTrt: 'Relance de la requête', actionTrt: 'Relance', modif_trt: false }, () => {
                            this.enreg_traitement();
                        });

                    }
                },
            )
        }
        return <SplitButton label={rowData.idReq} icon="pi pi-eye"
            onClick={(e) => {
                this.setState({ selectedReq: rowData, visible_dlg_historique_trt: true });
                this.getHistorique_traitement(rowData);
            }} model={items} style={{ with: 200 }} />
    }


    deleteRequete() {
        if (this.state.canalReq == null || this.state.canalReq == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.CISService.delete_requete(this.state.canalReq).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_deleteRequete(data) {
        if (data != null && data != undefined) {
            this.setState({ canalReq: data });
            this.setState({ msg: data.canalReq });
            this.setState({ visible: true });
        }
    }

    conf_delete_abonnement(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedAbonnement: data });
            this.setState({ msg: data.idOffre.libelleOffre });
            this.setState({ visible_del_abonnement: true });
        }
    }

    /* Affichage de la boite de dialogue pour l'ajout Codification */
    toogleDlg() {
        this.annuleRequete();
        window.location = "#/requete/new/0";
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedReq == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_deleteRequete(this.state.selectedReq)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    /* Exportation du tableau contenant la liste des requetes en fichier CSV */
    exportCSV = () => {
        //ref={(el) => { this.dt = el; }}
        this.dt.exportCSV("Liste_requete");
    }

    openFileUpload() {
        document.getElementById("hiddenFile").click();
    }

    get_type_operation() {
        let mode = 'liste';
        if (this.props.match.params.typeOp != null && this.props.match.params.typeOp != undefined) {
            mode = this.props.match.params.typeOp;
        }
        return mode;
    }

    resultat_abn(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.getAbonnemts();
            this.annule_abonnement();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
        this.setState({ submit: false });
    }


    annule_abonnement() {
        this.setState({ numAbn: '' });
        this.setState({ codeAbn: '' });
        this.setState({ motifResilAbn: '' });
        this.setState({ idOffre: null });
        this.setState({ modif_abn: false });
        this.setState({
            visible_dlg_abn: false,
            visible_dlg_toogle_statut: false
        });
    }

    annule_client() {
        this.setState({ nomCli: '' });
        this.setState({ prenomCli: '' });
        this.setState({ telCli: '' });
        this.setState({ emailCli: '' });
        this.setState({ adrCli: '' });
        this.setState({ dateNaissCli: '' });
        this.setState({ lieuNaissCli: '' });
        this.setState({ sexeCli: '' });
        this.setState({ photoCli: '' });
        this.setState({ numPiCli: '' });
        this.setState({ photoPiCli: '' });
        this.setState({ dateEnregCli: '' });
        this.setState({ typeCli: '' });
        this.setState({ idQuartier: null });
        this.setState({ idStruct: null });
        this.setState({ idCodifTypePiece: null });
        this.setState({ idCodifLang: null });
        this.setState({ idCodifCategorie: null });
        this.setState({ modif: false });
        this.setState({ urlfile: null });
        this.setState({ urlfilePI: null });
        this.setState({ urlfileDossier: null });
        this.setState({ file: null });
        this.setState({ filePI: null, liste_selected_offre: [] });
        this.setState({ fileDossier: null });
        this.setState({ visible_dlg_form: false });
    }

    edit_abonnement(data) {
        if (data != null && data != undefined) {
            this.setState({ numAbn: data.numAbn });
            this.setState({ codeAbn: data.codeAbn });
            this.setState({ statutAbn: data.statutAbn });
            this.setState({ descAbn: data.descAbn });
            this.setState({ motifResilAbn: data.motifResilAbn });
            this.setState({ idOffre: data.idOffre.idOffre });
            this.setState({ modif_abn: true });
            this.setState({ selectedAbonnement: data });
            this.setState({ visible_dlg_abn: true });
        }
    }

    /* Colonne Action du tableau contenant la liste des communes */
    actionTemplateAbn(rowData, column) {
        let classe = 'p-button-info';
        const items = [
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_abonnement(rowData);
                }
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-trash',
                command: (e) => {
                    this.conf_delete_abonnement(rowData);
                }
            },
        ]
        if (rowData.statutAbn == 'ACTIF' && this.CISService.get_profil() == 'Administrateur') {
            items.push(
                {
                    label: 'Suspendre',
                    icon: 'pi pi-ban',
                    command: (e) => {
                        this.setState({ selectedAbonnement: rowData, statutAbn: 'SUSPENDU', visible_dlg_toogle_statut: true });
                    }
                },
                {
                    label: 'Résilier',
                    icon: 'pi pi-times',
                    command: (e) => {
                        this.setState({ selectedAbonnement: rowData, statutAbn: 'RESILIE', visible_dlg_toogle_statut: true });
                    }
                },
            )
        } else {
            items.push(
                {
                    label: 'Activer',
                    icon: 'pi pi-check',
                    command: (e) => {
                        this.setState({ selectedAbonnement: rowData });
                        this.setState({ showindicator: true });
                        rowData.statutAbn = "ACTIF";
                        this.CISService.enreg_abonnement(rowData).then(data => this.resultat_abn(data.code, data.contenu));
                    }
                },
            )
        }
        if (rowData.statutAbn == 'ACTIF') {
            classe = 'p-button-success';
        } else if (rowData.statutAbn == 'SUSPENDU') {
            classe = 'p-button-warning';
        } else {
            classe = 'p-button-danger';
        }

        return <SplitButton label="Modifier" icon="pi pi-pencil" className={classe} onClick={(e) => this.edit_abonnement(rowData)} model={items}></SplitButton>
    }

    /* Uploader sur le serveur le logo */
    fileUpload(file, namefile) {
        this.CISService.upload_file(file, namefile).then(data => {
            if (data != null && data != undefined && data.code == '200') {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement du fichier sur le serveur effectué avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec upload du fichier sur le serveur' });
            }
        });
    }



    render() {

        const template = (options) => {
            const toggleIcon = options.collapsed ? 'pi pi-filter-fill' : 'pi pi-chevron-up';
            const className = `${options.className} justify-content-start`;
            const titleClassName = `${options.titleClassName} pl-1`;

            return (
                <a onClick={options.onTogglerClick} style={{ cursor: 'pointer' }}>
                    <div className={className}>
                        <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                            <span className={toggleIcon}></span>
                            <Ripple />
                        </button>
                        <span className={titleClassName}>
                            Filtre avancé
                        </span>

                    </div>
                </a>
            )
        }

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.deleteRequete();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                {/* Boite de dialogue de modification d'une requete */}
                <Dialog visible={this.state.visible_dlg_form_req} style={{ width: '450px' }} header="Traitement" modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form_req: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_form_req: false })} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enregRequete()} style={{ width: 120 }} />
                    </>}>

                    <div className="p-fluid">
                        <div className="field">
                            <label htmlFor="reponseTrt">Commentaire * </label>
                            <InputTextarea id="reponseTrt" rows={3} value={this.state.contenuReq} onChange={(e) => this.setState({ contenuReq: e.target.value })} />
                        </div>
                        <div className="field">
                            <label htmlFor="actionTrt">Motif * </label>
                            <Dropdown options={this.state.liste_motif} value={this.state.idMotif} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idMotif: e.value }, () => {
                                this.get_liste_nature(this.state.idMotif);
                            })} autoWidth={false} style={{ width: '100%' }} required className={classNames({ 'p-invalid': this.state.submit && !this.state.idMotif })} />
                            {this.state.submit && !this.state.idMotif && <small className="p-invalid" style={{ color: 'red' }}>Le motif est obligatoire.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="actionTrt">Nature * </label>
                            <Dropdown options={this.state.liste_nature} value={this.state.idNature} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idNature: e.value })} autoWidth={false} style={{ width: '100%' }} required className={classNames({ 'p-invalid': this.state.submit && !this.state.idNature })} />
                            {this.state.submit && !this.state.idMotif && <small className="p-invalid" style={{ color: 'red' }}>La nature est obligatoire.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="telCli">Etat *</label>
                            <Dropdown options={[{ value: 'FONDE', label: 'FONDE' }, { value: 'NON FONDE', label: 'NON FONDE' }]} value={this.state.etatReq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ etatReq: e.value })} autoWidth={false} style={{ width: '100%' }} required className={classNames({ 'p-invalid': this.state.submit && !this.state.etatReq })} />
                            {this.state.submit && !this.state.etatReq && <small className="p-invalid" style={{ color: 'red' }}>L'état est obligatoire. </small>}
                        </div>
                    </div>
                </Dialog>
                {/* Boite de dialogue contenant l'historique  */}
                <Dialog visible={this.state.visible_dlg_detail} style={{ width: '70%' }} header='Souscription' modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_detail: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_detail: false })} />
                    </>}>

                    <Toolbar className="mb-4" left={(
                        <React.Fragment>
                            <div className="my-2">
                                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={e => { this.setState({ visible_dlg_abn: true, modif_abn: false }) }} style={{ width: 115 }} />
                                <Button label="Supprimer" disabled={this.state.selectedAbonnement == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_abonnement(this.state.selectedAbonnement)} style={{ width: 115 }} />
                            </div>
                        </React.Fragment>
                    )
                    } right={
                        <React.Fragment>
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportAbnCSV} />}
                        </React.Fragment>
                    }></Toolbar>

                    <DataTable value={this.state.liste_abonnement} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={10}
                        alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedAbonnement: e.data })} onRowSelect={e => this.setState({ selectedAbonnement: e.data })}
                        responsive={true} selection={this.state.selectedAbonnement} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                        <Column header="Action" body={this.actionTemplateAbn.bind(this)} style={{ width: '8%' }} />
                        <Column field="dateAbn" header='Date' style={{ width: '10%' }} />
                        <Column field="numAbn" header='Numéro' style={{ width: '10%' }} />
                        <Column field="statutAbn" header="Statut" style={{ width: '10%' }} />
                        <Column field="idOffre.libelleOffre" header='Offre' style={{ width: '15%' }} />
                        <Column field="descAbn" header='Description' />
                    </DataTable>

                </Dialog>

                {/* Boite de dialogue d'ajout et de modification des Communes */}
                <Dialog visible={this.state.visible_dlg_toogle_statut} style={{ width: '450px' }} header={this.state.statutAbn == 'SUSPENDU' ? "Suspension souscription" : "Résiliation souscription"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_toogle_statut: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_toogle_statut: false, motifResilAbn: '' })} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => {
                            this.setState({ showindicator: true });
                            let data = this.state.selectedAbonnement;
                            data.statutAbn = this.state.statutAbn;
                            data.motifResilAbn = this.state.motifResilAbn;
                            this.CISService.enreg_abonnement(data).then(data => this.resultat_abn(data.code, data.contenu));
                        }} style={{ width: 120 }} />
                    </>}>
                    <div className="p-fluid">
                        <div className="field">
                            <label htmlFor="motifResilAbn">Motif *</label>
                            <Dropdown editable={true} options={[
                                { label: 'DEFAUT DE PAIEMENT', value: 'DEFAUT DE PAIEMENT' },
                                { label: 'DEMENAGEMENT', value: 'DEMENAGEMENT' },
                                { label: 'AUTRE', value: 'AUTRE' },
                            ]} id="statutAbn" value={this.state.motifResilAbn} onChange={(e) => this.setState({ motifResilAbn: e.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.statutAbn })} />
                        </div>
                    </div>
                </Dialog>

                {/* Boite de dialogue contenant l'historique des traitements  */}
                <Dialog visible={this.state.visible_dlg_historique_trt} style={{ width: '70%' }} header='Historique traitement' modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_historique_trt: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_historique_trt: false })} />
                    </>}>
                    <DataTable value={this.state.liste_traitement} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={5}
                        alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedTraitement: e.data })} onRowSelect={e => this.setState({ selectedTraitement: e.data })}
                        responsive={true} selection={this.state.selectedTraitement} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                        <Column field="dateTrt" header="Date" style={{ width: '15%' }} />
                        <Column field="actionTrt" header="Action" style={{ width: '15%' }} />
                        <Column field="reponseTrt" header="Commentaire" />
                        <Column field="idUserTrt.loginUser" header="Utilisateur" sortable={true} style={{ width: '30%' }} />
                    </DataTable>
                </Dialog>

                {/* Boite de dialogue d'ajout et de modification des traitements */}
                <Dialog visible={this.state.visible_dlg_form_trt} style={{ width: '450px' }} header="Traitement" modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form_trt: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_form_trt: false })} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_traitement()} style={{ width: 120 }} />
                    </>}>

                    <div className="p-fluid">
                        <div className="field">
                            <label htmlFor="actionTrt">Action * </label>
                            <Dropdown options={[
                                { label: 'Prise en charge', value: 'Prise en charge' },
                                { label: 'Relance', value: 'Relance' },
                                { label: 'Cloture', value: 'Cloture' },
                                { label: 'Transfert', value: 'Transfert' },
                            ]} id="actionTrt" value={this.state.actionTrt} onChange={(e) => this.setState({ actionTrt: e.value })} required className={classNames({ 'p-invalid': this.state.submitTrt && !this.state.actionTrt })} />
                            {this.state.submitTrt && !this.state.actionTrt && <small className="p-invalid" style={{ color: 'red' }}>L'action est obligatoire.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="reponseTrt">Commentaire * </label>
                            <InputTextarea id="reponseTrt" rows={4} value={this.state.reponseTrt} onChange={(e) => this.setState({ reponseTrt: e.target.value })} />
                        </div>
                        {/* <div className="field">
                            <label htmlFor="libelleCommune">Cloturer ? </label>
                            <Checkbox inputId="binary" checked={this.state.checkCloture} onChange={(e) => this.setState({ checkCloture: e.checked })} />
                        </div> */}
                    </div>
                </Dialog>


                <div className="p-grid">
                    <div className="p-col-12">

                        {this.get_type_operation() == 'liste' ?
                            <div className="card p-fluid">
                                <Toast ref={(el) => this.toast = el} />
                                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                                <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                                <Panel headerTemplate={template} collapsed={true} toggleable style={{ marginBottom: 10 }}>
                                    <div className="grid">
                                        <div className="col-12">
                                            <div className="grid" >

                                                <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                                    <span className="p-float-label">
                                                        <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => { this.get_listeRequete() })} dateFormat="dd/mm/yy" yearNavigator yearRange="2021:2070" />
                                                        <label htmlFor="inputtext">Date début</label>
                                                    </span>
                                                </div>
                                                <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                                    <span className="p-float-label">
                                                        <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => { this.get_listeRequete() })} dateFormat="dd/mm/yy" yearNavigator yearRange="2021:2070" />
                                                        <label htmlFor="inputtext">Date fin</label>
                                                    </span>
                                                </div>
                                                <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                                    <span className="p-float-label">
                                                        <Dropdown options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_motif)}
                                                            value={this.state.ridMotif} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridMotif: e.value }, () => {
                                                                this.get_listeRequete();
                                                                this.get_liste_nature(this.state.ridMotif);
                                                            })} autoWidth={false} style={{ width: '100%' }} />
                                                        <label htmlFor="inputtext">Motif</label>
                                                    </span>
                                                </div>
                                                <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                                    <span className="p-float-label">
                                                        <Dropdown options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_nature)}
                                                            value={this.state.ridNature} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridNature: e.value }, () => {
                                                                this.get_listeRequete();
                                                            })} autoWidth={false} style={{ width: '100%' }} />
                                                        <label htmlFor="inputtext">Nature</label>
                                                    </span>
                                                </div>
                                                <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                                    <span className="p-float-label">
                                                        <Dropdown options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_categorie)}
                                                            value={this.state.rCategorie} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rCategorie: e.value }, () => {
                                                                this.get_listeRequete();
                                                            })} autoWidth={false} style={{ width: '100%' }} />
                                                        <label htmlFor="inputtext">Catégorie client</label>
                                                    </span>
                                                </div>

                                                <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                                    <span className="p-float-label">
                                                        <Dropdown options={[{ value: 'Tous', label: 'Tous' }, { value: 'FERME', label: 'FERME' }, { value: 'OUVERT', label: 'OUVERT' }]}
                                                            value={this.state.rstatutReq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rstatutReq: e.value }, () => {
                                                                this.get_listeRequete();
                                                            })} autoWidth={false} style={{ width: '100%' }} />
                                                        <label htmlFor="inputtext">Statut </label>
                                                    </span>
                                                </div>

                                                <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                                    <span className="p-float-label">
                                                        <Dropdown options={[{ value: 'Tous', label: 'Tous' }, { value: 'FONDE', label: 'FONDE' }, { value: 'NON FONDE', label: 'NON FONDE' }]}
                                                            value={this.state.retatReq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ retatReq: e.value }, () => {
                                                                this.get_listeRequete();
                                                            })} autoWidth={false} style={{ width: '100%' }} />
                                                        <label htmlFor="inputtext">Etat </label>
                                                    </span>
                                                </div>

                                                <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                                                        <SplitButton label="Rechercher" icon="pi pi-search" onClick={(e) => this.get_listeRequete()} model={this.state.liste_item_export} />
                                                    }
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </Panel>

                                <DataTable value={this.state.liste_requete} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={10}
                                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedReq: e.data })} onRowSelect={e => this.setState({ selectedReq: e.data })}
                                    responsive={true} selection={this.state.selectedReq} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                                    <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '12%' }} />
                                    <Column field="dateReq" filter={true} filterMatchMode='contains' header="Date" sortable={true} style={{ width: '8%' }} />
                                    <Column field="statutReq" filter={true} filterMatchMode='contains' body={(rowData, column) => {
                                        return <div>
                                            <Badge value={rowData.statutReq == 'FERME' ? "1" : "0"} severity={rowData.statutReq == 'FERME' ? "success" : "warning"} />
                                            <span><label style={{ marginLeft: 8 }}>{rowData.statutReq}</label></span>
                                        </div>
                                    }} header="Statut" sortable={true} style={{ width: '11%' }} />
                                    <Column field="idNature.libelleNature" filter={true} filterMatchMode='contains' header="Nature" body={(rowData, column) => {
                                        if (rowData.idNature.idMotif !== undefined) {
                                            return <div>
                                                <label style={{ fontWeight: 'bold' }}>{rowData.idNature.idMotif.descriptionMotif}</label><br />
                                                <label>{rowData.idNature.libelleNature}</label>
                                            </div>
                                        }
                                    }} sortable={true} />
                                    <Column field="idCli.nomCli" filter={true} filterMatchMode='contains' header="Nom" sortable={true} style={{ width: '10%' }} />
                                    <Column field="idCli.prenomCli" filter={true} filterMatchMode='contains' header="Prénom" sortable={true} style={{ width: '15%' }} />
                                    <Column field="contactReq" filter={true} filterMatchMode='contains' header="Contact" style={{ width: '9%' }} />
                                    <Column field="etatReq" filter={true} filterMatchMode='contains' header="Etat" sortable={true} style={{ width: '10%' }} />
                                </DataTable>

                            </div> :

                            <div className="card p-fluid" style={{ height: 900 }}>
                                <Toast ref={(el) => this.toast = el} />
                                <div className="grid ">
                                    <div className="col-12 md:col-6" >
                                        <TabView style={{ marginTop: -15 }}>
                                            <TabPanel header="Recherche et identification">
                                                <div className="p-fluid">
                                                    <div className="field grid" style={{ marginTop: 6 }}>
                                                        <div className="col-12 md:col-6">
                                                            <span className="p-float-label">
                                                                <InputMask id="contactReq" mask="999999999" value={this.state.contactReq} onChange={(e) => this.setState({ contactReq: e.target.value, telCli: e.target.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.contactReq })} />
                                                                <label htmlFor="contactReq">Initiateur *</label>
                                                                {this.state.submit && !this.state.contactReq && <small className="p-invalid" style={{ color: 'red' }}>L'initiateur est obligatoire. </small>}
                                                            </span>
                                                        </div>
                                                        <div className="col-12 md:col-6">
                                                            <div className="p-inputgroup">
                                                                <span className="p-float-label">
                                                                    <InputMask id="telCli" mask="999999999" value={this.state.telCli} onChange={(e) => this.setState({ telCli: e.target.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.telCli })} />
                                                                    <label htmlFor="telCli">Concerné *</label>
                                                                </span>
                                                                <Button icon="pi pi-search" loading={this.state.showindicator} onClick={(e) => this.search()} />
                                                            </div>
                                                            {this.state.submit && !this.state.telCli && <small className="p-invalid" style={{ color: 'red' }}>Le concerné est obligatoire. </small>}
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="field grid">
                                                        <div className="col-12 md:col-3">
                                                            <div >
                                                                <a onClick={() => this.openFileUpload()} style={{ cursor: 'pointer' }}>
                                                                    <div style={{ height: 110, width: 92, borderRadius: 10, marginTop: 4 }} >
                                                                        <center>
                                                                            {this.state.urlfile == null ?
                                                                                <div style={{ width: '92px', height: '110px', borderRadius: 10, backgroundColor: '#f7f7f7', display: 'flex', justifyContent: 'center', padding: 30, border: '1px solid #ccc' }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>PHOTO</label></div> :
                                                                                <img src={this.state.urlfile} style={{ width: '92px', height: '110px', borderRadius: 10, backgroundColor: '#f7f7f7', border: '1px solid #003366' }} />}
                                                                        </center>
                                                                    </div>
                                                                </a>
                                                                <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                                                                    if (e.target.files && e.target.files[0]) {
                                                                        this.setState({ file: e.target.files[0] }, () => {
                                                                            let url = URL.createObjectURL(this.state.file);
                                                                            this.setState({ urlfile: url });
                                                                        })
                                                                    }
                                                                }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 md:col-9">
                                                            <div className="field col-12" style={{ padding: 4 }}>
                                                                <span className="p-float-label">
                                                                    <InputText id="nomCli" value={this.state.nomCli} onChange={(e) => this.setState({ nomCli: e.target.value })} required style={{ color: '#A40C68', fontSize: 20, fontWeight: 'bold' }} className={classNames({ 'p-invalid': this.state.submitInfoCli && !this.state.nomCli })} />
                                                                    <label htmlFor="name3" >Nom du client *</label>
                                                                    {this.state.submitInfoCli && !this.state.nomCli && <small className="p-invalid" style={{ color: 'red' }}>Le nom est obligatoire</small>}
                                                                </span>
                                                            </div>
                                                            <div className="field col-12" style={{ padding: 4 }}>
                                                                <span className="p-float-label">
                                                                    <InputText id="prenomCli" value={this.state.prenomCli} onChange={(e) => this.setState({ prenomCli: e.target.value })} required style={{ color: '#A40C68', fontSize: 20, fontWeight: 'bold' }} className={classNames({ 'p-invalid': this.state.submitInfoCli && !this.state.prenomCli })} />
                                                                    <label htmlFor="email3" >Prénom du client *</label>
                                                                    {this.state.submitInfoCli && !this.state.prenomCli && <small className="p-invalid" style={{ color: 'red' }}>Le prénom est obligatoire. </small>}
                                                                </span>
                                                            </div>

                                                            <hr />

                                                            <SplitButton label="Mettre à jour" icon="pi pi-pencil" className="p-button-warning" style={{ width: 200 }}
                                                                onClick={(e) => {
                                                                    this.setState({ visible_dlg_form: true });
                                                                }} model={
                                                                    [
                                                                        {
                                                                            label: 'Souscriptions',
                                                                            icon: 'pi pi-box',
                                                                            command: (e) => {
                                                                                if (this.state.selectedClient == null || this.state.selectedClient == undefined) {
                                                                                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Aucun client sélectionner, veuillez rechercher un client au préalable' });
                                                                                } else {
                                                                                    this.setState({ visible_dlg_detail: true }, () => {
                                                                                        this.getAbonnemts();
                                                                                    });
                                                                                }
                                                                            }
                                                                        },
                                                                        {
                                                                            label: 'Télécharger contrat',
                                                                            icon: 'pi pi-download',
                                                                            command: (e) => {
                                                                                if (this.state.selectedClient == null || this.state.selectedClient == undefined) {
                                                                                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Aucun client sélectionner, veuillez rechercher un client au préalable' });
                                                                                } else {
                                                                                    if (this.state.selectedClient.dossierCli == null || this.state.selectedClient.dossierCli == undefined || this.state.selectedClient.dossierCli == '') {
                                                                                        this.toast.show({ severity: 'warn', summary: 'Attention', detail: "Aucune pièce d'identité n'est rattachée à ce client" });
                                                                                    } else {
                                                                                        this.CISService.download_fichier_joint(this.state.selectedClient.dossierCli);
                                                                                    }
                                                                                }
                                                                            }
                                                                        },
                                                                        {
                                                                            label: 'Télécharger PI',
                                                                            icon: 'pi pi-download',
                                                                            command: (e) => {
                                                                                if (this.state.selectedClient == null || this.state.selectedClient == undefined) {
                                                                                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Aucun client sélectionner, veuillez rechercher un client au préalable' });
                                                                                } else {
                                                                                    if (this.state.selectedClient.photoPiCli == null || this.state.selectedClient.photoPiCli == undefined || this.state.selectedClient.photoPiCli == '') {
                                                                                        this.toast.show({ severity: 'warn', summary: 'Attention', detail: "Aucune pièce d'identité n'est rattachée à ce client" });
                                                                                    } else {
                                                                                        this.CISService.download_fichier_joint(this.state.selectedClient.photoPiCli);
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    ]
                                                                } />
                                                        </div>

                                                    </div>

                                                </div>
                                            </TabPanel>
                                        </TabView>

                                    </div>
                                    <div className="col-12 md:col-6">
                                        <TabView style={{ marginTop: -15 }}>
                                            <TabPanel header="Nouvelle requête">
                                                <div className="formgrid grid" >
                                                    <div className="field col-12 md:col-12" style={{ padding: 3 }}>
                                                        <span className="p-float-label">
                                                            <InputTextarea id="descAbn" rows={3} value={this.state.contenuReq} onChange={(e) => this.setState({ contenuReq: e.target.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.contenuReq })} />
                                                            <label htmlFor="prenomCli">Description </label>
                                                            {this.state.submit && !this.state.contenuReq && <small className="p-invalid" style={{ color: 'red' }}>Le contenu de la requête est obligatoire. </small>}

                                                        </span>
                                                    </div>
                                                    <div className="field col-12 md:col-6" style={{ padding: 3 }}>
                                                        <span className="p-float-label">
                                                            <Dropdown options={this.state.liste_motif} value={this.state.idMotif} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idMotif: e.value }, () => {
                                                                this.get_liste_nature(this.state.idMotif);
                                                            })} autoWidth={false} style={{ width: '100%' }} required className={classNames({ 'p-invalid': this.state.submit && !this.state.idMotif })} />
                                                            <label htmlFor="prenomCli">Motif *</label>
                                                            {this.state.submit && !this.state.idMotif && <small className="p-invalid" style={{ color: 'red' }}>Le motif est obligatoire. </small>}

                                                        </span>
                                                    </div>
                                                    <div className="field col-12 md:col-6" style={{ padding: 3 }}>
                                                        <span className="p-float-label">
                                                            <Dropdown options={this.state.liste_nature} value={this.state.idNature} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idNature: e.value })} autoWidth={false} style={{ width: '100%' }} required className={classNames({ 'p-invalid': this.state.submit && !this.state.idNature })} />
                                                            <label htmlFor="telCli">Nature *</label>
                                                            {this.state.submit && !this.state.idNature && <small className="p-invalid" style={{ color: 'red' }}>La nature est obligatoire. </small>}

                                                        </span>
                                                    </div>
                                                    <div className="field col-12 md:col-6" style={{ padding: 3 }}>
                                                        <span className="p-float-label">
                                                            <Dropdown options={[{ value: 'FERME', label: 'FERME' }, { value: 'OUVERT', label: 'OUVERT' }]} value={this.state.statutReq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ statutReq: e.value })} autoWidth={false} style={{ width: '100%' }} required className={classNames({ 'p-invalid': this.state.submit && !this.state.statutReq })} />
                                                            <label htmlFor="telCli">Statut *</label>
                                                            {this.state.submit && !this.state.statutReq && <small className="p-invalid" style={{ color: 'red' }}>Le statut est obligatoire. </small>}

                                                        </span>
                                                    </div>
                                                    <div className="field col-12 md:col-6" style={{ padding: 3 }}>
                                                        <span className="p-float-label">
                                                            <Dropdown options={[{ value: 'FONDE', label: 'FONDE' }, { value: 'NON FONDE', label: 'NON FONDE' }]} value={this.state.etatReq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ etatReq: e.value })} autoWidth={false} style={{ width: '100%' }} required className={classNames({ 'p-invalid': this.state.submit && !this.state.etatReq })} />
                                                            <label htmlFor="telCli">Etat *</label>
                                                            {this.state.submit && !this.state.etatReq && <small className="p-invalid" style={{ color: 'red' }}>L'état est obligatoire. </small>}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="field col-12" style={{ marginTop: -8 }}>
                                                    <center>
                                                        <div className='grid' style={{ width: 350 }}>
                                                            <div className='col'>
                                                                <Button label="Annuler" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 140 }} onClick={(e) => this.annuleRequete()} />
                                                            </div>
                                                            <div className='col'>
                                                                <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enregRequete()} style={{ width: 140 }} />
                                                            </div>
                                                        </div>
                                                    </center>
                                                </div>
                                            </TabPanel>
                                        </TabView>

                                    </div>
                                </div>
                                <TabView style={{ marginTop: -65 }}>
                                    <TabPanel header="Historique requête">
                                        <DataTable value={this.state.liste_requete} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={10}
                                            alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedReq: e.data })} onRowSelect={e => this.setState({ selectedReq: e.data })}
                                            responsive={true} selection={this.state.selectedReq} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                                            <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '12%' }} />
                                            <Column field="dateReq" header="Date" sortable={true} style={{ width: '8%' }} />
                                            <Column field="statutReq" body={(rowData, column) => {
                                                return <div>
                                                    <Badge value={rowData.statutReq == 'FERME' ? "1" : "0"} severity={rowData.statutReq == 'FERME' ? "success" : "warning"} />
                                                    <span><label style={{ marginLeft: 8 }}>{rowData.statutReq}</label></span>
                                                </div>
                                            }} header="Statut" sortable={true} style={{ width: '11%' }} />
                                            <Column field="idNature.libelleNature" header="Nautre" body={(rowData, column) => {
                                                if (rowData.idNature.idMotif !== undefined) {
                                                    return <div>
                                                        <label style={{ fontWeight: 'bold' }}>{rowData.idNature.idMotif.descriptionMotif}</label><br />
                                                        <label>{rowData.idNature.libelleNature}</label>
                                                    </div>
                                                }
                                            }} sortable={true} style={{ width: '20%' }} />
                                            <Column field="contenuReq" header="Commentaire" />
                                            <Column field="contactReq" header="Contact" sortable={true} style={{ width: '10%' }} />
                                            <Column field="etatReq" header="Etat" sortable={true} style={{ width: '10%' }} />
                                        </DataTable>
                                    </TabPanel>

                                </TabView>


                                {/* Boite de dialogue d'ajout et de modification des Communes */}
                                <Dialog visible={this.state.visible_dlg_abn} style={{ width: '450px' }} header={this.state.modif_abn == true ? "Modification souscription" : "Ajout souscription"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_abn: false })}
                                    footer={<>
                                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annule_abonnement()} />
                                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_abonnement()} style={{ width: 120 }} />
                                    </>}>

                                    <div className="p-fluid">
                                        <div className="field">
                                            <label htmlFor="idOffre">Offre * </label>
                                            <Dropdown id="idOffre" options={this.state.liste_offre} value={this.state.idOffre} onChange={(e) => this.setState({ idOffre: e.value })} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.idOffre })} />
                                            {this.state.submit && !this.state.idOffre && <small className="p-invalid" style={{ color: 'red' }}>L'offre est obligatoire.</small>}

                                        </div>
                                        {this.state.modif_abn == true ?
                                            <div className="field">
                                                <label htmlFor="statutAbn">Statut * </label>
                                                <Dropdown options={[
                                                    { label: 'ACTIF', value: 'ACTIF' },
                                                    { label: 'SUSPENDU', value: 'SUSPENDU' },
                                                    { label: 'RESILIE', value: 'RESILIE' },
                                                ]} id="statutAbn" value={this.state.statutAbn} onChange={(e) => this.setState({ statutAbn: e.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.statutAbn })} />
                                                {this.state.submit && !this.state.statutAbn && <small className="p-invalid" style={{ color: 'red' }}>Le statut est obligatoire.</small>}
                                            </div>

                                            : null}
                                        {this.state.statutAbn !== 'ACTIF' && this.state.modif_abn == true ?
                                            <div className="field">
                                                <label htmlFor="motifResilAbn">Motif *</label>
                                                <Dropdown editable={true} options={[
                                                    { label: 'NEAT', value: 'NEAT' },
                                                    { label: 'DEFAUT DE PAIEMENT', value: 'DEFAUT DE PAIEMENT' },
                                                    { label: 'DEMENAGEMENT', value: 'DEMENAGEMENT' },
                                                    { label: 'AUTRE', value: 'AUTRE' },
                                                ]} id="motifResilAbn" value={this.state.motifResilAbn} onChange={(e) => this.setState({ motifResilAbn: e.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.motifResilAbn })} />
                                            </div> : null}
                                        <div className="field">
                                            <label htmlFor="libelleCommune">Numéro abonnement *</label>
                                            <InputText id="libelleCommune" value={this.state.numAbn} onChange={(e) => this.setState({ numAbn: e.target.value })} />
                                        </div>
                                        <div className="field">
                                            <label htmlFor="descAbn">Commentaire </label>
                                            <InputTextarea id="descAbn" rows={2} value={this.state.descAbn} onChange={(e) => this.setState({ descAbn: e.target.value })} />
                                        </div>
                                    </div>
                                </Dialog>


                                {/* Boite de dialogue d'ajout et de modification d'un abonné  */}
                                <Dialog visible={this.state.visible_dlg_form} style={{ width: '90%' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                                    footer={<>
                                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_form: false })} />
                                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_client()} style={{ width: 120 }} />
                                    </>}>
                                    <div className="p-fluid">
                                        <div className="grid">
                                            <div className="col-12 md:col-10">
                                                <div style={{ backgroundColor: '#f3f7fb', height: 320, padding: 20, border: '4px solid #f5f5f5' }}>

                                                    <div className="formgrid grid" >

                                                        <div className="field col-4" style={{ padding: 4 }}>
                                                            <span className="p-float-label">
                                                                <InputText id="nomCli" value={this.state.nomCli} onChange={(e) => this.setState({ nomCli: e.target.value })} required autoFocus className={classNames({ 'p-invalid': this.state.submitInfoCli && !this.state.nomCli })} />
                                                                <label htmlFor="nomCli">Nom *</label>
                                                            </span>
                                                            {this.state.submitInfoCli && !this.state.nomCli && <small className="p-invalid" style={{ color: 'red' }}>Le nom est obligatoire</small>}
                                                        </div>
                                                        <div className="field col-4" style={{ padding: 4 }}>
                                                            <span className="p-float-label">
                                                                <InputText id="prenomCli" value={this.state.prenomCli} onChange={(e) => this.setState({ prenomCli: e.target.value })} required className={classNames({ 'p-invalid': this.state.submitInfoCli && !this.state.prenomCli })} />
                                                                <label htmlFor="prenomCli">Prénom *</label>
                                                            </span>
                                                            {this.state.submitInfoCli && !this.state.prenomCli && <small className="p-invalid" style={{ color: 'red' }}>Le prénom est obligatoire. </small>}
                                                        </div>
                                                        <div className="field col-4" style={{ padding: 4 }}>
                                                            <span className="p-float-label">
                                                                <InputMask mask="999999999" id="telCli" value={this.state.telCli} onChange={(e) => this.setState({ telCli: e.target.value })} required className={classNames({ 'p-invalid': this.state.submitInfoCli && !this.state.telCli })} />
                                                                <label htmlFor="telCli">N° téléphone *</label>
                                                            </span>
                                                            {this.state.submitInfoCli && !this.state.telCli && <small className="p-invalid" style={{ color: 'red' }}>La numéro de téléphone est obligatoire.  </small>}
                                                            {this.state.checkPhone == false && <small className="p-invalid" style={{ color: 'red' }}>Ce numéro de téléphone existe déja.</small>}
                                                        </div>
                                                        <div className="field col-4" style={{ padding: 4 }}>
                                                            <span className="p-float-label">
                                                                <InputMask mask="99/99/9999" id="dateNaissCli" value={this.state.dateNaissCli} onChange={(e) => this.setState({ dateNaissCli: e.target.value })} required className={classNames({ 'p-invalid': this.state.submitInfoCli && !this.state.dateNaissCli })} />
                                                                <label htmlFor="dateNaissCli">Date naissance  *</label>
                                                            </span>
                                                            {this.state.submitInfoCli && !this.state.dateNaissCli && <small className="p-invalid" style={{ color: 'red' }}>La date de naissance est obligatoire.  </small>}
                                                        </div>
                                                        <div className="field col-4" style={{ padding: 4 }}>
                                                            <span className="p-float-label">
                                                                <InputText id="lieuNaissCli" value={this.state.lieuNaissCli} onChange={(e) => this.setState({ lieuNaissCli: e.target.value })} required className={classNames({ 'p-invalid': this.state.submitInfoCli && !this.state.lieuNaissCli })} />
                                                                <label htmlFor="lieuNaissCli">Lieu naissance *</label>
                                                            </span>
                                                            {this.state.submitInfoCli && !this.state.lieuNaissCli && <small className="p-invalid" style={{ color: 'red' }}>Le lieu de naissance est obligatoire.</small>}
                                                        </div>
                                                        <div className="field col-4" style={{ padding: 4 }}>
                                                            <span className="p-float-label">
                                                                <Dropdown options={[
                                                                    { label: 'Masculin', value: 'Masculin' },
                                                                    { label: 'Feminin', value: 'Feminin' },
                                                                ]} id="sexeCli" value={this.state.sexeCli} onChange={(e) => this.setState({ sexeCli: e.value })} required className={classNames({ 'p-invalid': this.state.submitInfoCli && !this.state.sexeCli })} />
                                                                <label htmlFor="sexeCli">Sexe *</label>
                                                            </span>
                                                            {this.state.submitInfoCli && !this.state.sexeCli && <small className="p-invalid" style={{ color: 'red' }}>Le genre est obligatoire </small>}
                                                        </div>
                                                        <div className="field col-4" style={{ padding: 4 }}>
                                                            <span className="p-float-label">
                                                                <Dropdown id="idCodifCategorie" options={this.state.liste_categorie} value={this.state.idCodifCategorie} onChange={(e) => this.setState({ idCodifCategorie: e.value })} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submitInfoCli && !this.state.idCodifCategorie })} />
                                                                <label htmlFor="idCodifCategorie">Catégorie * </label>
                                                            </span>
                                                            {this.state.submitInfoCli && !this.state.idCodifCategorie && <small className="p-invalid" style={{ color: 'red' }}>La catégorie est obligatoire.</small>}
                                                        </div>
                                                        <div className="field col-4" style={{ padding: 4 }}>
                                                            <span className="p-float-label">
                                                                <InputText id="emailCli" value={this.state.emailCli} onChange={(e) => this.setState({ emailCli: e.target.value })} />
                                                                <label htmlFor="emailCli">Email </label>
                                                            </span>
                                                        </div>
                                                        <div className="field col-4" style={{ padding: 4 }}>
                                                            <span className="p-float-label">
                                                                <Dropdown id="typeCli" options={[
                                                                    { label: 'PARTICULIER', value: 'PARTICULIER' },
                                                                    { label: 'ORGANISATION', value: 'ORGANISATION' },
                                                                ]} value={this.state.typeCli} onChange={(e) => this.setState({ typeCli: e.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submitInfoCli && !this.state.typeCli })} />
                                                                <label htmlFor="typeCli">Type client *</label>
                                                            </span>
                                                            {this.state.submitInfoCli && !this.state.typeCli && <small className="p-invalid" style={{ color: 'red' }}>Le type de client est obligatoire .</small>}
                                                        </div>
                                                        <div className="field col-4" style={{ padding: 4 }}>
                                                            <span className="p-float-label">
                                                                <Dropdown id="idCodifLang" options={this.state.liste_langue} value={this.state.idCodifLang} onChange={(e) => this.setState({ idCodifLang: e.target.value })} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submitInfoCli && !this.state.idCodifLang })} />
                                                                <label htmlFor="idCodifLang">Langue *</label>
                                                            </span>
                                                            {this.state.submitInfoCli && !this.state.idCodifLang && <small className="p-invalid" style={{ color: 'red' }}>La langue est obligatoire.</small>}
                                                        </div>
                                                        <div className="field col-4" style={{ padding: 4 }}>
                                                            <span className="p-float-label">
                                                                <Dropdown id="idQuart" options={this.state.liste_quartier} value={this.state.idQuartier} onChange={(e) => this.setState({ idQuartier: e.target.value })} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submitInfoCli && !this.state.idQuartier })} />
                                                                <label htmlFor="idQuart">Quartier *</label>
                                                            </span>
                                                            {this.state.submitInfoCli && !this.state.idQuartier && <small className="p-invalid" style={{ color: 'red' }}>Le quartier est obligatoire.</small>}
                                                        </div>
                                                        <div className="field col-4" style={{ padding: 4 }}>
                                                            <span className="p-float-label">
                                                                <InputText id="adrCli" value={this.state.adrCli} onChange={(e) => this.setState({ adrCli: e.target.value })} />
                                                                <label htmlFor="adrCli">Adresse </label>
                                                            </span>
                                                        </div>
                                                        <div className="field col-4" style={{ padding: 4 }}>
                                                            <span className="p-float-label">
                                                                <Dropdown id="idQuart" options={this.state.liste_type_piece} value={this.state.idCodifTypePiece} onChange={(e) => this.setState({ idCodifTypePiece: e.target.value })} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submitInfoCli && !this.state.idCodifTypePiece })} />
                                                                <label htmlFor="idQuart">Type pièce *</label>
                                                            </span>
                                                            {this.state.submitInfoCli && !this.state.idCodifTypePiece && <small className="p-invalid" style={{ color: 'red' }}>Le type de pièce est obligatoire.</small>}
                                                        </div>
                                                        <div className="field col-4" style={{ padding: 4 }}>
                                                            <span className="p-float-label">
                                                                <InputText id="numPiCli" value={this.state.numPiCli} onChange={(e) => this.setState({ numPiCli: e.target.value })} className={classNames({ 'p-invalid': this.state.submitInfoCli && !this.state.numPiCli })} />
                                                                <label htmlFor="numPiCli">N° pièce ID </label>
                                                            </span>
                                                            {this.state.submitInfoCli && !this.state.numPiCli && <small className="p-invalid" style={{ color: 'red' }}>Le numéro de pièce est obligatoire.</small>}
                                                        </div>

                                                        <div className="field col-4" style={{ padding: 4 }}>
                                                            <span className="p-float-label">
                                                                <MultiSelect value={this.state.liste_selected_offre} options={this.state.liste_offre} onChange={(e) => this.setState({ liste_selected_offre: e.value })} optionLabel="label" />
                                                                <label htmlFor="adrCli">Offre </label>
                                                            </span>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="col-12 md:col-2">
                                                <center>
                                                    <div className='p-fluid' >
                                                        <div className="field" style={{ marginBottom: -10 }}>
                                                            <a onClick={() => this.openFileUpload()} style={{ cursor: 'pointer' }}>
                                                                <div style={{ height: 100, width: 95, borderRadius: 10 }} >
                                                                    <center>
                                                                        {this.state.urlfile == null ?
                                                                            <div style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>PHOTO</label></div> :
                                                                            <img src={this.state.urlfile} style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb' }} />}
                                                                    </center>
                                                                </div>
                                                            </a>
                                                            <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                                                                if (e.target.files && e.target.files[0]) {
                                                                    this.setState({ file: e.target.files[0] }, () => {
                                                                        let url = URL.createObjectURL(this.state.file);
                                                                        this.setState({ urlfile: url });
                                                                    })
                                                                }
                                                            }} />
                                                        </div>

                                                        <div className="field" style={{ marginBottom: -10 }}>
                                                            <a onClick={() => this.openFileUploadPiece()} style={{ cursor: 'pointer' }}>
                                                                <div style={{ height: 100, width: 95, borderRadius: 10 }} >
                                                                    <center>
                                                                        {this.state.urlfilePI == null ?
                                                                            <div style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>PIECE</label></div> :
                                                                            <img id="footer-logo" src="assets/layout/images/id.png" alt="diamond-layout" style={{ width: '120px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb' }} />
                                                                        }
                                                                    </center>
                                                                </div>
                                                            </a>
                                                            <input type="file" id="hiddenFilePiece" style={{ visibility: 'hidden' }} onChange={(e) => {
                                                                if (e.target.files && e.target.files[0]) {
                                                                    this.setState({ filePI: e.target.files[0], urlfilePI: 'OK' })
                                                                }
                                                            }} />
                                                        </div>

                                                        <div className="field" style={{ marginBottom: -10 }}>
                                                            <a onClick={() => this.openFileUploadDossier()} style={{ cursor: 'pointer' }}>
                                                                <div style={{ height: 100, width: 95, borderRadius: 10 }} >
                                                                    <center>
                                                                        {this.state.urlfileDossier == null ?
                                                                            <div style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>DOSSIER</label></div> :
                                                                            <img id="footer-logo" src="assets/layout/images/doc.png" alt="diamond-layout" style={{ width: '120px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb' }} />
                                                                        }
                                                                    </center>
                                                                </div>
                                                            </a>
                                                            <input type="file" id="hiddenFileDossier" style={{ visibility: 'hidden' }} onChange={(e) => {
                                                                if (e.target.files && e.target.files[0]) {
                                                                    this.setState({ fileDossier: e.target.files[0], urlfileDossier: 'OK' })
                                                                }
                                                            }} />
                                                        </div>

                                                    </div>
                                                </center>
                                            </div>
                                        </div>


                                    </div>
                                </Dialog>
                            </div>
                        }

                    </div>
                </div>
            </div>
        );
    }
}