import React, { Component } from 'react';
import CISService from '../service/CISService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { SplitButton } from 'primereact/splitbutton';
import { ProgressSpinner } from 'primereact/progressspinner';

export class StatReqPeriode extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            liste_structure: [],
            liste_profil: [],
            liste_motif: [],
            liste_categorie: [],
            liste_nature: [],
            liste_item_export: [
                {
                    label: 'Exporter en CVS',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.exportCSV();
                    }
                },
                {
                    label: 'Exporter en XLS',
                    icon: 'pi pi-file-excel',
                    command: (e) => {
                        this.exportExcel();
                    }
                },
            ],
            top: 10,
            pd1: new Date(),
            pd2: new Date(),
            ridStruct: 'Tous',
            rCategorie: 'Tous',
            rtypeCli: 'Tous',
            ridNature: 'Tous',
            ridMotif: 'Tous',
            rProfil: 'Tous',
        };
        this.CISService = new CISService();
    }

    componentDidMount() {
        this.get_liste_codification();
        this.get_liste_motif();
        this.get_liste_data();
        this.get_liste_structures();
    }

    //Liste des motifs
    get_liste_motif() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_motif().then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idMotif, label: x.descriptionMotif }
                });
                this.setState({ liste_motif: liste });
            }
        });
    }

    get_liste_data() {
        this.setState({ showindicator: true });
        try {
            let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
            let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
            this.CISService.get_liste_stat_req_periode(spd1, spd2, this.state.ridStruct, this.state.rCategorie, this.state.rProfil, this.state.ridMotif, this.state.ridNature).then(data => this.setState({ liste_data: data, showindicator: false }));
        } catch (err) {
            console.log(err);
        }
    }

    //Liste des motifs
    get_liste_motif() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_motif().then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idMotif, label: x.descriptionMotif }
                });
                this.setState({ liste_motif: liste });
            }
        });
    }

    get_liste_nature(idMotif) {
        this.CISService.get_liste_nature(idMotif).then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idNature, label: x.descriptionNature }
                });
                this.setState({ liste_nature: liste });
            }
        });
    }

    //Liste des Codifications 
    get_liste_codification() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_codification('CATEGORIE_CLIENT').then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.descriptionCodif }
                });
                this.setState({ liste_categorie: liste });
            }
        });

        this.CISService.get_liste_codification('PLATEAUX').then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.descriptionCodif }
                });
                this.setState({ liste_profil: liste });
            }
        });

    }

    //Liste des agences 
    get_liste_structures() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_structure().then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idStruct, label: x.libelleStruct }
                });
                this.setState({ liste_structure: liste });
            }
        });
    }

    /* Exportation du tableau contenant la liste des requetes en fichier CSV */
    exportCSV = () => {
        //ref={(el) => { this.dt = el; }}
        this.dt.exportCSV("STAT_REQUETE_");
    }

    /* Exportation du tableau  en fichier XLSX */
    exportExcel = () => {
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return { 'DATE': x.periode, 'NB': x.nb, 'PRC': x.prc,'FONDE': x.nbFonde,'NON FONDE': x.nbNfonde,'OUVERT': x.nbOuvert,'FERME': x.nbFerme,'DELAI': x.nbDelai ,'TAUX DELAI': x.prcDelai,'HORS DELAI': x.nbHdelai   }
        }) : [];
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'STAT_REQUETE_');
        });
    }

    /* Enregistrer le fichier sur le client */
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });
                module.default.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    render() {
        const template = (options) => {
            const toggleIcon = options.collapsed ? 'pi pi-filter-fill' : 'pi pi-chevron-up';
            const className = `${options.className} justify-content-start`;
            const titleClassName = `${options.titleClassName} pl-1`;
            return (
                <a onClick={options.onTogglerClick} style={{ cursor: 'pointer' }}>
                    <div className={className}>
                        <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                            <span className={toggleIcon}></span>
                            <Ripple />
                        </button>
                        <span className={titleClassName}>
                            Filtre avancé
                        </span>

                    </div>
                </a>
            )
        }
        return (
            <div className="card p-fluid" style={{ height: 900 }}>
                <Panel headerTemplate={template} collapsed={true} toggleable style={{ marginBottom: 10 }}>
                    <div className="grid">
                        <div className="col-12">
                            <div className="grid" >

                                <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                    <span className="p-float-label">
                                        <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => { this.get_liste_data() })} dateFormat="dd/mm/yy" yearNavigator yearRange="2021:2070" />
                                        <label htmlFor="inputtext">Date début</label>
                                    </span>
                                </div>
                                <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                    <span className="p-float-label">
                                        <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => { this.get_liste_data() })} dateFormat="dd/mm/yy" yearNavigator yearRange="2021:2070" />
                                        <label htmlFor="inputtext">Date fin</label>
                                    </span>
                                </div>
                                <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                    <span className="p-float-label">
                                        <Dropdown options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_categorie)}
                                            value={this.state.rCategorie} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rCategorie: e.value }, () => {
                                                this.get_liste_data();
                                            })} autoWidth={false} style={{ width: '100%' }} />
                                        <label htmlFor="inputtext">Catégorie client</label>
                                    </span>
                                </div>
                                <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                    <span className="p-float-label">
                                        <Dropdown options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_structure)}
                                            value={this.state.ridStruct} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridStruct: e.value }, () => {
                                                this.get_liste_data();
                                            })} autoWidth={false} style={{ width: '100%' }} />
                                        <label htmlFor="inputtext">Structure</label>
                                    </span>
                                </div>
                                <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                    <span className="p-float-label">
                                        <Dropdown options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_profil)}
                                            value={this.state.rProfil} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rProfil: e.value }, () => {
                                                this.get_liste_data();
                                            })} autoWidth={false} style={{ width: '100%' }} />
                                        <label htmlFor="inputtext">Profil</label>
                                    </span>
                                </div>
                                <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                    <span className="p-float-label">
                                        <Dropdown options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_motif)}
                                            value={this.state.ridMotif} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridMotif: e.value }, () => {
                                                this.get_liste_data();
                                                this.get_liste_nature(this.state.ridMotif);
                                            })} autoWidth={false} style={{ width: '100%' }} />
                                        <label htmlFor="inputtext">Motif</label>
                                    </span>
                                </div>
                                <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                    <span className="p-float-label">
                                        <Dropdown options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_nature)}
                                            value={this.state.ridNature} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridNature: e.value }, () => {
                                                this.get_liste_data();
                                            })} autoWidth={false} style={{ width: '100%' }} />
                                        <label htmlFor="inputtext">Nature</label>
                                    </span>
                                </div>
                                <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                                        <SplitButton style={{ width: 200 }} label="Rechercher" icon="pi pi-search" onClick={(e) => this.get_liste_data()} model={this.state.liste_item_export} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Panel>

                <DataTable value={this.state.liste_data} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={20} ref={(el) => { this.dt = el; }}
                    alwaysShowPaginator={false} responsive={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                    <Column header="No" body={(rowData, props) => {
                        return <span>{props.rowIndex + 1}</span>
                    }} style={{ width: '5%' }} />
                    <Column field="periode" header="Date" sortable={true} />
                    <Column field="nb" header="Nb" style={{ width: '8%' }} sortable />
                    <Column field="prc" header="Taux" style={{ width: '10%' }} sortable />
                    <Column field="nbFonde" header="Fondé" style={{ width: '8%' }} sortable />
                    <Column field="nbNfonde" header="Non fondé" style={{ width: '12%' }} sortable />
                    <Column field="nbOuvert" header="Ouvert" style={{ width: '8%' }} sortable />
                    <Column field="nbFerme" header="Fermé" style={{ width: '8%' }} sortable />
                    <Column field="nbDelai" header="Délai" style={{ width: '8%' }} sortable />
                    <Column field="prcDelai" header="% délai" style={{ width: '10%' }} sortable />
                    <Column field="nbHdelai" header="Hors delai" style={{ width: '12%' }} sortable />
                </DataTable>
            </div>

        );
    }
}