import React, { Component } from 'react';
import CISService from '../service/CISService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';

export class FormStructure extends Component {
    constructor() {
        super();
        this.state = {
            liste_Structure: [],
            liste_Quartier: [],
            liste_Etablissement: [],
            selectedStructure: null,
            idStruct: '',
            libelleStruct: '',
            typeStruct: '',
            codeStruct: '',
            longitudeStruct: '',
            photoStruct: '',
            descStruct: '',
            latitudeStruct: '',
            precisionStruct: '',
            file: null,
            urlfile: null,
            idQuartier: null, //Clé étrangère de la table Quartier
            liste_type: [
                { label: 'Agence', value: 'Agence' },
                { label: 'Accueil', value: 'Accueil' },
                { label: 'Bureau', value: 'Bureau' },
                { label: 'Appartement', value: 'Appartement' },
                { label: 'Site', value: 'Site' },
            ],
            ////////////////////////////
            modif: false, // pour vérifier s'il s'agit d'une modification ou un ajout
            visible: false, // pour afficher la boite de confirmation de suppression
            visible_dlg_form: false, // pour afficher la boite de dialogue du formation de saisie
            showindicator: false, // pour afficher l'état de chargement
            submit: false // pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            ////////////////////////////
        };
        this.edit_structure = this.edit_structure.bind(this);
        this.conf_delete_structure = this.conf_delete_structure.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.CISService = new CISService();
    }

    componentDidMount() {
        this.get_liste_structure();
        this.get_liste_quartier();
    }

    get_liste_structure() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_structure().then(data => this.setState({ liste_Structure: data, showindicator: false }));
    }

    //Liste Des Quartiers
    get_liste_quartier() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_quartier().then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let quartier = data.map(x => {
                    return { value: x.idQuartier, label: x.libelleQuartier }
                });
                this.setState({ liste_Quartier: quartier });
            }
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_structure();
            this.get_liste_structure();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
        this.setState({ submit: false });
    }

    edit_structure(data) {
        if (data != null && data != undefined) {
            this.setState({ modif: true });
            this.setState({ libelleStruct: data.libelleStruct });
            this.setState({ typeStruct: data.typeStruct });
            this.setState({ codeStruct: data.codeStruct });
            this.setState({ longitudeStruct: data.longitudeStruct });
            this.setState({ photoStruct: data.photoStruct });
            this.setState({ descStruct: data.descStruct });
            this.setState({ latitudeStruct: data.latitudeStruct });
            this.setState({ precisionStruct: data.precisionStruct });
            this.setState({ idQuartier: data.idQuartier.idQuartier });
            this.setState({ selectedStructure: data });
            if (data.photoCli !== null && data.photoCli !== '') {
                this.setState({ urlfile: this.CISService.download_file(data.photoStruct) })
            } else {
                this.setState({ urlfile: null })
            }
            this.setState({ visible_dlg_form: true });
        }
    }

    annule_structure() {
        this.setState({ libelleStruct: '' });
        this.setState({ typeStruct: '' });
        this.setState({ codeStruct: '' });
        this.setState({ longitudeStruct: '' });
        this.setState({ photoStruct: '' });
        this.setState({ descStruct: '' });
        this.setState({ latitudeStruct: '' });
        this.setState({ precisionStruct: '' });
        this.setState({ idQuartier: null });
        this.setState({ urlfile: null })
        this.setState({ modif: false });
        this.setState({ visible_dlg_form: false });
    }


    enreg_structure() {
        this.setState({ submit: true });
        if (this.state.libelleStruct == '' || this.state.typeStruct == '' || this.state.codeStruct == '' ||
            this.state.idQuartier == null || this.state.idQuartier == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            let upload = false;
            let nomfic = this.state.photoStruct;
            if (this.state.file != null && this.state.file != undefined && this.state.file != '') {
                let name = this.state.file.name;
                upload = true;
                nomfic = 'PST_' + this.CISService.get_userconnected().idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
            }
            if (this.state.modif == false) {
                let data = {
                    idStruct: 0,
                    libelleStruct: this.state.libelleStruct,
                    typeStruct: this.state.typeStruct,
                    codeStruct: this.state.codeStruct,
                    longitudeStruct: this.state.longitudeStruct,
                    photoStruct: nomfic,
                    descStruct: this.state.descStruct,
                    latitudeStruct: this.state.latitudeStruct,
                    precisionStruct: this.state.precisionStruct,
                    idQuartier: { idQuartier: this.state.idQuartier },
                    idEtabl: this.CISService.get_etablissement() ,
                }
                this.CISService.enreg_structure(data).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        if (reponse.code == 200) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                        }
                        this.resultat(reponse.code, reponse.contenu);
                    }
                });
            } else {
                this.setState({ showindicator: true });
                let data = this.state.selectedStructure;
                data.libelleStruct = this.state.libelleStruct;
                data.typeStruct = this.state.typeStruct;
                data.codeStruct = this.state.codeStruct;
                data.longitudeStruct = this.state.longitudeStruct;
                data.photoStruct = nomfic;
                data.descStruct = this.state.descStruct;
                data.latitudeStruct = this.state.latitudeStruct;
                data.precisionStruct = this.state.precisionStruct;
                data.idQuartier = { idQuartier: this.state.idQuartier };
                this.CISService.enreg_structure(data).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        if (reponse.code == 200) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                        }
                        this.resultat(reponse.code, reponse.contenu);
                    }
                });
            }
        }
    }

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_structure(rowData)} style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_structure(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -17 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_structure() {
        if (this.state.selectedStructure == null || this.state.selectedStructure == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.CISService.delete_structure(this.state.selectedStructure).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_structure(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedStructure: data });
            this.setState({ msg: data.idStruct });
            this.setState({ visible: true });
            this.setState({ selectedStructure: data });
        }
    }

    /* Affichage de la boite de dialogue pour l'ajout Structure */
    toogleDlg() {
        this.annule_structure();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedStructure == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_structure(this.state.selectedStructure)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )
    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

     /* Exportation du tableau contenant la liste des agences en fichier CSV */
     exportCSV = () => {
        //ref={(el) => { this.dt = el; }}
        this.dt.exportCSV("Liste_bureau");
    }

 /* Uploader sur le serveur le logo */
 fileUpload(file, namefile) {
    this.CISService.upload_file(file, namefile).then(data => {
        if (data != null && data != undefined && data.code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement de la photo sur le serveur effectué avec succès' });
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec upload de la photo sur le serveur' });
        }
    });
}
    openFileUpload() {
        document.getElementById("hiddenFile").click();
    }

    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_structure();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="card p-fluid col-12">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                {/* Tableau contenant la liste des Structures*/}
                <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_Structure} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={10}
                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedStructure: e.data })} onRowSelect={e => this.setState({ selectedStructure: e.data })}
                    responsive={true} selection={this.state.selectedStructure} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                    <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                    <Column field="libelleStruct" filter={true} filterMatchMode='contains' header="Nom" sortable={true} />
                    <Column field="codeStruct" filter={true} filterMatchMode='contains' header="Code" sortable={true} style={{ width: '20%' }} />
                    <Column field="typeStruct" filter={true} filterMatchMode='contains' header="Type" sortable={true} style={{ width: '15%' }} />
                    <Column field="idQuartier.libelleQuartier" filter={true} filterMatchMode='contains' header="Quartier" sortable={true} style={{ width: '25%' }} />

                </DataTable>

                {/* Boite de dialogue d'ajout et de modification Des Structures */}
                <Dialog visible={this.state.visible_dlg_form} style={{ width: '70%' }} header={this.state.modif == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annule_structure()} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_structure()} style={{ width: 120 }} />
                    </>}>
                    <div className="formgrid grid">
                        <div className="field col-6">
                            <label htmlFor="libelleStruct">Nom *</label>
                            <InputText id="libelleStruct" value={this.state.libelleStruct} onChange={(e) => this.setState({ libelleStruct: e.target.value })} required autoFocus className={classNames({ 'p-invalid': this.state.submit && !this.state.libelleStruct })} />
                            {this.state.submit && !this.state.libelleStruct && <small className="p-invalid" style={{ color: 'red' }}>Le nom est obligatoire </small>}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="codeStruct">Code *</label>
                            <InputText id="codeStruct" value={this.state.codeStruct} onChange={(e) => this.setState({ codeStruct: e.target.value })} required  className={classNames({ 'p-invalid': this.state.submit && !this.state.codeStruct })} />
                            {this.state.submit && !this.state.codeStruct && <small className="p-invalid" style={{ color: 'red' }}>Le code est obligatoire </small>}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="type">Type *</label>
                            <Dropdown id="type" options={this.state.liste_type} value={this.state.typeStruct} onChange={(e) => this.setState({ typeStruct: e.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.typeStruct })} />
                            {this.state.submit && !this.state.typeStruct && <small className="p-invalid" style={{ color: 'red' }}>Le type est obligatoire  </small>}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="descStruct">Description </label>
                            <InputText id="descStruct" value={this.state.descStruct} onChange={(e) => this.setState({ descStruct: e.target.value })} required  />
                        </div>
                        <div className="field col-6">
                            <label htmlFor="longitudeStruct">Longitude </label>
                            <InputText id="longitudeStruct" value={this.state.longitudeStruct} onChange={(e) => this.setState({ longitudeStruct: e.target.value })} required  />
                        </div>
                        <div className="field col-6">
                            <label htmlFor="latitudeStruct">Latitude </label>
                            <InputText id="latitudeStruct" value={this.state.latitudeStruct} onChange={(e) => this.setState({ latitudeStruct: e.target.value })} required  />
                        </div>
                        <div className="field col-6">
                            <label htmlFor="precisionStruct">Précision </label>
                            <InputText id="precisionStruct" value={this.state.precisionStruct} onChange={(e) => this.setState({ precisionStruct: e.target.value })} required  />
                        </div>
                        <div className="field col-6">
                            <label htmlFor="idQuartier">Quartier *</label>
                            <Dropdown id="idQuartier" options={this.state.liste_Quartier} value={this.state.idQuartier} onChange={(e) => this.setState({ idQuartier: e.target.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.idQuartier })} />
                            {this.state.submit && !this.state.idQuartier && <small className="p-invalid" style={{ color: 'red' }}>Le quartier est obligatoire.</small>}
                        </div>
                        <div className="field col-2">
                            <center>
                                <a onClick={() => this.openFileUpload()} style={{ cursor: 'pointer' }}>
                                    <div style={{ height: 90, width: 120, borderRadius: 10 }} >
                                        <center>
                                            {this.state.urlfile == null ?
                                                <div style={{ width: '120px', height: '90px', borderRadius: 10, backgroundColor: '#f7f7f7', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>PHOTO</label></div> :
                                                <img src={this.state.urlfile} style={{ width: '120px', height: '90px', borderRadius: 10, backgroundColor: '#f7f7f7' }} />}
                                        </center>
                                    </div>
                                </a>
                                <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                                    if (e.target.files && e.target.files[0]) {
                                        this.setState({ file: e.target.files[0] }, () => {
                                            let url = URL.createObjectURL(this.state.file);
                                            this.setState({ urlfile: url });
                                        })
                                    }
                                }} />
                            </center>
                        </div>
                    </div>


                </Dialog>
            </div>
        );
    }
}