import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import CISService from '../service/CISService';
import { classNames } from 'primereact/utils';
import { SplitButton } from 'primereact/splitbutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputMask } from 'primereact/inputmask';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Steps } from 'primereact/steps';
import { Password } from 'primereact/password';

export class FormEtablissement extends Component {
    constructor() {
        super();

        this.state = {
            liste_data: [],
            selectedData: null,
            activeIndex: 0,
            stepItems: [
                {
                    label: 'Information',
                    index: 0,
                    command: (event) => {

                    }
                },
                {
                    label: 'Adresses',
                    index: 1,
                    command: (event) => {

                    }
                },
                {
                    label: 'Compte',
                    index: 2,
                    command: (event) => {

                    }
                }
            ],
            liste_actif: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' }
            ],
            formData: {
                idEtabl: 0,
                codeEtabl: '',
                nomEtabl: "",
                domaineEtabl: "",
                telEtabl: "",
                registerFirstName: "",
                registerLastName: "",
                registerEmail: "",
                registerLogin: "",
                registerPwd: "",
                registerPwdConf: "",
                adrEtabl: "",
                emailEtabl: "",
                regionEtabl: "",
                villeEtabl: "",
                quartierEtabl: "",
                statutEtabl: "En attente",
            },
            ////////////////////////////
            modif_data: false, // pour vérifier s'il s'agit d'une modification ou un ajout
            visible: false, // pour afficher la boite de confirmation de suppression
            visible_dlg_form: false, // pour afficher la boite de dialogue du formation de saisie
            showindicator: false, // pour afficher l'état de chargement
            submit: false,// pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            submitUser: false,// pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            submitAdr: false,// pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            msg: '',
            ////////////////////////////
        };
        this.edit_data = this.edit_data.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.appservice = new CISService();

    }

    componentDidMount() {
        this.get_liste_etablissement();
    }

    get_liste_etablissement() {
        this.appservice.get_liste_etablissement().then(data => this.setState({ liste_data: data }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annuler();
            this.get_liste_etablissement();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    edit_data(data) {
        if (data != null && data != undefined) {
            this.setState({ formData: data, activeIndex: 0 });
            this.setState({ modif_data: true });
            this.setState({ visible_dlg_form: true });
            this.setState({ selectedData: data });
        }
    }

    annuler() {
        this.setState({
            formData: {
                idEtabl: 0,
                codeEtabl: '',
                nomEtabl: "",
                domaineEtabl: "",
                telEtabl: "",
                registerFirstName: "",
                registerLastName: "",
                registerEmail: "",
                registerLogin: "",
                registerPwd: "",
                registerPwdConf: "",
                adrEtabl: "",
                emailEtabl: "",
                regionEtabl: "",
                villeEtabl: "",
                quartierEtabl: "",
                statutEtabl: "En attente",
            },
        });
        this.setState({ showindicator: false });
        this.setState({ modif_data: false });
        this.setState({ visible_dlg_delete: false });
        this.setState({ visible_dlg_form: false });
    }

    actionTemplate(rowData) {
        const items = [
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_data(rowData);
                }
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-trash',
                command: (e) => {
                    this.conf_delete_data(rowData);
                }
            },
        ]
        if (this.appservice.get_etablissement().codeEtabl == 'OPTION' || this.appservice.get_profil() == 'Administrateur') {
            if (rowData.statutEtabl == 'Validé') {
                items.push(
                    {
                        label: 'Suspendre',
                        icon: 'pi pi-ban',
                        command: (e) => {
                            this.setState({ selectedData: rowData });
                            this.setState({ showindicator: true });
                            rowData.statutEtabl = "Suspendu";
                            this.appservice.toogle_etablissement(rowData).then(data => this.resultat(data.code, data.contenu));
                        }
                    },

                )
            } else {
                items.push(
                    {
                        label: 'Activer',
                        icon: 'pi pi-check',
                        command: (e) => {
                            this.setState({ selectedData: rowData });
                            this.setState({ showindicator: true });
                            rowData.statutEtabl = "Validé";
                            this.appservice.toogle_etablissement(rowData).then(data => this.resultat(data.code, data.contenu));
                        }
                    },

                )
            }
        }
        return <SplitButton label="Modifier" icon="pi pi-pencil" onClick={(e) => this.edit_data(rowData)} model={items}></SplitButton>
    }


    delete_data() {
        this.appservice.delete_etablissement(this.state.selectedData).then(data => this.resultat(data.code, data.contenu));
    }

    conf_delete_data(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedData: data });
            this.setState({ visible: true });
        }
    }

    /* Affichage de la boite de dialogue pour l'ajout Codification */
    toogleDlg() {
        this.annuler();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }


    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedData == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data(this.state.selectedData)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    checkForm() {
        if (this.state.activeIndex == 0) {
            this.setState({ submit: true });
            if (this.state.formData.nom == "" || this.state.formData.nomEtabl == "" || this.state.formData.codeEtabl == "" || this.state.formData.telEtabl == "" || this.state.formData.domaineEtabl == "" || this.state.formData.emailEtabl == "") {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
                return;
            }
        } else if (this.state.activeIndex == 1) {
            this.setState({ submitAdr: true });
            if (this.state.formData.regionEtabl == "" || this.state.formData.villeEtabl == "" || this.state.formData.quartierEtabl == "" || this.state.formData.adrEtabl == "") {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
                return;
            }
        } else if (this.state.activeIndex == 2) {
            this.setState({ submitUser: true });
            if (this.state.formData.registerFirstName == "" || this.state.formData.registerLastName == "" || this.state.formData.registerLogin == "" || this.state.formData.registerPwd == "" || this.state.formData.registerPwdConf == "") {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
                return;
            }
        } else {
            return;
        }
        this.setState({ activeIndex: this.state.activeIndex + 1 });
    }


    /* Fonction de validation du login */
    checkLogin() {
        this.setState({ submitLogin: true });
        this.appservice.get_user_by_login(this.state.formDataUser.login).then(user => {
            if (user !== null && user !== undefined) {
                this.setState({ submitLogin: false, msgLogin: 'Ce login existe déja' });
                this.setState({ formDataUser: user });
            } else {
                this.setState({ submitLogin: true, msgLogin: '' });
            }
        })
    }

    enreg_data() {
        if (this.state.formData.nomEtabl == '' || this.state.formData.codeEtabl == '') {
            this.setState({ msg: 'Veuilllez saisir le registerLogin et le mot de passe' });
        } else {
            this.setState({ showindicator: true });
            this.appservice.enreg_etablissement(this.state.formData).then(data => {
                this.setState({ showindicator: false });
                this.resultat(data.code,data.contenu);
            });
        }
    }

    getItem() {
        if (this.state.activeIndex == 0) {
            return <div className="p-fluid">
                <div className="formgrid grid">
                    <div className="field col-12 md:col-6">
                        <label htmlFor="nomEtabl">Nom de l'établissement *</label>
                        <InputText id="nomEtabl" value={this.state.formData.nomEtabl} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.nomEtabl })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'nomEtabl', '', this.state.formData) })}
                            required autoFocus />
                        {this.state.submit && !this.state.formData.nomEtabl && <small className="p-invalid" style={{ color: 'red' }}>Le nom est obligatoire.</small>}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="codeEtabl">Sigle *</label>
                        <InputText id="codeEtabl" value={this.state.formData.codeEtabl} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.codeEtabl })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'codeEtabl', '', this.state.formData) })}
                            required />
                        {this.state.submit && !this.state.formData.codeEtabl && <small className="p-invalid" style={{ color: 'red' }}>Le sigle est obligatoire.</small>}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="telEtabl">Téléphone * </label>
                        <InputMask id="telEtabl" mask="999999999" value={this.state.formData.telEtabl} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.telEtabl })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'telEtabl', '', this.state.formData) })}
                            required />
                        {this.state.submit && !this.state.formData.telEtabl && <small className="p-invalid" style={{ color: 'red' }}>Le téléphone est obligatoire.</small>}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="emailEtabl">Email *</label>
                        <InputText id="emailEtabl" value={this.state.formData.emailEtabl} onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'emailEtabl', '', this.state.formData) })} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.emailEtabl })} />
                        {this.state.submit && !this.state.formData.emailEtabl && <small className="p-invalid" style={{ color: 'red' }}>L'email est obligatoire.</small>}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="domaineEtabl">Domaine * </label>
                        <Dropdown id='domaineEtabl' autoWidth={false} value={this.state.formData.domaineEtabl} filter={true} filterBy='label' filterMatchMode='contains'
                            options={[
                                { value: 'Administration publique', label: 'Administration publique' },
                                { value: 'Assurance', label: 'Assurance' },
                                { value: 'Banque', label: 'Banque' },
                                { value: 'Commerce', label: 'Commerce' },
                                { value: 'Prestation de service', label: 'Prestation de service' },
                                { value: 'Télécommunication', label: 'Télécommunication' },
                                { value: 'ONG', label: 'ONG' },
                                { value: 'Autre', label: 'Autre' },
                            ]}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'domaineEtabl', '', this.state.formData) })}
                            appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.domaineEtabl })} />
                        {this.state.submit && !this.state.formData.domaineEtabl && <small className="p-invalid" style={{ color: 'red' }}>Le domaine est obligatoire</small>}
                    </div>

                </div>
            </div>;
        } else if (this.state.activeIndex == 1) {
            return <div className="p-fluid">
                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-6">
                        <label htmlFor="regionEtabl">Region / province * </label>
                        <InputText id="regionEtabl" value={this.state.formData.regionEtabl} className={classNames({ 'p-invalid': this.state.submitAdr && !this.state.formData.regionEtabl })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'regionEtabl', '', this.state.formData) })}
                            required  />
                        {this.state.submitAdr && !this.state.formData.regionEtabl && <small className="p-invalid" style={{ color: 'red' }}>La region/province est obligatoire</small>}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="villeEtabl">Ville *</label>
                        <InputText id="villeEtabl" value={this.state.formData.villeEtabl} className={classNames({ 'p-invalid': this.state.submitAdr && !this.state.formData.villeEtabl })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'villeEtabl', '', this.state.formData) })}
                            required />
                        {this.state.submitAdr && !this.state.formData.villeEtabl && <small className="p-invalid" style={{ color: 'red' }}>La ville est obligatoire.</small>}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="quartierEtabl">Quartier *</label>
                        <InputText id="quartierEtabl" value={this.state.formData.quartierEtabl} className={classNames({ 'p-invalid': this.state.submitAdr && !this.state.formData.quartierEtabl })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'quartierEtabl', '', this.state.formData) })}
                            required />
                        {this.state.submitAdr && !this.state.formData.quartierEtabl && <small className="p-invalid" style={{ color: 'red' }}>Le nom est obligatoire.</small>}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="adrEtabl">Adresse *</label>
                        <InputText id='adrEtabl' value={this.state.formData.adrEtabl} className={classNames({ 'p-invalid': this.state.submitAdr && !this.state.formData.adrEtabl })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'adrEtabl', '', this.state.formData) })}
                            required />
                        {this.state.submitAdr && !this.state.formData.adrEtabl && <small className="p-invalid" style={{ color: 'red' }}>L'adresse est obligatoire.</small>}
                    </div>
                </div>
            </div>
        }
        else if (this.state.activeIndex == 2) {
            return <div className="p-fluid">
                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-6">
                        <label htmlFor="registerLastName">Nom *</label>
                        <InputText id="registerLastName" value={this.state.formData.registerLastName} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formData.registerLastName })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'registerLastName', '', this.state.formData) })}
                            required />
                        {this.state.submitUser && !this.state.formData.registerLastName && <small className="p-invalid" style={{ color: 'red' }}>Le nom est obligatoire.</small>}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="registerFirstName">Prénom *</label>
                        <InputText id="registerFirstName" value={this.state.formData.registerFirstName} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formData.registerFirstName })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'registerFirstName', '', this.state.formData) })}
                            required />
                        {this.state.submitUser && !this.state.formData.registerFirstName && <small className="p-invalid" style={{ color: 'red' }}>Le prénom est obligatoire.</small>}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="registerLogin">Login * </label>
                        <InputText id="registerLogin" value={this.state.formData.registerLogin} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formData.registerLogin })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'registerLogin', '', this.state.formData) })}
                            required onBlur={(e) => {
                                if (this.state.formData.registerLogin !== "") {
                                    //this.checkLogin();
                                }
                            }}  />
                        {this.state.submitUser && !this.state.formData.registerLogin && <small className="p-invalid" style={{ color: 'red' }}>Le registerLogin est obligatoire</small>}
                        {this.state.submitLogin == false && this.state.modif == false && <small className="p-invalid" style={{ color: 'red' }}>{this.state.msgLogin}.</small>}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="registerEmail">Email * </label>
                        <InputText id="registerEmail" value={this.state.formData.registerEmail} onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'registerEmail', '', this.state.formData) })} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formData.registerEmail })} />
                        {this.state.submitUser && !this.state.formData.registerEmail && <small className="p-invalid" style={{ color: 'red' }}>L'email est obligatoire.</small>}
                    </div>
                    {this.state.modif_data == false ?
                        <>
                            <div className="field col-12 md:col-6">
                                <label htmlFor="registerPwd">Mot de passe *</label>
                                <Password id="registerPwd" value={this.state.formData.registerPwd} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formData.registerPwd })}
                                    onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'registerPwd', '', this.state.formData) })}
                                    required />
                                {this.state.submitUser && !this.state.formData.registerPwd && <small className="p-invalid" style={{ color: 'red' }}>Le mot de passe est obligatoire.</small>}
                            </div>

                            <div className="field col-12 md:col-6">
                                <label htmlFor="registerPwdConf">Confirmer mot de passe *</label>
                                <Password id='registerPwdConf' value={this.state.formData.registerPwdConf} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formData.registerPwdConf })}
                                    onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'registerPwdConf', '', this.state.formData) })}
                                    required />
                                {this.state.submitUser && !this.state.formData.registerPwdConf && <small className="p-invalid" style={{ color: 'red' }}>Le mot de passe est obligatoire.</small>}
                            </div>
                        </> : null}

                </div>
            </div>
        }

    }


    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_data();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="card p-fluid col-12">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer la ligne sélectionnée ?
                    </div>
                </Dialog>
                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                {/* Tableau contenant la liste des Communes  */}
                <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={10}
                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedData: e.data })} onRowSelect={e => this.setState({ selectedData: e.data })}
                    responsive={true} selection={this.state.selectedData} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                    <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                    <Column field="nomEtabl" filter={true} filterMatchMode='contains' header="Nom" />
                    <Column field="codeEtabl" filter={true} filterMatchMode='contains' header="Sigle" style={{ width: '10%' }} />
                    <Column field="statutEtabl" filter={true} filterMatchMode='contains' header="Statut" style={{ width: '10%' }} />
                    <Column field="telEtabl" filter={true} filterMatchMode='contains' header="Telephone" style={{ width: '10%' }} />
                    <Column field="domaineEtabl" filter={true} filterMatchMode='contains' header="Domaine" style={{ width: '15%' }} />
                </DataTable>

                {/* Boite de dialogue d'ajout et de modification des Communes */}
                <Dialog visible={this.state.visible_dlg_form} style={{ width: '70%' }} header={this.state.modif == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={<>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <Button label="RETOUR" disabled={this.state.activeIndex <= 0} icon="pi pi-angle-left" onClick={() => {
                                this.setState({ activeIndex: this.state.activeIndex - 1 });
                            }} style={{ width: 150 }} />
                            {this.state.activeIndex == 2 ?
                                <Button label="ENREGISTRER" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 150 }} className="p-button-success" />
                                :
                                <Button label="SUIVANT" disabled={this.state.activeIndex >= 2} icon="pi pi-angle-right" onClick={() => {
                                    this.checkForm();
                                }} iconPos='right' style={{ width: 150 }} />
                            }
                        </div>
                    </>}>
                    <div className="p-fluid">
                        <Steps model={this.state.stepItems} activeIndex={this.state.activeIndex} onSelect={(e) => this.setState({ activeIndex: e.index })} readOnly={true} />
                        <br />
                        <div className="p-col-12" style={{ height: 260 }}>
                            {this.getItem()}
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}