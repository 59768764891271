import React, { Component } from 'react';
import CISService from '../service/CISService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { classNames } from 'primereact/utils';
import { InputMask } from 'primereact/inputmask';
import { MultiSelect } from 'primereact/multiselect';
import { TabView, TabPanel } from 'primereact/tabview';
import { SplitButton } from 'primereact/splitbutton';
import { InputTextarea } from 'primereact/inputtextarea';

export class FormClient extends Component {
    constructor() {
        super();
        this.state = {
            tarifAbn: '',
            numAbn: '',
            codeAbn: '',
            statutAbn: null,
            descAbn: '',
            motifResilAbn: '',
            idOffre: null, //Clé étrangère de la table Offre

            liste_client: [],
            liste_structure: [],
            liste_categorie: [],
            liste_req: [],
            liste_offre: [],
            liste_selected_offre: [],
            liste_abonnement: [],
            liste_type_piece: [],
            liste_langue: [],
            liste_quartier: [],
            selectedClient: null,
            selectedAbonnement: null,
            selectedReq: null,
            visible_dlg_toogle_statut: false,
            idCli: '',
            nomCli: '',
            prenomCli: '',
            telCli: '',
            emailCli: '',
            adrCli: '',
            dateNaissCli: null,
            lieuNaissCli: '',
            sexeCli: null,
            file: null,
            filePI: null,
            fileDossier: null,
            urlfile: null,
            urlfilePI: null,
            urlfileDossier: null,
            photoCli: 'nophoto.png',
            numPiCli: '',
            dossierCli: '',
            photoPiCli: '',
            typeCli: '',
            idQuartier: null,
            idCodifCategorie: null,
            idStruct: null, //Clé étrangère de la table Structure
            idCodifTypePiece: null, //Clé étrangère de la table Codification
            idCodifLang: null, //Clé étrangère de la table Codification

            ////////////////////////////
            checkPhone: true, //Véfifier si le numéro existe déja
            modif: false, // pour vérifier s'il s'agit d'une modification ou un ajout
            modif_abn: false, // pour vérifier s'il s'agit d'une modification ou un ajout
            visible: false, // pour afficher la boite de confirmation de suppression
            visible_del_abonnement: false, // pour afficher la boite de confirmation de suppression
            visible_dlg_form: false, // pour afficher la boite de dialogue du formation de saisie
            visible_dlg_trt: false, // pour afficher la boite de dialogue du formation de saisie
            visible_dlg_abn: false, // pour afficher la boite de dialogue des abonnements
            visible_dlg_detail: false,
            showindicator: false, // pour afficher l'état de chargement
            submit: false // pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            ////////////////////////////
        };
        this.edit_client = this.edit_client.bind(this);
        this.edit_abonnement = this.edit_abonnement.bind(this);
        this.conf_delete_client = this.conf_delete_client.bind(this);
        this.conf_delete_abonnement = this.conf_delete_abonnement.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.CISService = new CISService();

    }

    componentDidMount() {
        this.get_liste_client();
        this.get_liste_offre();
        this.get_liste_codification();
        this.get_liste_quartier();
    }

    get_liste_client() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_client().then(data => this.setState({ liste_client: data, showindicator: false }));
    }

    getAbonnemts() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_abonnement_client(this.state.selectedClient.idCli).then(data => this.setState({ liste_abonnement: data, showindicator: false }));
    }

    getRequetes() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_requetes_client(this.state.selectedClient.idCli).then(data => this.setState({ liste_req: data, showindicator: false }));
    }

    //Liste des offres
    get_liste_offre() {
        //this.setState({ showindicator: true });
        this.CISService.get_liste_offre().then(data => {
          //  this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idOffre, label: x.libelleOffre }
                });
                this.setState({ liste_offre: liste });
            }
        });
    }

    search() {
        this.setState({ showindicator: true });
        this.CISService.getClientbyPhone(this.state.telCli).then(data => {
            this.setState({ showindicator: false });
            this.setState({ checkPhone: true });
            if (data !== null && data !== undefined) {
                if (data.telCli !== undefined) {
                    this.setState({ checkPhone: false });
                }
            }
        });
    }

    //Liste Des Quartiers
    get_liste_quartier() {
        //this.setState({ showindicator: true });
        this.CISService.get_liste_quartier().then(data => {
           // this.setState({ showindicator: false });
            if (data != undefined) {
                let quartier = data.map(x => {
                    return { value: x.idQuartier, label: x.libelleQuartier }
                });
                this.setState({ liste_quartier: quartier });
            }
        });
    }

    //Liste des Codifications 
    get_liste_codification() {
        //this.setState({ showindicator: true });
        this.CISService.get_liste_codification('LANGUE').then(data => {
            //this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libelleCodif }
                });
                this.setState({ liste_langue: liste });
            }
        });
       // this.setState({ showindicator: true });
        this.CISService.get_liste_codification('TYPE_PIECE').then(data => {
           //this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libelleCodif }
                });
                this.setState({ liste_type_piece: liste });
            }
        });
       // this.setState({ showindicator: true });
        this.CISService.get_liste_codification('CATEGORIE_CLIENT').then(data => {
           // this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.descriptionCodif }
                });
                this.setState({ liste_categorie: liste });
            }
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_client();
            this.get_liste_client();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
        this.setState({ submit: false });
    }

    resultat_abn(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.getAbonnemts();
            this.annule_abonnement();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
        this.setState({ submit: false });
    }

    edit_abonnement(data) {
        if (data != null && data != undefined) {
            this.setState({ numAbn: data.numAbn });
            this.setState({ codeAbn: data.codeAbn });
            this.setState({ statutAbn: data.statutAbn });
            this.setState({ descAbn: data.descAbn });
            this.setState({ motifResilAbn: data.motifResilAbn });
            this.setState({ idOffre: data.idOffre.idOffre });
            this.setState({ modif_abn: true });
            this.setState({ selectedAbonnement: data });
            this.setState({ visible_dlg_abn: true });
        }
    }

    edit_client(data) {
        if (data != null && data != undefined) {
            this.setState({ nomCli: data.nomCli });
            this.setState({ prenomCli: data.prenomCli });
            this.setState({ telCli: data.telCli });
            this.setState({ emailCli: data.emailCli });
            this.setState({ adrCli: data.adrCli });
            this.setState({ dateNaissCli: data.dateNaissCli });
            this.setState({ lieuNaissCli: data.lieuNaissCli });
            this.setState({ sexeCli: data.sexeCli });
            this.setState({ photoCli: data.photoCli });
            this.setState({ numPiCli: data.numPiCli });
            this.setState({ photoPiCli: data.photoPiCli });
            this.setState({ dossierCli: data.dossierCli });
            this.setState({ idCodifCategorie: data.idCodifCategorie.idCodif });
            this.setState({ typeCli: data.typeCli });
            this.setState({ idStruct: data.idStruct.idStruct });
            this.setState({ idCodifTypePiece: data.idCodifTypePiece.idCodif });
            this.setState({ idCodifLang: data.idCodifLang.idCodif });
            this.setState({ idQuartier: data.idQuartier.idQuartier });
            this.setState({ modif: true });
            this.setState({ selectedClient: data });
            if (data.photoCli !== null && data.photoCli !== '') {
                this.setState({ urlfile: this.CISService.download_file(data.photoCli) })
            } else {
                this.setState({ urlfile: null })
            }
            if (data.photoPiCli !== null && data.photoPiCli !== '') {
                this.setState({ urlfilePI: 'OK' })
            } else {
                this.setState({ urlfilePI: null })
            }
            if (data.dossierCli !== null && data.dossierCli !== '') {
                this.setState({ urlfileDossier: 'OK' })
            } else {
                this.setState({ urlfileDossier: null })
            }

            this.setState({ visible_dlg_form: true });
        }
    }

    annule_client() {
        this.setState({ nomCli: '' });
        this.setState({ prenomCli: '' });
        this.setState({ telCli: '' });
        this.setState({ emailCli: '' });
        this.setState({ adrCli: '' });
        this.setState({ dateNaissCli: '' });
        this.setState({ lieuNaissCli: '' });
        this.setState({ sexeCli: '' });
        this.setState({ photoCli: '' });
        this.setState({ numPiCli: '' });
        this.setState({ photoPiCli: '' });
        this.setState({ dateEnregCli: '' });
        this.setState({ typeCli: '' });
        this.setState({ idQuartier: null });
        this.setState({ idStruct: null });
        this.setState({ idCodifTypePiece: null });
        this.setState({ idCodifLang: null });
        this.setState({ idCodifCategorie: null });
        this.setState({ modif: false });
        this.setState({ urlfile: null });
        this.setState({ urlfilePI: null });
        this.setState({ urlfileDossier: null });
        this.setState({ file: null });
        this.setState({ filePI: null,liste_selected_offre: [] });
        this.setState({ fileDossier: null });
        this.setState({ visible_dlg_form: false });
    }

    annule_abonnement() {
        this.setState({ numAbn: '' });
        this.setState({ codeAbn: '' });
        this.setState({ motifResilAbn: '' });
        this.setState({ idOffre: null });
        this.setState({ modif_abn: false });
        this.setState({ visible_dlg_abn: false, visible_dlg_toogle_statut: false });
    }


    enreg_client() {
        this.setState({ submit: true });
        if (this.state.nomCli == '' || this.state.prenomCli == '' || this.state.telCli == '' ||
            this.state.lieuNaissCli == '' || this.state.numPiCli == '' || this.state.idQuartier == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let upload = false;
            let nomfic = this.state.photoCli;
            if (this.state.file != null && this.state.file != undefined) {
                let name = this.state.file.name;
                nomfic = 'PH_' + this.CISService.get_userconnected().idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                upload = true;
            }

            let uploadPI = false;
            let nomficPI = this.state.photoPiCli;
            if (this.state.filePI != null && this.state.filePI != undefined) {
                let name = this.state.filePI.name;
                nomficPI = 'PI_' + this.CISService.get_userconnected().idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                uploadPI = true;
            }

            let uploadDossier = false;
            let nomficDossier = this.state.dossierCli;
            if (this.state.fileDossier != null && this.state.fileDossier != undefined) {
                let name = this.state.fileDossier.name;
                nomficDossier = 'DO_' + this.CISService.get_userconnected().idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                uploadDossier = true;
            }

            if (this.state.modif == false) {
                this.setState({ showindicator: true });
                let data = {
                    idCli: 0,
                    nomCli: this.state.nomCli,
                    prenomCli: this.state.prenomCli,
                    telCli: this.state.telCli,
                    emailCli: this.state.emailCli,
                    adrCli: this.state.adrCli,
                    dateNaissCli: this.state.dateNaissCli,
                    lieuNaissCli: this.state.lieuNaissCli,
                    sexeCli: this.state.sexeCli,
                    photoCli: nomfic,
                    numPiCli: this.state.numPiCli,
                    typeCli: this.state.typeCli,
                    photoPiCli: nomficPI,
                    dossierCli: nomficDossier,
                    idStruct: { idStruct: this.state.idStruct },
                    idCodifTypePiece: { idCodif: this.state.idCodifTypePiece },
                    idCodifLang: { idCodif: this.state.idCodifLang },
                    idCodifCategorie: { idCodif: this.state.idCodifCategorie },
                    idQuartier: { idQuartier: this.state.idQuartier },
                    abonnementsCli: this.state.liste_selected_offre.length > 0 ? this.state.liste_selected_offre.map(x => { return { idOffre: x } }) : []
                }
                this.CISService.enreg_client(data).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        if (reponse.code == 200) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                            if (uploadPI === true) {
                                this.fileUpload(this.state.filePI, nomficPI);
                            }
                            if (uploadDossier === true) {
                                this.fileUpload(this.state.fileDossier, nomficDossier);
                            }
                        }
                        this.resultat(reponse.code, reponse.contenu);
                    }
                });
            } else {
                this.setState({ showindicator: true });
                let data = this.state.selectedClient;
                data.nomCli = this.state.nomCli;
                data.prenomCli = this.state.prenomCli;
                data.telCli = this.state.telCli;
                data.emailCli = this.state.emailCli;
                data.adrCli = this.state.adrCli;
                data.dateNaissCli = this.state.dateNaissCli;
                data.lieuNaissCli = this.state.lieuNaissCli;
                data.sexeCli = this.state.sexeCli;
                data.photoCli = nomfic;
                data.dossierCli = nomficDossier;
                data.numPiCli = this.state.numPiCli;
                data.photoPiCli = nomficPI;
                data.typeCli = this.state.typeCli;
                data.latitudeClient = this.state.latitudeClient;
                data.precisionClient = this.state.precisionClient;
                data.idEtabl = this.state.idEtabl;
                data.idCodifTypePiece = { idCodif: this.state.idCodifTypePiece };
                data.idCodifCategorie = { idCodif: this.state.idCodifCategorie };
                data.idCodifLang = { idCodif: this.state.idCodifLang };
                data.idQuartier = { idQuartier: this.state.idQuartier };
                this.CISService.enreg_client(data).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        if (reponse.code == 200) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                            if (uploadPI === true) {
                                this.fileUpload(this.state.filePI, nomficPI);
                            }
                            if (uploadDossier === true) {
                                this.fileUpload(this.state.fileDossier, nomficDossier);
                            }
                        }
                        this.resultat(reponse.code, reponse.contenu);
                    }
                });
            }
        }
    }

    enreg_abonnement() {
        this.setState({ submit: true });
        if (this.state.numAbn == '' || this.state.idOffre == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif_abn == false) {
                this.setState({ showindicator: true });
                let data = {
                    idAbn: 0,
                    tarifAbn: 0,
                    numAbn: this.state.numAbn,
                    statutAbn: 'ACTIF',
                    idOffre: { idOffre: this.state.idOffre },
                    idCli: this.state.selectedClient
                }
                this.CISService.enreg_abonnement(data).then(data => this.resultat_abn(data.code, data.contenu));
            } else {
                this.setState({ showindicator: true });
                let data = this.state.selectedAbonnement;
                data.numAbn = this.state.numAbn;
                data.statutAbn = this.state.statutAbn;
                data.descAbn = this.state.descAbn;
                data.motifResilAbn = this.state.motifResilAbn;
                data.idOffre = { idOffre: this.state.idOffre };
                this.CISService.enreg_abonnement(data).then(data => this.resultat_abn(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData, props) {
        let items = [
            {
                label: 'Détails',
                icon: 'pi pi-eye',
                command: (e) => {
                    this.setState({ selectedClient: rowData, visible_dlg_detail: true }, () => {
                        this.getAbonnemts();
                        this.getRequetes();
                    });
                }
            },
            {
                label: 'Nouvelle requête',
                icon: 'pi pi-comments',
                command: (e) => {
                    this.setState({ selectedClient: rowData}, () => {
                        window.location = "#/requete/new/"+rowData.telCli;
                    });
                }
            }
        ]
        if (this.CISService.get_profil() == 'Administrateur') {
            items.push(
                {
                    label: 'Modifier',
                    icon: 'pi pi-pencil',
                    command: (e) => {
                        this.edit_client(rowData);
                    }
                },
                {
                    label: 'Supprimer',
                    icon: 'pi pi-times',
                    command: (e) => {
                        this.conf_delete_client(rowData);
                    }
                },
            )
        }
        if (rowData.dossierCli == null || rowData.dossierCli == undefined || rowData.dossierCli == '') {
            items.push(
                {
                    label: 'Télécharger contrat',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.setState({ selectedClient: rowData });
                        this.CISService.download_fichier_joint(rowData.dossierCli);
                    }
                },
            )
        }
        if (rowData.photoPiCli == null || rowData.photoPiCli == undefined || rowData.photoPiCli == '') {
            items.push(
                {
                    label: 'Télécharger PI',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.setState({ selectedClient: rowData });
                        this.CISService.download_fichier_joint(rowData.photoPiCli);
                    }
                },
            )
        }
        return <SplitButton label={props.rowIndex + 1} icon="pi pi-eye" 
            onClick={(e) => {
                this.setState({ selectedClient: rowData, visible_dlg_detail: true }, () => {
                    this.getAbonnemts();
                    this.getRequetes();
                });
            }} model={items}></SplitButton>
    }


    actionTemplateReq(rowData, props) {
        let items = [{
            label: 'Historique',
            icon: 'pi pi-eye',
            command: (e) => {

            }
        },
        ]
        if (rowData.statutReq !== "FERME") {
            items.push(
                {
                    label: 'Traiter',
                    icon: 'pi pi-pencil',
                    command: (e) => {

                    }
                },
                {
                    label: 'Cloturer',
                    icon: 'pi pi-thumbs-up-fill',
                    command: (e) => {

                    }
                },
            )
        }
        return <SplitButton label='Historique' icon="pi pi-eye" 
            onClick={(e) => {

            }} model={items}/>
    }

    /* Colonne Action du tableau contenant la liste des communes */
    actionTemplateAbn(rowData, column) {
        let classe='p-button-info';
        const items = [
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_abonnement(rowData);
                }
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-trash',
                command: (e) => {
                    this.conf_delete_abonnement(rowData);
                }
            },
        ]
        if (rowData.statutAbn == 'ACTIF' && this.CISService.get_profil() == 'Administrateur') {
            items.push(
                {
                    label: 'Suspendre',
                    icon: 'pi pi-ban',
                    command: (e) => {
                        this.setState({ selectedAbonnement: rowData, statutAbn: 'SUSPENDU', visible_dlg_toogle_statut: true });
                    }
                },
                {
                    label: 'Résilier',
                    icon: 'pi pi-times',
                    command: (e) => {
                        this.setState({ selectedAbonnement: rowData, statutAbn: 'RESILIE', visible_dlg_toogle_statut: true });
                    }
                },
            )
        } else {
            items.push(
                {
                    label: 'Activer',
                    icon: 'pi pi-check',
                    command: (e) => {
                        this.setState({ selectedAbonnement: rowData });
                        this.setState({ showindicator: true });
                        rowData.statutAbn = "ACTIF";
                        this.CISService.enreg_abonnement(rowData).then(data => this.resultat_abn(data.code, data.contenu));
                    }
                },
            )
        }
        if (rowData.statutAbn == 'ACTIF') {
            classe='p-button-success';
        }else if (rowData.statutAbn == 'SUSPENDU') {
            classe='p-button-warning';
        }else{
            classe='p-button-danger';
        }
        
        return <SplitButton label="Modifier" icon="pi pi-pencil" className={classe} onClick={(e) => this.edit_abonnement(rowData)} model={items}></SplitButton>
    }

    delete_client() {
        if (this.state.selectedClient == null || this.state.selectedClient == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.CISService.delete_client(this.state.selectedClient).then(data => this.resultat(data.code, data.contenu));
        }
    }

    delete_abonnement() {
        if (this.state.selectedAbonnement == null || this.state.selectedAbonnement == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.CISService.delete_abonnement(this.state.selectedAbonnement).then(data => this.resultat_abn(data.code, data.contenu));
        }
    }

    conf_delete_client(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedClient: data });
            this.setState({ msg: data.idCli });
            this.setState({ visible: true });
            this.setState({ selectedClient: data });
        }
    }

    conf_delete_abonnement(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedAbonnement: data });
            this.setState({ msg: data.idOffre.libelleOffre });
            this.setState({ visible_del_abonnement: true });
        }
    }

    /* Affichage de la boite de dialogue pour l'ajout d'un client */
    toogleDlg() {
        this.annule_client();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedClient == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_client(this.state.selectedClient)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

     /* Exportation du tableau contenant la liste des clients en fichier CSV */
     exportCSV = () => {
        //ref={(el) => { this.dt = el; }}
        this.dt.exportCSV("Liste_client");
    }

    openFileUpload() {
        document.getElementById("hiddenFile").click();
    }

    openFileUploadPiece() {
        document.getElementById("hiddenFilePiece").click();
    }

    openFileUploadDossier() {
        document.getElementById("hiddenFileDossier").click();
    }

    /* Uploader sur le serveur le logo */
    fileUpload(file, namefile) {
        this.CISService.upload_file(file, namefile).then(data => {
            if (data != null && data != undefined && data.code == '200') {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement du fichier sur le serveur effectué avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec upload du fichier sur le serveur' });
            }
        });
    }

    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_client();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="card p-fluid col-12">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visible_del_abonnement} style={{ width: '250px' }} modal={true} footer={<div>
                    <Button icon="pi pi-check" onClick={() => {
                        this.setState({ visible_del_abonnement: false });
                        this.delete_abonnement();
                    }} label="Oui" />
                    <Button icon="pi pi-times" onClick={() => this.setState({ visible_del_abonnement: false })} label="Annuler" className="p-button-secondary" />
                </div>} onHide={() => this.setState({ visible_del_abonnement: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                {/* Tableau contenant la liste des clients */}
                <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_client} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={10}
                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedClient: e.data })} onRowSelect={e => this.setState({ selectedClient: e.data })}
                    responsive={true} selection={this.state.selectedClient} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                    <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '13%' }} />
                    <Column field="nomCli" filter={true} filterMatchMode='contains' header="Nom"  style={{ width: '10%' }} />
                    <Column field="prenomCli" filter={true} filterMatchMode='contains' header="Prenom"  />
                    <Column field="telCli" filter={true} filterMatchMode='contains' header="Téléphone"  style={{ width: '12%' }} />
                    <Column field="idQuartier.libelleQuartier" filter={true} filterMatchMode='contains' header="Quartier"  style={{ width: '15%' }} />
                    <Column field="idCodifCategorie.libelleCodif" filter={true} filterMatchMode='contains' header="Catégorie" style={{ width: '12%' }} />
                    <Column field="idCodifLang.libelleCodif" filter={true} filterMatchMode='contains' header="Langue" style={{ width: '10%' }} />
                </DataTable>

                {/* Boite de dialogue contenant l'historique  */}
                <Dialog visible={this.state.visible_dlg_detail} style={{ width: '70%' }} header='Autres informations' modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_detail: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_detail: false })} />
                    </>}>
                    <div className="p-fluid" style={{ marginTop: -10, height: 400 }}>
                        <TabView >
                            <TabPanel header="Abonnements">
                                <Toolbar className="mb-4" left={(
                                    <React.Fragment>
                                        <div className="my-2">
                                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={e => { this.setState({ visible_dlg_abn: true, modif_abn: false }) }} style={{ width: 115 }} />
                                            <Button label="Supprimer" disabled={this.state.selectedAbonnement == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_abonnement(this.state.selectedAbonnement)} style={{ width: 115 }} />
                                        </div>
                                    </React.Fragment>
                                )
                                } right={
                                    <React.Fragment>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportAbnCSV} />}
                                    </React.Fragment>
                                }></Toolbar>

                                <DataTable value={this.state.liste_abonnement} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={10}
                                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedAbonnement: e.data })} onRowSelect={e => this.setState({ selectedAbonnement: e.data })}
                                    responsive={true} selection={this.state.selectedAbonnement} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                                    <Column header="Action" body={this.actionTemplateAbn.bind(this)} style={{ width: '8%' }} />
                                    <Column field="dateAbn" header='Date' style={{ width: '10%' }} />
                                    <Column field="numAbn" header='Numéro' style={{ width: '10%' }} />
                                    <Column field="statutAbn" header="Statut" style={{ width: '10%' }} />
                                    <Column field="idOffre.libelleOffre" header='Offre' style={{ width: '15%' }} />
                                    <Column field="descAbn" header='Description' />
                                </DataTable>
                            </TabPanel>
                            <TabPanel header="Requêtes">
                                <DataTable value={this.state.liste_req} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={10}
                                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedReq: e.data })} onRowSelect={e => this.setState({ selectedReq: e.data })}
                                    responsive={true} selection={this.state.selectedReq} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                                    <Column header="Action" body={this.actionTemplateReq.bind(this)} style={{ width: '10%' }} />
                                    <Column field="dateReq" header='Date' style={{ width: '12%' }} />
                                    <Column field="idReq" header='Numero' style={{ width: '12%' }} />
                                    <Column field="idNature.libelleNature" header="Statut" />
                                    <Column field="etatReq" header="Etat" style={{ width: '12%' }} />
                                    <Column field="statutReq" header="Statut" style={{ width: '12%' }} />
                                </DataTable>
                            </TabPanel>
                        </TabView>
                    </div>
                </Dialog>

                {/* Boite de dialogue d'ajout et de modification des Communes */}
                <Dialog visible={this.state.visible_dlg_toogle_statut} style={{ width: '450px' }} header={this.state.statutAbn == 'SUSPENDU' ? "Suspension souscription" : "Résiliation souscription"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_toogle_statut: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_toogle_statut: false, motifResilAbn: '' })} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => {
                            this.setState({ showindicator: true });
                            let data = this.state.selectedAbonnement;
                            data.statutAbn = this.state.statutAbn;
                            data.motifResilAbn = this.state.motifResilAbn;
                            this.CISService.enreg_abonnement(data).then(data => this.resultat_abn(data.code, data.contenu));
                        }} style={{ width: 120 }} />
                    </>}>
                    <div className="p-fluid">
                        <div className="field">
                            <label htmlFor="motifResilAbn">Motif *</label>
                            <Dropdown editable={true} options={[
                                { label: 'DEFAUT DE PAIEMENT', value: 'DEFAUT DE PAIEMENT' },
                                { label: 'DEMENAGEMENT', value: 'DEMENAGEMENT' },
                                { label: 'AUTRE', value: 'AUTRE' },
                            ]} id="statutAbn" value={this.state.motifResilAbn} onChange={(e) => this.setState({ motifResilAbn: e.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.statutAbn })} />
                        </div>
                    </div>
                </Dialog>

                {/* Boite de dialogue d'ajout et de modification des Communes */}
                <Dialog visible={this.state.visible_dlg_abn} style={{ width: '450px' }} header={this.state.modif_abn == true ? "Modification souscription" : "Ajout souscription"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_abn: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annule_abonnement()} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_abonnement()} style={{ width: 120 }} />
                    </>}>

                    <div className="p-fluid">
                        <div className="field">
                            <label htmlFor="idOffre">Offre * </label>
                            <Dropdown id="idOffre" options={this.state.liste_offre} value={this.state.idOffre} onChange={(e) => this.setState({ idOffre: e.value })} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.idOffre })} />
                            {this.state.submit && !this.state.idOffre && <small className="p-invalid" style={{ color: 'red' }}>L'offre est obligatoire.</small>}

                        </div>
                        {this.state.modif_abn == true ?
                            <div className="field">
                                <label htmlFor="statutAbn">Statut * </label>
                                <Dropdown options={[
                                    { label: 'ACTIF', value: 'ACTIF' },
                                    { label: 'SUSPENDU', value: 'SUSPENDU' },
                                    { label: 'RESILIE', value: 'RESILIE' },
                                ]} id="statutAbn" value={this.state.statutAbn} onChange={(e) => this.setState({ statutAbn: e.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.statutAbn })} />
                                {this.state.submit && !this.state.statutAbn && <small className="p-invalid" style={{ color: 'red' }}>Le statut est obligatoire.</small>}
                            </div>

                            : null}
                        {this.state.statutAbn !== 'ACTIF' && this.state.modif_abn == true ?
                            <div className="field">
                                <label htmlFor="motifResilAbn">Motif *</label>
                                <Dropdown editable={true} options={[
                                    { label: 'NEAT', value: 'NEAT' },
                                    { label: 'DEFAUT DE PAIEMENT', value: 'DEFAUT DE PAIEMENT' },
                                    { label: 'DEMENAGEMENT', value: 'DEMENAGEMENT' },
                                    { label: 'AUTRE', value: 'AUTRE' },
                                ]} id="motifResilAbn" value={this.state.motifResilAbn} onChange={(e) => this.setState({ motifResilAbn: e.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.motifResilAbn })} />
                            </div> : null}
                        <div className="field">
                            <label htmlFor="libelleCommune">Numéro abonnement *</label>
                            <InputText id="libelleCommune" value={this.state.numAbn} onChange={(e) => this.setState({ numAbn: e.target.value })} />
                        </div>
                        <div className="field">
                            <label htmlFor="descAbn">Commentaire </label>
                            <InputTextarea id="descAbn" rows={2} value={this.state.descAbn} onChange={(e) => this.setState({ descAbn: e.target.value })} />
                        </div>
                    </div>
                </Dialog>

                {/* Boite de dialogue d'ajout et de modification d'un abonné  */}
                <Dialog visible={this.state.visible_dlg_form} style={{ width: '90%' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annule_client()} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_client()} style={{ width: 120 }} />
                    </>}>
                    <div className="p-fluid">
                        <div className="grid">
                            <div className="col-12 md:col-10">
                                <div style={{ backgroundColor: '#f3f7fb', height: 320, padding: 20, border: '4px solid #f5f5f5' }}>

                                    <div className="formgrid grid" >

                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <InputText id="nomCli" value={this.state.nomCli} onChange={(e) => this.setState({ nomCli: e.target.value })} required autoFocus />
                                                <label htmlFor="nomCli">Nom *</label>
                                            </span>
                                            {this.state.submit && !this.state.nomCli && <small className="p-invalid" style={{ color: 'red' }}>Le nom est obligatoire</small>}
                                        </div>
                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <InputText id="prenomCli" value={this.state.prenomCli} onChange={(e) => this.setState({ prenomCli: e.target.value })} required />
                                                <label htmlFor="prenomCli">Prénom *</label>
                                            </span>
                                            {this.state.submit && !this.state.prenomCli && <small className="p-invalid" style={{ color: 'red' }}>Le prénom est obligatoire. </small>}
                                        </div>
                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <InputMask mask="999999999" id="telCli" value={this.state.telCli} onChange={(e) => this.setState({ telCli: e.target.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.telCli })} />
                                                <label htmlFor="telCli">N° téléphone *</label>
                                            </span>
                                            {this.state.submit && !this.state.telCli && <small className="p-invalid" style={{ color: 'red' }}>La numéro de téléphone est obligatoire.  </small>}
                                            {this.state.checkPhone == false && <small className="p-invalid" style={{ color: 'red' }}>Ce numéro de téléphone existe déja.</small>}
                                        </div>
                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <InputMask mask="99/99/9999" id="dateNaissCli" value={this.state.dateNaissCli} onChange={(e) => this.setState({ dateNaissCli: e.target.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.dateNaissCli })} />
                                                <label htmlFor="dateNaissCli">Date naissance  *</label>
                                            </span>
                                            {this.state.submit && !this.state.dateNaissCli && <small className="p-invalid" style={{ color: 'red' }}>La date de naissance est obligatoire.  </small>}
                                        </div>
                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <InputText id="lieuNaissCli" value={this.state.lieuNaissCli} onChange={(e) => this.setState({ lieuNaissCli: e.target.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.lieuNaissCli })} />
                                                <label htmlFor="lieuNaissCli">Lieu naissance *</label>
                                            </span>
                                            {this.state.submit && !this.state.lieuNaissCli && <small className="p-invalid" style={{ color: 'red' }}>Le lieu de naissance est obligatoire.</small>}
                                        </div>
                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <Dropdown options={[
                                                    { label: 'Masculin', value: 'Masculin' },
                                                    { label: 'Feminin', value: 'Feminin' },
                                                ]} id="sexeCli" value={this.state.sexeCli} onChange={(e) => this.setState({ sexeCli: e.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.sexeCli })} />
                                                <label htmlFor="sexeCli">Sexe *</label>
                                            </span>
                                            {this.state.submit && !this.state.sexeCli && <small className="p-invalid" style={{ color: 'red' }}>Le genre est obligatoire </small>}
                                        </div>
                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <Dropdown id="idCodifCategorie" options={this.state.liste_categorie} value={this.state.idCodifCategorie} onChange={(e) => this.setState({ idCodifCategorie: e.value })} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.idCodifCategorie })} />
                                                <label htmlFor="idCodifCategorie">Catégorie * </label>
                                            </span>
                                            {this.state.submit && !this.state.idCodifCategorie && <small className="p-invalid" style={{ color: 'red' }}>La catégorie est obligatoire.</small>}
                                        </div>
                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <InputText id="emailCli" value={this.state.emailCli} onChange={(e) => this.setState({ emailCli: e.target.value })} />
                                                <label htmlFor="emailCli">Email </label>
                                            </span>
                                        </div>
                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <Dropdown id="typeCli" options={[
                                                    { label: 'PARTICULIER', value: 'PARTICULIER' },
                                                    { label: 'ORGANISATION', value: 'ORGANISATION' },
                                                ]} value={this.state.typeCli} onChange={(e) => this.setState({ typeCli: e.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.typeCli })} />
                                                <label htmlFor="typeCli">Type client *</label>
                                            </span>
                                            {this.state.submit && !this.state.typeCli && <small className="p-invalid" style={{ color: 'red' }}>Le type de client est obligatoire .</small>}
                                        </div>
                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <Dropdown id="idCodifLang" options={this.state.liste_langue} value={this.state.idCodifLang} onChange={(e) => this.setState({ idCodifLang: e.target.value })} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.idCodifLang })} />
                                                <label htmlFor="idCodifLang">Langue *</label>
                                            </span>
                                            {this.state.submit && !this.state.idCodifLang && <small className="p-invalid" style={{ color: 'red' }}>La langue est obligatoire.</small>}
                                        </div>
                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <Dropdown id="idQuart" options={this.state.liste_quartier} value={this.state.idQuartier} onChange={(e) => this.setState({ idQuartier: e.target.value })} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.idQuartier })} />
                                                <label htmlFor="idQuart">Quartier *</label>
                                            </span>
                                            {this.state.submit && !this.state.idQuartier && <small className="p-invalid" style={{ color: 'red' }}>Le quartier est obligatoire.</small>}
                                        </div>
                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <InputText id="adrCli" value={this.state.adrCli} onChange={(e) => this.setState({ adrCli: e.target.value })} />
                                                <label htmlFor="adrCli">Adresse </label>
                                            </span>
                                        </div>
                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <Dropdown id="idQuart" options={this.state.liste_type_piece} value={this.state.idCodifTypePiece} onChange={(e) => this.setState({ idCodifTypePiece: e.target.value })} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.idCodifTypePiece })} />
                                                <label htmlFor="idQuart">Type pièce *</label>
                                            </span>
                                            {this.state.submit && !this.state.idCodifTypePiece && <small className="p-invalid" style={{ color: 'red' }}>Le type de pièce est obligatoire.</small>}
                                        </div>
                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <InputText id="numPiCli" value={this.state.numPiCli} onChange={(e) => this.setState({ numPiCli: e.target.value })} className={classNames({ 'p-invalid': this.state.submit && !this.state.numPiCli })} />
                                                <label htmlFor="numPiCli">N° pièce ID </label>
                                            </span>
                                            {this.state.submit && !this.state.numPiCli && <small className="p-invalid" style={{ color: 'red' }}>Le numéro de pièce est obligatoire.</small>}
                                        </div>
                                       
                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <MultiSelect value={this.state.liste_selected_offre} options={this.state.liste_offre} onChange={(e) => this.setState({ liste_selected_offre: e.value })} optionLabel="label" />
                                                <label htmlFor="adrCli">Offre </label>
                                            </span>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="col-12 md:col-2">
                                <center>
                                    <div className='p-fluid' >
                                        <div className="field" style={{ marginBottom: -10 }}>
                                            <a onClick={() => this.openFileUpload()} style={{ cursor: 'pointer' }}>
                                                <div style={{ height: 100, width: 95, borderRadius: 10 }} >
                                                    <center>
                                                        {this.state.urlfile == null ?
                                                            <div style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>PHOTO</label></div> :
                                                            <img src={this.state.urlfile} style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb' }} />}
                                                    </center>
                                                </div>
                                            </a>
                                            <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                                                if (e.target.files && e.target.files[0]) {
                                                    this.setState({ file: e.target.files[0] }, () => {
                                                        let url = URL.createObjectURL(this.state.file);
                                                        this.setState({ urlfile: url });
                                                    })
                                                }
                                            }} />
                                        </div>

                                        <div className="field" style={{ marginBottom: -10 }}>
                                            <a onClick={() => this.openFileUploadPiece()} style={{ cursor: 'pointer' }}>
                                                <div style={{ height: 100, width: 95, borderRadius: 10 }} >
                                                    <center>
                                                        {this.state.urlfilePI == null ?
                                                            <div style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>PIECE</label></div> :
                                                            <img id="footer-logo" src="assets/layout/images/id.png" alt="diamond-layout" style={{ width: '120px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb' }} />
                                                        }
                                                    </center>
                                                </div>
                                            </a>
                                            <input type="file" id="hiddenFilePiece" style={{ visibility: 'hidden' }} onChange={(e) => {
                                                if (e.target.files && e.target.files[0]) {
                                                    this.setState({ filePI: e.target.files[0], urlfilePI: 'OK' })
                                                }
                                            }} />
                                        </div>

                                        <div className="field" style={{ marginBottom: -10 }}>
                                            <a onClick={() => this.openFileUploadDossier()} style={{ cursor: 'pointer' }}>
                                                <div style={{ height: 100, width: 95, borderRadius: 10 }} >
                                                    <center>
                                                        {this.state.urlfileDossier == null ?
                                                            <div style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>DOSSIER</label></div> :
                                                            <img id="footer-logo" src="assets/layout/images/doc.png" alt="diamond-layout" style={{ width: '120px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb' }} />
                                                        }
                                                    </center>
                                                </div>
                                            </a>
                                            <input type="file" id="hiddenFileDossier" style={{ visibility: 'hidden' }} onChange={(e) => {
                                                if (e.target.files && e.target.files[0]) {
                                                    this.setState({ fileDossier: e.target.files[0], urlfileDossier: 'OK' })
                                                }
                                            }} />
                                        </div>

                                    </div>
                                </center>
                            </div>
                        </div>


                    </div>
                </Dialog>
            </div>
        );
    }
}