import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';

const AppSearch = (props) => {
 
    let searchInputEl = null; 
    const [value, setValue] = useState('');

    const onInputKeydown = (event) => {
        const key = event.which;

        //escape, tab and enter
        if (key === 27 || key === 9 || key === 13) {
            window.location = "#/requete/new/"+value;
            props.onSearchHide(event);
        }

       
    };

    const onEnter = () => {
        if (searchInputEl) {
            searchInputEl.focus();
        }
    };

    return (
        <div className="layout-search">
            <CSSTransition classNames="search-container" timeout={{ enter: 400, exit: 400 }} in={props.searchActive} unmountOnExit onEnter={onEnter}>
                <div className="search-container" onClick={props.onSearchClick}>
                    <i className="pi pi-search"></i>
                    <InputText  value={value} onChange={(e) => setValue(e.target.value)} ref={(el) => searchInputEl = ReactDOM.findDOMNode(el)} type="text" name="search" placeholder="Search" onKeyDown={onInputKeydown} />
                </div>
            </CSSTransition>
        </div>
    );
}

export default AppSearch;
