import React, { Component } from 'react';
import CISService from '../service/CISService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { ProgressSpinner } from 'primereact/progressspinner';
import { classNames } from 'primereact/utils';
import { Toolbar } from 'primereact/toolbar';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';

export class FormOffre extends Component {
    constructor() {
        super();
        this.state = {
            liste_Offre: [],
            selectedOffre: null,
            fileDossier: null,
            urlfileDossier: null,
            idOffre: '',
            libelleOffre: '',
            docOffre: '',
            codeOffre: '',
            tarifOffre: 0,
            descriptionOffre: '',
            liste_actif: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' }
            ],
            ////////////////////////////
            modif: false, // pour vérifier s'il s'agit d'une modification ou un ajout
            visible: false, // pour afficher la boite de confirmation de suppression
            visible_dlg_form: false, // pour afficher la boite de dialogue du formation de saisie
            showindicator: false, // pour afficher l'état de chargement
            submit: false // pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            ////////////////////////////
        };
        this.edit_offre = this.edit_offre.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.conf_delete_offre = this.conf_delete_offre.bind(this);
        this.CISService = new CISService();

    }

    componentDidMount() {
        this.get_liste_offre();

    }

    get_liste_offre() {
        this.CISService.get_liste_offre().then(data => this.setState({ liste_Offre: data }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_offre();
            this.get_liste_offre();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
        this.setState({ submit: false });
    }

    edit_offre(data) {
        if (data != null && data != undefined) {
            this.setState({ libelleOffre: data.libelleOffre });
            this.setState({ codeOffre: data.codeOffre });
            this.setState({ tarifOffre: data.tarifOffre });
            this.setState({ descriptionOffre: data.descriptionOffre });
            this.setState({ docOffre: data.docOffre });
            this.setState({ modif: true });
            this.setState({ selectedOffre: data });
            if (data.docOffre !== undefined && data.docOffre !== null && data.docOffre !== '') {
                this.setState({ urlfileDossier: 'OK' })
            } else {
                this.setState({ urlfileDossier: null })
            }
            this.setState({ visible_dlg_form: true });
        }
    }

    annule_offre() {
        this.setState({ libelleOffre: '' });
        this.setState({ codeOffre: '' });
        this.setState({ tarifOffre: '' });
        this.setState({
            descriptionOffre: '',
            docOffre: '',
            fileDossier: null,
            urlfileDossier: null
        });
        this.setState({ visible_dlg_form: false });
    }


    enreg_offre() {
        this.setState({ submit: true });
        if (this.state.libelleOffre == '') {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let uploadDossier = false;
            let nomficDossier = this.state.docOffre;
            if (this.state.fileDossier != null && this.state.fileDossier != undefined) {
                let name = this.state.fileDossier.name;
                nomficDossier = 'DO_' + this.CISService.get_userconnected().idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                uploadDossier = true;
            }
            if (this.state.modif == false) {
                let form = {
                    idOffre: 0,
                    libelleOffre: this.state.libelleOffre,
                    codeOffre: this.state.codeOffre,
                    tarifOffre: this.state.tarifOffre,
                    docOffre: nomficDossier,
                    descriptionOffre: this.state.descriptionOffre
                }
                this.CISService.enreg_offre(form).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        if (reponse.code == 200) {
                            if (uploadDossier === true) {
                                this.fileUpload(this.state.fileDossier, nomficDossier);
                            }
                        }
                        this.resultat(reponse.code, reponse.contenu);
                    }
                });
            } else {
                let form = this.state.selectedOffre;
                form.libelleOffre = this.state.libelleOffre;
                form.codeOffre = this.state.codeOffre;
                form.tarifOffre = this.state.tarifOffre;
                form.docOffre = nomficDossier;
                form.descriptionOffre = this.state.descriptionOffre;
                this.CISService.enreg_offre(form).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        if (reponse.code == 200) {
                            if (uploadDossier === true) {
                                this.fileUpload(this.state.fileDossier, nomficDossier);
                            }
                        }
                        this.resultat(reponse.code, reponse.contenu);
                    }
                });
            }
        }
    }

    actionTemplate(rowData) {
        return <div className="grid" >
            <div className="col"><Button onClick={() => this.edit_offre(rowData)} style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button disabled={rowData.docOffre==null || rowData.docOffre==undefined || rowData.docOffre==''} onClick={() => {
                 this.setState({ selectedOffre: rowData });
                 this.CISService.download_fichier_joint(rowData.docOffre);
            }} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -17 }} icon="pi pi-download" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_offre(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -17 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_offre() {
        if (this.state.selectedOffre == null || this.state.selectedOffre == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.CISService.delete_offre(this.state.selectedOffre).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_offre(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedOffre: data });
            this.setState({ msg: data.libelleOffre });
            this.setState({ visible: true });
        }
    }


    /* Affichage de la boite de dialogue pour l'ajout Codification */
    toogleDlg() {
        this.annule_offre();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedOffre == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_offre(this.state.selectedOffre)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )
    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }
    
     /* Exportation du tableau contenant la liste des offres en fichier CSV */
     exportCSV = () => {
        //ref={(el) => { this.dt = el; }}
        this.dt.exportCSV("Liste_offre");
    }

    openFileUploadDossier() {
        document.getElementById("hiddenFileDossier").click();
    }

    /* Uploader sur le serveur le logo */
    fileUpload(file, namefile) {
        this.CISService.upload_file(file, namefile).then(data => {
            if (data != null && data != undefined && data.code == '200') {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement du fichier sur le serveur effectué avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec upload du fichier sur le serveur' });
            }
        });
    }


    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_offre();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="card p-fluid col-12" style={{ height: 800 }}>
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_Offre} paginatorPosition="top" selectionMode="single" paginator={true} rows={100}
                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedOffre: e.data })} onRowSelect={e => this.setState({ selectedOffre: e.data })}
                    responsive={true} selection={this.state.selectedOffre} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                    <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '12%' }} />
                    <Column field="libelleOffre" filter={true} filterMatchMode='contains' header="Libelle" sortable={true} style={{ width: '22%' }}/>
                    <Column field="codeOffre" filter={true} filterMatchMode='contains' header="Code" sortable={true} style={{ width: '15%' }} />
                    <Column field="tarifOffre" body={(rowData, column) => {
                        return <div>
                            <span >{this.CISService.formaterValueSep(rowData.tarifOffre)}</span>
                        </div>
                    }} filter={true} filterMatchMode='contains' header="Tarif" sortable={true} style={{ width: '15%' }} />
                    <Column field="descriptionOffre" filter={true} filterMatchMode='contains' header="Description" sortable={true}  />
                </DataTable>


                {/* Boite de dialogue d'ajout et de modification des Communes */}
                <Dialog visible={this.state.visible_dlg_form} style={{ width: '450px' }} header={this.state.modif == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annule_offre()} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_offre()} style={{ width: 120 }} />
                    </>}>
                    <div className="formgrid grid">
                        <div className="field col-12">
                            <label htmlFor="libelleCommune">Désignation *</label>
                            <InputText id="libelleCommune" value={this.state.libelleOffre} onChange={(e) => this.setState({ libelleOffre: e.target.value })} required autoFocus className={classNames({ 'p-invalid': this.state.submit && !this.state.libelleOffre })} />
                            {this.state.submit && !this.state.libelleOffre && <small className="p-invalid" style={{ color: 'red' }}>La désignation est obligatoire * </small>}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="libelleCommune">Code </label>
                            <InputText id="libelleCommune" value={this.state.codeOffre} onChange={(e) => this.setState({ codeOffre: e.target.value })} />
                        </div>
                        <div className="field col-6">
                            <label htmlFor="libelleCommune">Tarif </label>
                            <InputNumber id="libelleCommune" value={this.state.tarifOffre} onChange={(e) => this.setState({ tarifOffre: e.value })} />
                        </div>
                        <div className="field col-8">
                            <label htmlFor="libelleCommune">Description </label>
                            <InputTextarea id="libelleCommune" rows={4} value={this.state.descriptionOffre} onChange={(e) => this.setState({ descriptionOffre: e.target.value })} />
                        </div>
                        <div className="field col-4">
                            <div className="col-12 md:col-9">
                                <a onClick={() => this.openFileUploadDossier()} style={{ cursor: 'pointer' }}>
                                    <div style={{ height: 120, width: 100, borderRadius: 10 }} >
                                        <center>
                                            {this.state.urlfileDossier == null ?
                                                <div style={{ width: '100px', height: '120px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>BROCHURE</label></div> :
                                                <img id="footer-logo" src="assets/layout/images/doc.png" alt="diamond-layout" style={{ width: '100px', height: '120px', borderRadius: 10, backgroundColor: '#f3f7fb' }} />
                                            }
                                        </center>
                                    </div>
                                </a>
                                <input type="file" id="hiddenFileDossier" style={{ visibility: 'hidden' }} onChange={(e) => {
                                    if (e.target.files && e.target.files[0]) {
                                        this.setState({ fileDossier: e.target.files[0], urlfileDossier: 'OK' })
                                    }
                                }} />
                            </div>
                        </div>

                    </div>
                </Dialog>

            </div>
        );
    }
}