import Numeral from 'numeral';
export default class CISService {

    constructor() {
        this.state = {
            //wsurl: 'http://localhost:8787/cis',
            wsurl: 'https://opt-api224.com/ws_cis/cis',
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
    }

    getToken() {
        let token = '';
        if (this.get_userconnected() !== null && this.get_userconnected() !== undefined) {
            token = this.get_userconnected().token;
        }
        return token;
    }

    getSelectedData(liste, id) {
        return liste.filter(function (el) {
            return el.value === id;
        })[0];
    }

    wsurl() {
        return this.state.wsurl;
    }

    get_profil() {
        return this.state.userconnected.idCodifGrp.libelleCodif;
    }

    get_type_compte() {
        return this.state.userconnected.idCodifGrp.descriptionCodif;
    }

    get_userconnected() {
        return this.state.userconnected;
    }

    get_etablissement() {
        return this.state.userconnected.matrPers.idStruct.idEtabl;
    }

    //Permet de mettre des séparateur pour les montants
    formaterValueSep(value) {
        return Numeral(parseFloat(value)).format('0,0');
    }

    // Affectation des variables de state
    onInputChange = (e, name, typeInput, data) => {
        const val = (e.target && e.target.value || e.value) || '';
        if (typeInput == 'fk') {
            data[`${name}`]['id'] = val;
        } else {
            data[`${name}`] = val;
        }
        return data;
    }


    // Tableau de bord
    get_dashboard(pidStruct) {
        var lien = this.wsurl() + '/etats/get_dashboard?pidStruct=' + pidStruct;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_stat_req_profil(pd1, pd2, pcategorieCli) {
        var lien = this.wsurl() + '/etats/get_liste_stat_req_profil?pd1=' + pd1 + '&pd2=' + pd2 + '&pcategorieCli=' + pcategorieCli;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_stat_req_site(pd1, pd2, pcategorieCli, pProfil, pidMotif, pidNature) {
        var lien = this.wsurl() + '/etats/get_liste_stat_req_site?pd1=' + pd1 + '&pd2=' + pd2 + '&pcategorieCli=' + pcategorieCli + '&pProfil=' + pProfil + '&pidMotif=' + pidMotif + '&pidNature=' + pidNature;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_stat_req_nature(pd1, pd2, pidStruct, pcategorieCli, pProfil, pidMotif) {
        var lien = this.wsurl() + '/etats/get_liste_stat_req_nature?pd1=' + pd1 + '&pd2=' + pd2 + '&pcategorieCli=' + pcategorieCli + '&pidStruct=' + pidStruct + '&pProfil=' + pProfil + '&pidMotif=' + pidMotif;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_stat_req_motif(pd1, pd2, pidStruct, pcategorieCli, pProfil) {
        var lien = this.wsurl() + '/etats/get_liste_stat_req_motif?pd1=' + pd1 + '&pd2=' + pd2 + '&pcategorieCli=' + pcategorieCli + '&pidStruct=' + pidStruct + '&pProfil=' + pProfil;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_liste_stat_req_periode(pd1, pd2, pidStruct, pcategorieCli, pProfil, pidMotif, pidNature) {
        var lien = this.wsurl() + '/etats/get_liste_stat_req_periode?pd1=' + pd1 + '&pd2=' + pd2 + '&pcategorieCli=' + pcategorieCli + '&pidStruct=' + pidStruct + '&pProfil=' + pProfil + '&pidNature=' + pidNature + '&pidMotif=' + pidMotif;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_liste_stat_top_nature(pd1, pd2, pidStruct, petatReq, pstatutReq, pcategorieCli, pProfil, pTop, pidMotif) {
        var lien = this.wsurl() + '/etats/get_liste_stat_top_nature?pd1=' + pd1 + '&pd2=' + pd2 + '&petatReq=' + petatReq + '&pstatutReq=' + pstatutReq + '&pcategorieCli=' + pcategorieCli + '&pidStruct=' + pidStruct + '&pProfil=' + pProfil + '&pTop=' + pTop + '&pidMotif=' + pidMotif;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_stat_top_motif(pd1, pd2, pidStruct, petatReq, pstatutReq, pcategorieCli, pProfil, pTop) {
        var lien = this.wsurl() + '/etats/get_liste_stat_top_motif?pd1=' + pd1 + '&pd2=' + pd2 + '&petatReq=' + petatReq + '&pstatutReq=' + pstatutReq + '&pcategorieCli=' + pcategorieCli + '&pidStruct=' + pidStruct + '&pProfil=' + pProfil + '&pTop=' + pTop;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    // Web service Signalisation

    enreg_signalisation(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idCand == "0") {
            lien = this.wsurl() + '/signalisation/add';
        } else {
            lien = this.wsurl() + '/signalisation/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_signalisation() {
        var lien = this.wsurl() + '/signalisation/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_signalisation(data) {
        var lien = this.wsurl() + '/signalisation/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    // Web service Utilisateur

    enreg_utilisateur(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idUser == "0") {
            lien = this.wsurl() + '/user/add';
        } else {
            lien = this.wsurl() + '/user/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_utilisateur() {
        var lien = this.wsurl() + '/user/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_utilisateur_groupe(idGrp) {
        var lien = this.wsurl() + '/user/get_users_by_groupe?idGrp=' + idGrp;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_identification(loginUser, pwdUser) {
        var lien = this.wsurl() + '/get_identification';
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify({ userName: loginUser, password: pwdUser })
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_utilisateur(data) {
        var lien = this.wsurl() + '/user/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    download_fichier_joint(namefile) {
        var lien = this.wsurl() + '/utils/download_attachment?namefile=' + namefile + '&iduser=' + this.get_userconnected().idUser;
        window.location = lien;
    }

    download_photo_utilisateur(namefile) {
        var lien = this.wsurl() + '/user/download_photo?namefile=' + namefile + '&iduser=' + this.get_userconnected().idUser;
        return lien
    }

    download_file(namefile) {
        var lien = this.wsurl() + '/utils/download_file?namefile=' + namefile + '&iduser=' + this.get_userconnected().idUser;
        return lien
    }

    upload_file(file, namefile) {
        var methode = 'POST';
        var lien = this.wsurl() + '/utils/upload_file';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('namefile', namefile);
        formData.append('iduser', this.get_userconnected().idUser);
        return fetch(lien, {
            method: methode,
            headers: {
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    upload_photo_utilisateur(file, namefile, destination) {
        var lien = this.wsurl() + '/user/upload_photo/';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('namefile', namefile);
        formData.append('destination', destination);
        //   console.log(formData);
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: formData
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    // Web service Examen

    enreg_examen(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idExam == "0") {
            lien = this.wsurl() + '/examen/add';
        } else {
            lien = this.wsurl() + '/examen/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_examen() {
        var lien = this.wsurl() + '/examen/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_by_examen(date1, date2, rlibExam, ridDos) {
        if (rlibExam == '') {
            rlibExam = 'Tous';
        }
        if (ridDos == '') {
            ridDos = 'Tous';
        }
        var lien = this.wsurl() + '/examen/get_by_examen?pd1=' + date1 + '&pd2=' + date2 + '&rlibExam=' + rlibExam + '&ridDos=' + ridDos;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_examen(data) {
        var lien = this.wsurl() + '/examen/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Abonnement

    enreg_abonnement(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idAbn == "0") {
            lien = this.wsurl() + '/abonnement/add';
        } else {
            lien = this.wsurl() + '/abonnement/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_abonnement() {
        var lien = this.wsurl() + '/abonnement/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_abonnement_client(idCli) {
        var lien = this.wsurl() + '/abonnement/get_abonnements_client?idCli=' + idCli;
        ; return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_abonnement(data) {
        var lien = this.wsurl() + '/abonnement/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Access_Media
    enreg_access_media(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id_acmedia == "0") {
            lien = this.wsurl() + '/accessmedia/add';
        } else {
            lien = this.wsurl() + '/accessmedia/update';
            methode = 'PUT';
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_access_media() {
        var lien = this.wsurl() + '/accessmedia/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_access_media(data) {
        var lien = this.wsurl() + '/accessmedia/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Clients
    enreg_client(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idCli == "0") {
            data.idStruct = this.state.userconnected.matrPers.idStruct;
            lien = this.wsurl() + '/client/add';
        } else {
            lien = this.wsurl() + '/client/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_client() {
        var lien = this.wsurl() + '/client/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    getClientbyPhone(telCli) {
        var lien = this.wsurl() + '/client/get_client_by_phone?telCli=' + telCli;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    delete_client(data) {
        var lien = this.wsurl() + '/client/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Codification
    enreg_codification(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idCodif == "0") {
            lien = this.wsurl() + '/codif/add';
        } else {
            lien = this.wsurl() + '/codif/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_codification(paramCodif) {
        var lien = this.wsurl() + '/codif/get_codif_by_param?paramCodif=' + paramCodif;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_codification(data) {
        var lien = this.wsurl() + '/codif/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Commune
    enreg_commune(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idCommune == "0") {
            lien = this.wsurl() + '/commune/add';
        } else {
            lien = this.wsurl() + '/commune/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_commune() {
        var lien = this.wsurl() + '/commune/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_commune(data) {
        var lien = this.wsurl() + '/commune/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Detail_Offre
    enreg_detail_offre(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id_detoffre == "0") {
            lien = this.wsurl() + '/detailoffre/add';
        } else {
            lien = this.wsurl() + '/detailoffre/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_detail_offre() {
        var lien = this.wsurl() + '/detailoffre/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_detail_offre(data) {
        var lien = this.wsurl() + '/detailoffre/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Droit_Traitement
    enreg_Droit_Traitement(data) {
        var lien = this.wsurl() + '/droittrait/add_bloc';
        let methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_droit_traitement(idGrp) {
        var lien = this.wsurl() + '/droittrait/get_liste_by_grp?idGrp=' + idGrp;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_Droit_Traitement(data) {
        var lien = this.wsurl() + '/droittrait/delete_bloc';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Etablissement
    register(data) {
        var lien = this.wsurl() + '/register';
        let methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Etablissement
    enreg_etablissement(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idEtabl == "0") {
            lien = this.wsurl() + '/etabl/add';
        } else {
            lien = this.wsurl() + '/etabl/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Etablissement
    toogle_etablissement(data) {
        var lien = this.wsurl() + '/etabl/toogle';
        let methode = 'PUT';
        data.iduser = this.get_userconnected().idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_etablissement() {
        var lien = this.wsurl() + '/etabl/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_etablissement(data) {
        var lien = this.wsurl() + '/etabl/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Gerer_Structure
    enreg_gerer_structure(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id_gstruct == "0") {
            lien = this.wsurl() + '/gererstruct/add';
        } else {
            lien = this.wsurl() + '/gererstruct/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_gerer_structure() {
        var lien = this.wsurl() + '/gererstruct/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_structure(data) {
        var lien = this.wsurl() + '/gererstruct/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Item
    enreg_item(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id_gstruct == "0") {
            lien = this.wsurl() + '';
        } else {
            lien = this.wsurl() + '';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_item() {
        var lien = this.wsurl() + '';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_item(data) {
        var lien = this.wsurl() + '';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Media
    enreg_media(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id_media == "0") {
            lien = this.wsurl() + '/media/add';
        } else {
            lien = this.wsurl() + '/media/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_media() {
        var lien = this.wsurl() + '/media/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_media(data) {
        var lien = this.wsurl() + '/media/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Motif
    enreg_motif(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idMotif == "0") {
            lien = this.wsurl() + '/motif/add';
        } else {
            lien = this.wsurl() + '/motif/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_motif() {
        var lien = this.wsurl() + '/motif/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_motif(data) {
        var lien = this.wsurl() + '/motif/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Nature
    enreg_nature(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idNature == "0") {
            lien = this.wsurl() + '/nature/add';
        } else {
            lien = this.wsurl() + '/nature/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_nature(idMotif) {
        var lien = this.wsurl() + '/nature/get_liste_by_motif?idMotif=' + idMotif;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_by_motif_grp(idGrp, idMotif) {
        var lien = this.wsurl() + '/nature/get_liste_by_motif_grp?idMotif=' + idMotif + '&idGrp=' + idGrp;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_nature(data) {
        var lien = this.wsurl() + '/nature/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Notification
    enreg_notification(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id_notif == "0") {
            lien = this.wsurl() + '/notif/add';
        } else {
            lien = this.wsurl() + '/notif/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_notification() {
        var lien = this.wsurl() + '/notif/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_notification(data) {
        var lien = this.wsurl() + '/notif/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Offre
    enreg_offre(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idOffre == 0) {
            lien = this.wsurl() + '/offre/add';
        } else {
            lien = this.wsurl() + '/offre/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_offre() {
        var lien = this.wsurl() + '/offre/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_offre(data) {
        var lien = this.wsurl() + '/offre/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Personnel
    enreg_personnel(data) {
        var lien = "";
        let methode = 'POST';
        if (data.matrPers == "0") {
            lien = this.wsurl() + '/personnel/add';
        } else {
            lien = this.wsurl() + '/personnel/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_personnel() {
        var lien = this.wsurl() + '/personnel/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_personnel(data) {
        var lien = this.wsurl() + '/personnel/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Planning
    enreg_planning(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id_plan == "0") {
            lien = this.wsurl() + '/planning/add';
        } else {
            lien = this.wsurl() + '/planning/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_planning() {
        var lien = this.wsurl() + '/planning/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_planning(data) {
        var lien = this.wsurl() + '/planning/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Quartier
    enreg_quartier(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idQuartier == "0") {
            lien = this.wsurl() + '/quartier/add';
        } else {
            lien = this.wsurl() + '/quartier/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_quartier() {
        var lien = this.wsurl() + '/quartier/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_quartier(data) {
        var lien = this.wsurl() + '/quartier/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Requete
    enreg_requete(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idReq == "0") {
            lien = this.wsurl() + '/requete/add';
        } else {
            lien = this.wsurl() + '/requete/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_requete(pd1, pd2, pidCli, pidMotif, pidNature, petatReq, pstatutReq, pcategorie) {
        var lien = this.wsurl() + '/requete/get_by_criteria?pd1=' + pd1 + '&pd2=' + pd2 + '&pidCli=' + pidCli + '&pidMotif=' + pidMotif + '&pidNature=' + pidNature + '&petatReq=' + petatReq + '&pstatutReq=' + pstatutReq + '&pcategorie=' + pcategorie;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    get_liste_requetes_client(idCli) {
        var lien = this.wsurl() + '/requete/get_requetes_client?idCli=' + idCli;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_requete(data) {
        var lien = this.wsurl() + '/requete/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Region
    enreg_region(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idRegion == "0") {
            lien = this.wsurl() + '/region/add';
        } else {
            lien = this.wsurl() + '/region/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_region() {
        var lien = this.wsurl() + '/region/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_region(data) {
        var lien = this.wsurl() + '/region/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Structure
    enreg_structure(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idStruct == "0") {
            lien = this.wsurl() + '/structure/add';
        } else {
            lien = this.wsurl() + '/structure/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_structure() {
        var lien = this.wsurl() + '/structure/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_structure(data) {
        var lien = this.wsurl() + '/structure/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Traitement
    enreg_traitement(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idTrt == "0") {
            lien = this.wsurl() + '/traitement/add';
        } else {
            lien = this.wsurl() + '/traitement/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_traitement(pd1, pd2, pidCli, pidMotif, pidNature, petatReq, pstatutReq, pcategorie) {
        var lien = this.wsurl() + '/traitement/get_by_criteria?pd1=' + pd1 + '&pd2=' + pd2 + '&pidCli=' + pidCli + '&pidMotif=' + pidMotif + '&pidNature=' + pidNature + '&petatReq=' + petatReq + '&pstatutReq=' + pstatutReq + '&pcategorie=' + pcategorie;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_traitements_req(idReq) {
        var lien = this.wsurl() + '/traitement/get_traitements_req?idReq=' + idReq;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_traitement(data) {
        var lien = this.wsurl() + '/traitement/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Ville
    enreg_ville(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idVille == "0") {
            lien = this.wsurl() + '/ville/add';
        } else {
            lien = this.wsurl() + '/ville/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().idUser;
        data.idEtabl = this.get_etablissement().idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_ville() {
        var lien = this.wsurl() + '/ville/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_ville(data) {
        var lien = this.wsurl() + '/ville/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


}