import React, { Component } from 'react';
import CISService from '../service/CISService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { SplitButton } from 'primereact/splitbutton';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Badge } from 'primereact/badge';

export class EditionRequete extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            liste_motif: [],
            liste_nature: [],
            liste_structure: [],
            liste_categorie: [],
            liste_item_export: [
                {
                    label: 'Exporter en CVS',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.exportCSV();
                    }
                },
                {
                    label: 'Exporter en XLS',
                    icon: 'pi pi-file-excel',
                    command: (e) => {
                        this.exportExcel();
                    }
                },
            ],
            idReq: 0,
            pd1: new Date(),
            pd2: new Date(),
            rtelCli: '',
            ridStruct: 'Tous',
            rCategorie: 'Tous',
            rtypeCli: 'Tous',
            retatReq: 'Tous',
            rstatutReq: 'Tous',
            ridCli: 'Tous',
            ridMotif: 'Tous',
            ridNature: 'Tous',
        };
        this.CISService = new CISService();
    }

    componentDidMount() {
        this.get_liste_codification();
        this.get_liste_motif();
        this.get_liste_data();
    }

    get_liste_data() {
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        this.CISService.get_liste_requete(spd1, spd2, this.state.ridCli, this.state.ridMotif, this.state.ridNature, this.state.retatReq, this.state.rstatutReq, this.state.rCategorie).then(data => this.setState({ liste_data: data, showindicator: false }));
    }

    //Liste des motifs
    get_liste_motif() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_motif().then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idMotif, label: x.descriptionMotif }
                });
                this.setState({ liste_motif: liste });
            }
        });
    }

    get_liste_nature(idMotif) {
        this.CISService.get_liste_nature(idMotif).then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idNature, label: x.descriptionNature }
                });
                this.setState({ liste_nature: liste });
            }
        });
    }

    //Liste des Codifications 
    get_liste_codification() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_codification('CATEGORIE_CLIENT').then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.descriptionCodif }
                });
                this.setState({ liste_categorie: liste });
            }
        });
    }

    /* Exportation du tableau contenant la liste des requetes en fichier CSV */
    exportCSV = () => {
        //ref={(el) => { this.dt = el; }}
        this.dt.exportCSV("JOURNAL_REQUETE_");
    }

    /* Exportation du tableau  en fichier XLSX */
    exportExcel = () => {
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return { 'NUMERO': x.idReq, 'DATE': x.dateReq, 'STATUT': x.statutReq, 'MOTIF': x.idNature.idMotif.descriptionMotif, 'NATURE': x.idNature.libelleNature, 'NOM': x.idCli.nomCli, 'PRENOM': x.idCli.prenomCli, 'CONTACT': x.contactReq, 'RESOLU': x.dateResReq }
        }) : [];
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'JOURNAL_REQUETE');
        });
    }

    /* Enregistrer le fichier sur le client */
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    render() {
        const template = (options) => {
            const toggleIcon = options.collapsed ? 'pi pi-filter-fill' : 'pi pi-chevron-up';
            const className = `${options.className} justify-content-start`;
            const titleClassName = `${options.titleClassName} pl-1`;
            return (
                <a onClick={options.onTogglerClick} style={{ cursor: 'pointer' }}>
                    <div className={className}>
                        <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                            <span className={toggleIcon}></span>
                            <Ripple />
                        </button>
                        <span className={titleClassName}>
                            Filtre avancé
                        </span>

                    </div>
                </a>
            )
        }
        return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">

                        <div className="card p-fluid">

                            <Panel headerTemplate={template} collapsed={true} toggleable style={{ marginBottom: 10 }}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="grid" >

                                            <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                                <span className="p-float-label">
                                                    <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => { this.get_liste_data() })} dateFormat="dd/mm/yy" yearNavigator yearRange="2021:2070" />
                                                    <label htmlFor="inputtext">Date début</label>
                                                </span>
                                            </div>
                                            <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                                <span className="p-float-label">
                                                    <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => { this.get_liste_data() })} dateFormat="dd/mm/yy" yearNavigator yearRange="2021:2070" />
                                                    <label htmlFor="inputtext">Date fin</label>
                                                </span>
                                            </div>
                                            <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                                <span className="p-float-label">
                                                    <Dropdown options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_motif)}
                                                        value={this.state.ridMotif} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridMotif: e.value }, () => {
                                                            this.get_liste_data();
                                                            this.get_liste_nature(this.state.ridMotif);
                                                        })} autoWidth={false} style={{ width: '100%' }} />
                                                    <label htmlFor="inputtext">Motif</label>
                                                </span>
                                            </div>
                                            <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                                <span className="p-float-label">
                                                    <Dropdown options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_nature)}
                                                        value={this.state.ridNature} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridNature: e.value }, () => {
                                                            this.get_liste_data();
                                                        })} autoWidth={false} style={{ width: '100%' }} />
                                                    <label htmlFor="inputtext">Nature</label>
                                                </span>
                                            </div>
                                            <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                                <span className="p-float-label">
                                                    <Dropdown options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_categorie)}
                                                        value={this.state.rCategorie} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rCategorie: e.value }, () => {
                                                            this.get_liste_data();
                                                        })} autoWidth={false} style={{ width: '100%' }} />
                                                    <label htmlFor="inputtext">Catégorie client</label>
                                                </span>
                                            </div>

                                            <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                                <span className="p-float-label">
                                                    <Dropdown options={[{ value: 'Tous', label: 'Tous' }, { value: 'FERME', label: 'FERME' }, { value: 'OUVERT', label: 'OUVERT' }]}
                                                        value={this.state.rstatutReq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rstatutReq: e.value }, () => {
                                                            this.get_liste_data();
                                                        })} autoWidth={false} style={{ width: '100%' }} />
                                                    <label htmlFor="inputtext">Statut </label>
                                                </span>
                                            </div>

                                            <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                                <span className="p-float-label">
                                                    <Dropdown options={[{ value: 'Tous', label: 'Tous' }, { value: 'FONDE', label: 'FONDE' }, { value: 'NON FONDE', label: 'NON FONDE' }]}
                                                        value={this.state.retatReq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ retatReq: e.value }, () => {
                                                            this.get_liste_data();
                                                        })} autoWidth={false} style={{ width: '100%' }} />
                                                    <label htmlFor="inputtext">Etat </label>
                                                </span>
                                            </div>

                                            <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                                                    <SplitButton label="Rechercher" icon="pi pi-search" onClick={(e) => this.get_liste_data()} model={this.state.liste_item_export} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Panel>

                            <DataTable value={this.state.liste_data} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={10} ref={(el) => { this.dt = el; }}
                                alwaysShowPaginator={false} responsive={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                                <Column header="No" body={(rowData, props) => {
                                    return <span>{props.rowIndex + 1}</span>
                                }} style={{ width: '5%' }} />
                                <Column field="dateReq" header="Date" sortable={true} style={{ width: '8%' }} />
                                <Column field="statutReq" body={(rowData, column) => {
                                    return <div>
                                        <Badge value={rowData.statutReq == 'FERME' ? "1" : "0"} severity={rowData.statutReq == 'FERME' ? "success" : "warning"} />
                                        <span><label style={{ marginLeft: 8 }}>{rowData.statutReq}</label></span>
                                    </div>
                                }} header="Statut" sortable={true} style={{ width: '11%' }} />
                                <Column field="idNature.libelleNature" header="Nature" body={(rowData, column) => {
                                    return <div>
                                        <label style={{ fontWeight: 'bold' }}>{rowData.idNature.idMotif.descriptionMotif}</label><br />
                                        <label>{rowData.idNature.libelleNature}</label>
                                    </div>
                                }} sortable={true} />
                                <Column field="dateResReq" header="Resolu" sortable={true} style={{ width: '8%' }} />
                                <Column field="idCli.nomCli" header="Nom" sortable={true} style={{ width: '10%' }} />
                                <Column field="idCli.prenomCli" header="Prénom" sortable={true} />
                                <Column field="contactReq" header="Contact" style={{ width: '9%' }} />
                                <Column field="etatReq" header="Etat" sortable={true} style={{ width: '10%' }} />
                            </DataTable>

                        </div>


                    </div>
                </div>
            </div>
        );
    }
}