import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import CISService from '../service/CISService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Steps } from 'primereact/steps';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';
import { Galleria } from 'primereact/galleria';



const sleep = ms => new Promise(r => setTimeout(r, ms));

export class Register extends Component {
    constructor() {
        super();
        this.state = {
            showindicator: false,
            submit: false,
            submitUser: false,
            submitAdr: false,
            succeed: false,
            images: [
                { src: 'assets/layout/images/pub/pub1.jpg', alt: '' },
                { src: 'assets/layout/images/pub/pub2.jpg', alt: '' },
                { src: 'assets/layout/images/pub/pub3.jpg', alt: '' },
                { src: 'assets/layout/images/pub/pub4.jpg', alt: '' },
            ],

            activeIndex: 0,
            formData: {
                idEtabl: 0,
                codeEtabl: '',
                nomEtabl: "",
                domaineEtabl: "",
                telEtabl: "",
                registerFirstName: "",
                registerLastName: "",
                registerEmail: "",
                registerLogin: "",
                registerPwd: "",
                registerPwdConf: "",
                adrEtabl: "",
                emailEtabl: "",
                regionEtabl: "",
                villeEtabl: "",
                quartierEtabl: "",
                statutEtabl: "En attente",
            },

            stepItems: [
                {
                    label: 'Information',
                    index: 0,
                    command: (event) => {

                    }
                },
                {
                    label: 'Adresses',
                    index: 1,
                    command: (event) => {

                    }
                },
                {
                    label: 'Compte',
                    index: 2,
                    command: (event) => {

                    }
                }
            ],
        };
        this.appservice = new CISService();
    }


    checkForm() {
        if (this.state.activeIndex == 0) {
            this.setState({ submit: true });
            if (this.state.formData.nom == "" || this.state.formData.nomEtabl == "" || this.state.formData.codeEtabl == "" || this.state.formData.telEtabl == "" || this.state.formData.domaineEtabl == "" || this.state.formData.emailEtabl == "") {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
                return;
            }
        } else if (this.state.activeIndex == 1) {
            this.setState({ submitAdr: true });
            if (this.state.formData.regionEtabl == "" || this.state.formData.villeEtabl == "" || this.state.formData.quartierEtabl == "" || this.state.formData.adrEtabl == "") {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
                return;
            }
        } else if (this.state.activeIndex == 2) {
            this.setState({ submitUser: true });
            if (this.state.formData.registerFirstName == "" || this.state.formData.registerLastName == "" || this.state.formData.registerLogin == "" || this.state.formData.registerPwd == "" || this.state.formData.registerPwdConf == "") {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
                return;
            }
        } else {
            return;
        }
        this.setState({ activeIndex: this.state.activeIndex + 1 });
    }


    /* Fonction de validation du login */
    checkLogin() {
        this.setState({ submitLogin: true });
        this.appservice.get_user_by_login(this.state.formDataUser.login).then(user => {
            if (user !== null && user !== undefined) {
                this.setState({ submitLogin: false, msgLogin: 'Ce login existe déja' });
                this.setState({ formDataUser: user });
            } else {
                this.setState({ submitLogin: true, msgLogin: '' });
            }
        })
    }

    register() {

        if (this.state.formData.nomEtabl == '' || this.state.formData.codeEtabl == '') {
            this.setState({ msg: 'Veuilllez saisir le registerLogin et le mot de passe' });
        } else {
            this.setState({ showindicator: true });
            this.appservice.register(this.state.formData).then(data => {
                this.setState({ showindicator: false });
                if (data.code == 200) {
                    this.toast.show({ severity: 'success', summary: 'Success', detail: 'Félicitation, votre compte a été créé avec succès, nous vous contacterons dans les meilleurs délais' });
                    this.setState({ succeed: true });
                } else {
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec de création de compte, veuillez ressayer ultérierement' });
                }
            });
        }
    }

    getItem() {
        if (this.state.activeIndex == 0) {
            return <div className="p-fluid">
                <div className="formgrid grid">
                    <div className="field col-12 md:col-6">
                        <label htmlFor="libelleStruct">Nom de l'établissement *</label>
                        <InputText id="name" value={this.state.formData.nomEtabl} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.nomEtabl })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'nomEtabl', '', this.state.formData) })}
                            required autoFocus />
                        {this.state.submit && !this.state.formData.nomEtabl && <small className="p-invalid" style={{ color: 'red' }}>Le nom est obligatoire.</small>}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="codeStruct">Sigle *</label>
                        <InputText id="name" value={this.state.formData.codeEtabl} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.codeEtabl })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'codeEtabl', '', this.state.formData) })}
                            required />
                        {this.state.submit && !this.state.formData.codeEtabl && <small className="p-invalid" style={{ color: 'red' }}>Le sigle est obligatoire.</small>}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="precisionStruct">Téléphone * </label>
                        <InputMask id="tel" mask="999999999" value={this.state.formData.telEtabl} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.telEtabl })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'telEtabl', '', this.state.formData) })}
                            required />
                        {this.state.submit && !this.state.formData.telEtabl && <small className="p-invalid" style={{ color: 'red' }}>Le téléphone est obligatoire.</small>}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="longitudeStruct">Email *</label>
                        <InputText id="name" value={this.state.formData.emailEtabl} onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'emailEtabl', '', this.state.formData) })} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.emailEtabl })} />
                        {this.state.submit && !this.state.formData.emailEtabl && <small className="p-invalid" style={{ color: 'red' }}>L'email est obligatoire.</small>}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="name1">Catégorie * </label>
                        <Dropdown autoWidth={false} value={this.state.formData.domaineEtabl} filter={true} filterBy='label' filterMatchMode='contains'
                            options={[
                                { value: 'Administration publique', label: 'Administration publique' },
                                { value: 'Assurance', label: 'Assurance' },
                                { value: 'Banque', label: 'Banque' },
                                { value: 'Commerce', label: 'Commerce' },
                                { value: 'Prestation de service', label: 'Prestation de service' },
                                { value: 'Télécommunication', label: 'Télécommunication' },
                                { value: 'ONG', label: 'ONG' },
                                { value: 'Autre', label: 'Autre' },
                            ]}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'domaineEtabl', '', this.state.formData) })}
                            appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.domaineEtabl })} />
                        {this.state.submit && !this.state.formData.domaineEtabl && <small className="p-invalid" style={{ color: 'red' }}>Le domaine est obligatoire</small>}
                    </div>

                </div>
            </div>;
        } else if (this.state.activeIndex == 1) {
            return <div className="p-fluid">
                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-6">
                        <label htmlFor="name1">Region / province * </label>
                        <InputText id="name1" value={this.state.formData.regionEtabl} className={classNames({ 'p-invalid': this.state.submitAdr && !this.state.formData.regionEtabl })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'regionEtabl', '', this.state.formData) })}
                            required autoFocus />
                        {this.state.submitAdr && !this.state.formData.regionEtabl && <small className="p-invalid" style={{ color: 'red' }}>La region/province est obligatoire</small>}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="emailEtabl1">Ville *</label>
                        <InputText id="name" value={this.state.formData.villeEtabl} className={classNames({ 'p-invalid': this.state.submitAdr && !this.state.formData.villeEtabl })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'villeEtabl', '', this.state.formData) })}
                            required />
                        {this.state.submitAdr && !this.state.formData.villeEtabl && <small className="p-invalid" style={{ color: 'red' }}>La ville est obligatoire.</small>}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="emailEtabl1">Quartier *</label>
                        <InputText id="name" value={this.state.formData.quartierEtabl} className={classNames({ 'p-invalid': this.state.submitAdr && !this.state.formData.quartierEtabl })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'quartierEtabl', '', this.state.formData) })}
                            required />
                        {this.state.submitAdr && !this.state.formData.quartierEtabl && <small className="p-invalid" style={{ color: 'red' }}>Le nom est obligatoire.</small>}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="emailEtabl1">Adresse *</label>
                        <InputText value={this.state.formData.adrEtabl} className={classNames({ 'p-invalid': this.state.submitAdr && !this.state.formData.adrEtabl })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'adrEtabl', '', this.state.formData) })}
                            required />
                        {this.state.submitAdr && !this.state.formData.adrEtabl && <small className="p-invalid" style={{ color: 'red' }}>L'adresse de passe est obligatoire.</small>}
                    </div>
                </div>
            </div>
        }
        else if (this.state.activeIndex == 2) {
            return <div className="p-fluid">
                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-6">
                        <label htmlFor="emailEtabl1">Nom *</label>
                        <InputText id="name" value={this.state.formData.registerLastName} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formData.registerLastName })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'registerLastName', '', this.state.formData) })}
                            required />
                        {this.state.submitUser && !this.state.formData.registerLastName && <small className="p-invalid" style={{ color: 'red' }}>Le nom est obligatoire.</small>}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="emailEtabl1">Prénom *</label>
                        <InputText id="name" value={this.state.formData.registerFirstName} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formData.registerFirstName })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'registerFirstName', '', this.state.formData) })}
                            required />
                        {this.state.submitUser && !this.state.formData.registerFirstName && <small className="p-invalid" style={{ color: 'red' }}>Le prénom est obligatoire.</small>}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="name1">Login * </label>
                        <InputText id="name1" value={this.state.formData.registerLogin} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formData.registerLogin })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'registerLogin', '', this.state.formData) })}
                            required onBlur={(e) => {
                                if (this.state.formData.registerLogin !== "") {
                                    //this.checkLogin();
                                }
                            }} autoFocus />
                        {this.state.submitUser && !this.state.formData.registerLogin && <small className="p-invalid" style={{ color: 'red' }}>Le registerLogin est obligatoire</small>}
                        {this.state.submitLogin == false && this.state.modif == false && <small className="p-invalid" style={{ color: 'red' }}>{this.state.msgLogin}.</small>}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="longitudeStruct">Email * </label>
                        <InputText id="name" value={this.state.formData.registerEmail} onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'registerEmail', '', this.state.formData) })} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formData.registerEmail })} />
                        {this.state.submitUser && !this.state.formData.registerEmail && <small className="p-invalid" style={{ color: 'red' }}>L'email est obligatoire.</small>}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="emailEtabl1">Mot de passe *</label>
                        <Password value={this.state.formData.registerPwd} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formData.registerPwd })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'registerPwd', '', this.state.formData) })}
                            required />
                        {this.state.submitUser && !this.state.formData.registerPwd && <small className="p-invalid" style={{ color: 'red' }}>Le mot de passe est obligatoire.</small>}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="emailEtabl1">Confirmer mot de passe *</label>
                        <Password value={this.state.formData.registerPwdConf} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formData.registerPwdConf })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'registerPwdConf', '', this.state.formData) })}
                            required />
                        {this.state.submitUser && !this.state.formData.registerPwdConf && <small className="p-invalid" style={{ color: 'red' }}>Le mot de passe est obligatoire.</small>}
                    </div>

                </div>
            </div>
        }

    }

    render() {



        const itemTemplate = (item) => {
            return <img src={item.src} alt={item.alt} style={{ width: '100%', display: 'block', border: '4px solid #f7f7f7' }} />;
        };

        return (
            <div className="register-body">
                <Toast ref={(el) => this.toast = el} />
                <div className="register-wrapper">
                    <div className="register-panel">

                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px', marginTop: '10px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
 
                        <div className="p-col-12">
                            <img src="assets/layout/images/logo.png" className="logo" alt="diamond-layout" width={100} height={150} />

                            <div className="grid">
                                <div style={{ padding: 10 }}>
                                    <h2>Demande de création de compte</h2>
                                    {this.state.succeed == false ?
                                        <>
                                            <p style={{ color: '#e9590b', fontSize: 16, fontWeight: 'bold' }}>Saisissez vos informations, notre équipe vous contactera dans les meilleurs délais </p>
                                            <Steps model={this.state.stepItems} activeIndex={this.state.activeIndex} onSelect={(e) => this.setState({ activeIndex: e.index })} readOnly={true} style={{ marginTop: 20 }} />
                                            <br />
                                            <div className="card" >
                                                <div className="p-col-12" style={{ height: 270 }}>
                                                    {this.getItem()}
                                                </div>
                                                <div className="p-col-12">
                                                    <div>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                            <Button label="RETOUR" disabled={this.state.activeIndex <= 0} icon="pi pi-angle-left" onClick={() => {
                                                                this.setState({ activeIndex: this.state.activeIndex - 1 });
                                                            }} style={{ width: 150 }} />
                                                            {this.state.activeIndex == 2 ?
                                                                <Button label="ENREGISTRER" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.register()} style={{ width: 150 }} className="p-button-success" />
                                                                :
                                                                <Button label="SUIVANT" disabled={this.state.activeIndex >= 2} icon="pi pi-angle-right" onClick={() => {
                                                                    this.checkForm();
                                                                }} iconPos='right' style={{ width: 150 }} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <center>
                                            <br/>
                                            <img src="assets/layout/images/success.png" height={200} />
                                            <br/>
                                            <label style={{fontSize: 18}}>Félicitation, votre compte a été créé avec succès, nous vous contacterons dans les meilleurs délais</label>
                                        </center>
                                    }
                                </div>

                            </div>

                        </div>


                    </div>
                    <div className="register-image">
                        <div className="register-image-content">
                            <Galleria value={this.state.images} circular autoPlay transitionInterval={4000} style={{ maxWidth: '100%' }} changeItemOnIndicatorHover showThumbnails={false} showIndicators item={itemTemplate} />
                        </div>



                        <div className="image-footer">

                            <p>Suivez nous sur :</p>
                            <div className="icons">
                                <i className="pi pi-facebook" style={{ color: '#1f317d' }}></i>
                                <i className="pi pi-twitter" style={{ color: '#1f317d' }}></i>
                                <i className="pi pi-youtube" style={{ color: '#1f317d' }}></i>
                                <i className="pi pi-instagram" style={{ color: '#1f317d' }}></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}