import React, { Component } from 'react';
import CISService from '../service/CISService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

export class FormDroitTrt extends Component {
    constructor() {
        super();
        this.state = {
            liste_nature: [],
            liste_motif: [],
            liste_groupe: [],
            liste_droit: [],
            liste_selected_nature: [],
            liste_selected_droit: [],
            idMotif: null,
            idGrp: null,
            ////////////////////////////
            visible: false, // pour afficher la boite de confirmation de suppression un motif
            showindicator: false, // pour afficher l'état de chargement
            submit: false,// pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge du formulaire motif
            msg: '',

            ////////////////////////////
        };
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.CISService = new CISService();

    }

    componentDidMount() {
        this.get_liste_motif();
        this.get_liste_groupe();
    }

    //Liste des groupes
    get_liste_groupe() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_codification('GROUPE').then(data => {
            this.setState({ showindicator: false });
            if (data !== undefined) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.idCodif, label: x.libelleCodif }
                    });
                    this.setState({ liste_groupe: liste });
                }
            }
        });
    }

    //Liste des motifs
    get_liste_motif() {
        this.CISService.get_liste_motif().then(data => {
            if (data !== undefined) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.idMotif, label: x.descriptionMotif }
                    });
                    this.setState({ liste_motif: liste });
                }
            }
        });
    }

    get_liste_nature() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_nature(this.state.idMotif).then(data =>{
            this.setState({showindicator: false })
            if (data !== undefined) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.idNature, label: x.libelleNature }
                    });
                    this.setState({ liste_nature: liste });
                }
            }
        });
    }

    get_liste_droit() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_droit_traitement(this.state.idGrp).then(data => this.setState({ liste_droit: data, showindicator: false }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annuler();
            this.get_liste_droit();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }


    annuler() {
        this.setState({ liste_selected_droit: [] });
        this.setState({ liste_selected_nature: [] });
    }


    enreg_date() {
        if (this.state.liste_selected_nature.length <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les natures' });
        } else if (this.state.idGrp == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner le groupe' });
        } else {
            let i=0;
            let formData = this.state.liste_selected_nature.map(x => {
                i=i+1;
                return {idDtrt: i,  niveauDtrt: 0, actifDtrt: 'Oui', idCodifGrp: { idCodif: this.state.idGrp }, idNature: { idNature: x.idNature } }
            });
            console.log(formData);
            this.CISService.enreg_Droit_Traitement(formData).then(data => this.resultat(data.code, data.contenu));
        }
    }


    delete_data() {
        if (this.state.liste_selected_droit.length <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à supprimer' });
        } else {
            this.CISService.delete_Droit_Traitement(this.state.liste_selected_droit).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_data(data) {
        if (data != null && data != undefined) {
            this.setState({ msg: 'Voulez-vous vraiment supprimer les lignes sélectionnées ?' });
            this.setState({ visible: true });
        }
    }


    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_data();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="fluid card" style={{ height: 1200 }}>
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12 md:col-6" >
                        <span> <i className='pi pi-users'></i> Sélection des natures</span><hr />
                        <div className="formgrid grid">
                            <div className="field col-2">
                                <label htmlFor="libelleCommune">Motif *</label>
                            </div>
                            <div className="field col-10">
                                <Dropdown id="profil" options={this.state.liste_motif} value={this.state.idMotif} onChange={(e) => this.setState({ idMotif: e.value }, () => {
                                    this.get_liste_nature();
                                })} autoWidth={false} filter={true} filterBy='label' style={{ width: '100%' }} />
                            </div>
                        </div>
                        <DataTable value={this.state.liste_nature} paginatorPosition="bottom" paginator={true} rows={10} alwaysShowPaginator={false}
                            selection={this.state.liste_selected_nature} onSelectionChange={e => this.setState({ liste_selected_nature: e.value })}
                            responsive={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            footer={
                                <center>
                                    <Button label="Affecter >>" disabled={this.state.liste_selected_nature.length <= 0} icon="pi pi-check" onClick={(e) => this.enreg_date()} style={{ width: 150 }} />
                                </center>
                            } >
                            <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                            <Column field="libelleNature" filter={true} filterMatchMode='contains' header="Nature" sortable={true} />
                            <Column field="typeNature" filter={true} filterMatchMode='contains' header="Type" sortable={true} style={{ width: '15%' }} />
                        </DataTable>
                    </div>

                    <div className="col-12 md:col-6" >
                        <span> <i className='pi pi-sitemap'></i> Droits de traitement</span><hr />
                        <div className="formgrid grid">
                            <div className="field col-3">
                                <label htmlFor="libelleCommune">Groupe * </label>
                            </div>
                            <div className="field col-9">
                                <Dropdown id="profil" options={this.state.liste_groupe} value={this.state.idGrp} onChange={(e) => this.setState({ idGrp: e.value }, () => {
                                    this.get_liste_droit();
                                })} autoWidth={false} filter={true} filterBy='label' style={{ width: '100%' }} />
                            </div>
                        </div>
                        <DataTable value={this.state.liste_droit} paginatorPosition="bottom" paginator={true} rows={10} alwaysShowPaginator={false}
                            selection={this.state.liste_selected_droit} onSelectionChange={e => this.setState({ liste_selected_droit: e.value })}
                            responsive={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            footer={
                                <center>
                                    <Button label="Supprimer" disabled={this.state.liste_selected_droit.length <= 0} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data()} style={{ width: 150 }} />
                                </center>
                            } >
                            <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                            <Column header="No" body={(rowData, props) => {
                                return <div>
                                    <span >{props.rowIndex + 1}</span>
                                </div>
                            }} style={{ width: '5%' }} />
                            <Column field="idNature.libelleNature" filter={true} filterMatchMode='contains' header="Nature" sortable={true} />
                            <Column field="idNature.idMotif.descriptionMotif" filter={true} filterMatchMode='contains' header="Motif" sortable={true} style={{ width: '20%' }} />
                        </DataTable>
                    </div>

                </div>
            </div>
        );
    }
}